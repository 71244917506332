import React from "react";
import {
  Card,
  Box,
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
  FormGroup,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/material";
import AvatarGalleryPreview from "src/components/imageViewOnLengthPreview";
import RecordNotFound from "src/components/RecordNotFound";

const QuestionsListing = ({ questions, setQuestions }) => {
  return (
    <form autoComplete="off">
      <Stack
        direction="column"
        alignItems="center"
        spacing={1.5}
        justifyContent="space-between"
        mb={4.5}
      >
        {questions.length > 0 ? (
          questions.map((item, index) => {
            return (
              <Card
                className="w-100"
                sx={{
                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  mb: 1,
                  width: { sm: "100%", md: "100%" },
                  margin: "auto",
                  p: 2,
                  backgroundColor: "",
                  borderRadius: 0.5,
                }}
              >
                <Box sx={{ width: "100%", margin: "auto" }} margin={0}>
                  <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 0, sm: 0, md: 2 }}
                    mt={1}
                  >
                    <Grid item md={12} xs={12}>
                      <div className="d-flex align-items-center mb-2">
                        <div className="">
                          <Typography
                            sx={{
                              fontWeight: "900",
                              fontSize: 16,
                              mr: 1,
                            }}
                          >
                            Q{index + 1}.
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: 14, mr: 1 }}
                          >
                            {item?.title}
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                    {item?.type == "multiple_choice" ? (
                      <Grid item md={12} xs={12}>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={item?.answer}
                            onChange={(e) => {
                              questions[index].answer = e.target.value;
                              setQuestions([...questions]);
                            }}
                          >
                            {item?.options.map((item) => {
                              return (
                                <Grid item md={12}>
                                  <FormControlLabel
                                    value={item}
                                    disabled
                                    control={
                                      <Radio
                                        sx={{
                                          "&.Mui-disabled": {
                                            color: "#212B36",
                                          },
                                          "&.Mui-checked": {
                                            color: "#0096FF",
                                          },
                                        }}
                                      />
                                    }
                                    label={
                                      <span style={{ color: "#212B36" }}>
                                        {item}
                                      </span>
                                    }
                                  />
                                </Grid>
                              );
                            })}{" "}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    ) : item?.type == "multiple_select" ? (
                      <Grid item md={12} xs={12}>
                        <FormControl>
                          <FormGroup>
                            {item?.options.map((option) => (
                              <Grid item md={12} key={option}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      sx={{
                                        "&.Mui-disabled": {
                                          color: "#212B36",
                                        },
                                        "&.Mui-checked": {
                                          color: "#0096FF",
                                        },
                                      }}
                                      disabled
                                      checked={questions[
                                        index
                                      ]?.multiple_answers.includes(option)}
                                    />
                                  }
                                  label={
                                    <span style={{ color: "#212B36" }}>
                                      {option}
                                    </span>
                                  }
                                />
                              </Grid>
                            ))}
                          </FormGroup>
                        </FormControl>
                      </Grid>
                    ) : item?.type == "image" ? (
                      <Grid item md={12} xs={12}>
                        <div className="d-flex align-items-center mb-2">
                          <div className="">
                            <Typography
                              sx={{
                                fontWeight: "500",
                                fontSize: 14,
                                mr: 1,
                              }}
                            >
                              {item?.options[0]}
                            </Typography>
                          </div>

                          <AvatarGalleryPreview
                            length={item?.options_count}
                            Image={item?.multiple_answers}
                          />
                        </div>
                      </Grid>
                    ) : item?.type == "free_text" ? (
                      <Grid item md={12} xs={12}>
                        <Typography
                          sx={{
                            fontSize: 14,

                            mb: 0,
                            width: "100%",
                          }}
                        >
                          {item?.answer && (
                            <span>
                              <b>
                                <i>Answer: </i>
                              </b>
                            </span>
                          )}
                          {item?.answer ? item?.answer : "No Answer"}
                        </Typography>
                      </Grid>
                    ) : (
                      item?.type == "signature" && (
                        <Grid item md={12} xs={12}>
                          {item?.answer ? (
                            <img
                              key={index}
                              src={item?.answer}
                              alt={`Signature ${index + 1}`}
                            />
                          ) : (
                            <TextField
                              id="outlined-multiline-static"
                              multiline
                              disabled
                              rows={4}
                              sx={{
                                width: "100%",
                                "& fieldset": {
                                  top: 0,
                                },
                                "& legend": {
                                  display: "none",
                                },
                              }}
                            />
                          )}
                        </Grid>
                      )
                    )}
                  </Grid>
                </Box>
              </Card>
            );
          })
        ) : (
          <RecordNotFound title="Questions" />
        )}
      </Stack>
    </form>
  );
};

export default QuestionsListing;
