import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Stack, Typography, CircularProgress } from "@mui/material";
import Label from "src/components/Label";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import {
  deleteForm_v1,
  getAllFormsListV1,
  recursive_form_list,
} from "src/DAL/SendFormTemplate/sendFormTemplate";
import { convertToTitleCase } from "src/utils/convertHtml";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import UserFormFilter from "./UserFormFilter";
import { Icon } from "@iconify/react";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import CustomMUITable from "src/components/CustomMUITable";
import FilteredChipNew from "src/components/FilteredChipNew";
import { dd_date_format } from "src/utils/constant";
import UserFormFilterchild from "./UserFormFilterchild";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function UserForm() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const { id } = useParams();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pageCount, setPageCount] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const EMPTY_FILTER = {
    search: "",
    submission_status: "",
    filter_by_dates: false,
    date_from: null,
    date_to: null,
  };

  const [filterData, setFilterData] = useState(EMPTY_FILTER);
  const [updateFilterData, setUpdateFilterData] = useState(EMPTY_FILTER);

  const handleSelectOther = (name, value) => {
    setFilterData((values) => ({ ...values, [name]: value }));
  };

  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
  };

  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const getFormTemListing = async (filterData) => {
    setIsLoading(true);

    let postData = { ...filterData };
    if (postData.date_from) {
      postData.date_from = moment(postData.date_from).format("YYYY-MM-DD");
    }
    if (postData.date_to) {
      postData.date_to = moment(postData.date_to).format("YYYY-MM-DD");
    }
    postData.parent_form_id = id;
    delete postData.filter_by_dates;
    handleCloseFilterDrawer();

    const result = await recursive_form_list(page, rowsPerPage, postData);
    if (result.code === 200) {
      let chipData = { ...filterData };

      if (chipData.date_to && chipData.date_from) {
        let date = `Start Date : ${dd_date_format(
          chipData.date_from
        )} End Date :  ${dd_date_format(chipData.date_to)}`;
        chipData.date = {
          chip_label: date,
          chip_value: date,
        };
        delete chipData.date_from;
        delete chipData.date_to;
      }

      delete chipData.search;
      delete chipData.filter_by_dates;

      let tempData = result.forms.map((item) => {
        let data = "N/A";
        if (item.createdAt) {
          data = moment(item.createdAt).format("ddd, MMM DD, YYYY");
        }
        return { ...item, MENU_OPTIONS: handleMenu(item), created_at: data };
      });

      setUpdateFilterData(chipData);
      setUserList(tempData);
      setTotalCount(result.total_forms);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    filterData.search = searchText;
    getFormTemListing(filterData);
    localStorage.setItem("userFormFilter", JSON.stringify(filterData));
  };

  const handleClearFilter = () => {
    setPage(0);
    setPageCount(1);
    setFilterData(EMPTY_FILTER);
    setUpdateFilterData(EMPTY_FILTER);
    getFormTemListing(EMPTY_FILTER);
    localStorage.removeItem("userFormFilter");
  };

  const handleDeleteChip = (data) => {
    setPage(0);
    setPageCount(1);
    if (!data.date) {
      delete data.date;
      data.date_from = null;
      data.date_to = null;
      data.filter_by_dates = false;
    }
    localStorage.setItem("userFormFilter", JSON.stringify(data));
    getFormTemListing(data);
    setUpdateFilterData(data);
    setFilterData(data);
  };

  // const handleEdit = (value) => {
  //   delete value.MENU_OPTIONS;
  //   navigate(`/forms/forms-edit-forms/${value.form_slug}`, {
  //     state: value,
  //   });
  // };
  const handlePreview = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/form-list/forms/forms-preview/${value._id}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };

  const handleProgramMembers = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/form-list/forms/members/${value.form_slug}`, {
      state: value,
    });
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteForm_v1(deleteDoc.form_slug);
    if (result.code === 200) {
      getFormTemListing(filterData);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNavigate = () => {
    navigate(`/forms/forms-send-forms`);
  };

  const handleMenu = (data) => {
    let MENU_OPTIONS = [];
    // if (data.submission_status !== "submitted") {
    //   MENU_OPTIONS.push({
    //     label: "Edit",
    //     icon: "akar-icons:edit",
    //     handleClick: handleEdit,
    //   });
    // }
    MENU_OPTIONS.push({
      label: "Members",
      icon: "gravity-ui:persons",
      handleClick: handleProgramMembers,
    });
    MENU_OPTIONS.push({
      label: "Preview",
      icon: "pajamas:eye",
      handleClick: handlePreview,
    });
    MENU_OPTIONS.push({
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    });
    return MENU_OPTIONS;
  };

  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    { id: "number", label: "#", type: "number" },
    { id: "created_at", label: "Date" },
    { id: "title", label: " Title" },
    {
      id: "title",
      label: "Added By",
      renderData: (data) => {
        return (
          <>
            <Stack direction="column" alignItems="start" spacing={0}>
              <Typography variant="subtitle2" noWrap>
                {data.action_by === "admin_user" ? "Admin" : "Coach"}
              </Typography>
              {data.action_by === "coach_user" && (
                <Typography
                  sx={{ fontSize: 11, opacity: 0.5 }}
                  variant="subtitle2"
                  noWrap
                >
                  {convertToTitleCase(
                    data.action_info.first_name +
                      " " +
                      data.action_info.last_name +
                      " (" +
                      data.action_info.email +
                      ")"
                  )}
                </Typography>
              )}
            </Stack>
          </>
        );
      },
    },
    {
      id: "submission_status",
      label: "Submission Status",
      renderData: (data) => {
        return (
          <Label
            variant="ghost"
            color={
              data.submission_status === "submitted" ? "success" : "warning"
            }
          >
            {data.submission_status === "submitted"
              ? `Submitted (${
                  data.submitted_count +
                  "/" +
                  (data.submitted_count + data.pending_count)
                })`
              : "Pending"}
          </Label>
        );
      },
    },
  ];

  useEffect(() => {
    let filter_data = EMPTY_FILTER;
    let find_filter = localStorage.getItem("userFormFilter");
    if (find_filter) {
      filter_data = JSON.parse(find_filter);
      if (filter_data.search) {
        setSearchText(filter_data.search);
      }
    }

    setFilterData(filter_data);
    setUpdateFilterData(filter_data);
    getFormTemListing(filter_data);
  }, [page, rowsPerPage]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-sm-12 d-flex align-items-baseline">
            <IconButton
              className="back-screen-button mb-0"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
            <h2 className="mb-0 ms-2">Form</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            <button
              className="small-contained-button me-2 mt-1 mb-4"
              onClick={handleOpenFilterDrawer}
            >
              Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
            </button>
            {/* <button onClick={handleNavigate} className="small-contained-button">
              Send Form
            </button> */}
          </div>
        </div>
        <div className="my-3">
          <FilteredChipNew
            data={updateFilterData}
            tempState={filterData}
            EMPTY_FILTER={EMPTY_FILTER}
            onDeleteChip={handleDeleteChip}
            onClear={handleClearFilter}
          />
        </div>
        <CustomMUITable
          TABLE_HEAD={TABLE_HEAD}
          data={userList}
          className="card-with-background"
          custom_pagination={{
            total_count: totalCount,
            rows_per_page: rowsPerPage,
            page: page,
            handleChangePage: handleChangePage,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          custom_search={{
            searchText: searchText,
            setSearchText: setSearchText,
            handleSubmit: searchFunction,
          }}
          pageCount={pageCount}
          totalPages={totalPages}
          handleChangePages={handleChangePages}
          pagePagination={true}
          rows_per_page_count={rowsPerPage}
        />
        <CustomDrawer
          isOpenDrawer={filterDrawerState}
          onOpenDrawer={handleOpenFilterDrawer}
          onCloseDrawer={handleCloseFilterDrawer}
          pageTitle="Filters"
          componentToPassDown={
            <UserFormFilterchild
              filterData={filterData}
              handleSelectOther={handleSelectOther}
              searchFunction={searchFunction}
              handleClearFilter={handleClearFilter}
            />
          }
        />
      </div>
    </>
  );
}
