import React, { useState, useEffect } from "react";
import moment from "moment";
import "@fullcalendar/daygrid/main.css";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useParams, useLocation } from "react-router-dom";
import GeneralCalendar from "src/components/GeneralComponents/GeneralCalendar";
import { programme_workout_list_api } from "src/DAL/programmeWorkout/programmeWorkout";
import { show_proper_words } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const get_view_dates = (calendarValue) => {
  let tool = calendarValue;

  const startOfMonth = moment().startOf(tool);
  const daysToSubtract = startOfMonth.day() - 0;
  const previousSunday = startOfMonth.subtract(daysToSubtract, "days");
  let previousSundayDate = previousSunday.toDate();

  let add_days = 42;
  if (tool == "week") {
    add_days = 6;
  } else if (tool == "day") {
    add_days = 0;
  }

  let nextSaturday = startOfMonth.add(add_days, "days");
  let nextSaturdayDate = nextSaturday.toDate();
  if (tool == "day") {
    previousSundayDate = new Date();
    nextSaturdayDate = new Date();
  }

  return {
    start_date: previousSundayDate,
    end_date: nextSaturdayDate,
  };
};

function Calenders() {
  const classes = useStyles();
  const { slug } = useParams();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [workouts, setworkouts] = useState([]);
  const [isPasted, setIsPasted] = useState(false);
  const [defaultTimeZone, setDefaultTimeZone] = useState("");
  const [currentDate, setCurrentDate] = useState(get_view_dates("month"));
  const [difficultyLevel, setDifficultyLevel] = useState([]);

  let Levels =
    difficultyLevel.length > 0
      ? difficultyLevel.map((level) => level).join(", ")
      : [];

  const handleScrollCalled = (data) => {
    const todayCell = document.querySelector(
      `.calendar-workout-card[id="${data}"]`
    );
    if (todayCell)
      todayCell.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const getEventListing = async (reloadId, message, setOpenCopyPasteConfig) => {
    let postData = {
      start_date: moment(currentDate.start_date).format("YYYY-MM-DD"),
      end_date: moment(currentDate.end_date).format("YYYY-MM-DD"),
      slug: slug,
      level_data: Levels,
    };
    const result = await programme_workout_list_api(postData);
    if (result.code === 200) {
      setDefaultTimeZone("asia/karachi");
      setworkouts(result?.program_workouts);
      setIsLoading(false);
      setIsPasted(false);
      if (reloadId) {
        handleScrollCalled(reloadId);
      }
      if (message) {
        enqueueSnackbar(message, { variant: "success" });
      }
      if (setOpenCopyPasteConfig) {
        setOpenCopyPasteConfig(false);
      }
    } else {
      setIsLoading(false);
      setIsPasted(false);
    }
  };

  useEffect(() => {
    getEventListing();
  }, [currentDate, slug, difficultyLevel]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <GeneralCalendar
      programWorkoutList={workouts}
      setCurrentDate={setCurrentDate}
      showAddEvent={true}
      time_zone={defaultTimeZone}
      currentDate={currentDate}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      reloadList={getEventListing}
      calendar_title={state?.title || show_proper_words(slug)}
      programme={state}
      isPasted={isPasted}
      setIsPasted={setIsPasted}
      difficultyLevel={difficultyLevel}
      setDifficultyLevel={setDifficultyLevel}
    />
  );
}

export default Calenders;
