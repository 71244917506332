import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "src/config/config";
import {
  csvImage,
  docImage,
  pdfImage,
  wordImage,
  audioImage,
  xlsxImage,
  otherImage,
} from "src/assets";
import {
  AddSupportTicketAPI,
  DeleteSupportTicketImageOnS3,
  UpdateSupportTicketAPI,
  UploadSupportTicketImageOnS3,
} from "src/DAL/SupportTicket/SupportTicket";
import { timeAgo } from "src/utils/constant";

export default function AddOrUpdateTickets({
  onCloseDrawer = () => {},
  editData,
  formType,
  setUserList = () => {},
  setOpen = () => {},
  dataList,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [oldImagesArray, setOldImagesArray] = useState([]);
  const [state, setState] = useState({
    subject: "",
    description: "",
    ticket_id: "",
  });

  const imageLinks = {
    docx: wordImage,
    mp3: audioImage,
    pdf: pdfImage,
    csv: csvImage,
    doc: docImage,
    xlsx: xlsxImage,
    xls: xlsxImage,
    other: otherImage,
  };

  const handleRemove = (index) => {
    files.splice(index, 1);
    setFiles([...files]);
  };

  const handleUpload = (event) => {
    let setImageObject = {};
    const fileList = event.target.files;
    const arr = [];

    for (let i = 0; i < fileList.length; i++) {
      setImageObject = {
        path: fileList[i],
        type: "file",
      };

      arr.push(setImageObject);
    }
    setFiles((prevFiles) => [...prevFiles, ...arr]);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const UploadImages = async (formData) => {
    const result = await UploadSupportTicketImageOnS3(formData);
    return result.image_path;
  };

  const DeleteImages = async (formData) => {
    const result = await DeleteSupportTicketImageOnS3(formData);
    return result;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const results = files?.map((image) => {
      const formData = new FormData();
      formData.append("image", image.path);
      const result = UploadImages(formData);
      return result;
    });

    Promise.all(results).then(async (img_results) => {
      var setImageArray = [];
      img_results?.map((image_path) => {
        setImageArray.push(image_path);
      });
      const formDataObject = {
        subject: state.subject,
        description: state.description,
      };
      if (files.length > 0) {
        formDataObject.ticket_images = setImageArray;
      }
      const result = await AddSupportTicketAPI(JSON.stringify(formDataObject));
      if (result.code === 200) {
        const timeCreated = timeAgo(result.support_ticket.support_ticket_date);
        setUserList((prev) => [
          { ...result.support_ticket, timeCreated: "few seconds ago" },
          ...prev,
        ]);
        setOpen((prev) => prev + 1);
        onCloseDrawer();
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const deleted_images_array = [];
    let deleted_images_object = {};

    oldImagesArray.map((old_image) => {
      const filtered_array = files.filter(
        (image) => image.thumbnail_1 == old_image.thumbnail_1
      );
      delete old_image.type;
      if (filtered_array.length == 0) {
        deleted_images_array.push(old_image);
      }
    });

    if (deleted_images_array.length > 0) {
      deleted_images_object = {
        moment_image: deleted_images_array,
      };
      DeleteImages(deleted_images_object);
    }

    const results = files?.map((image) => {
      if (image.type === "file") {
        const formData = new FormData();
        formData.append("image", image.path);
        const result = UploadImages(formData);
        return result;
      } else {
        return image;
      }
    });

    Promise.all(results).then(async (img_results) => {
      var setImageArray = [];
      img_results?.map((image_path) => {
        setImageArray.push(image_path);
      });
      const formDataObject = {
        subject: state.subject,
        description: state.description,
        status: true,
      };
      if (files.length > 0) {
        formDataObject.ticket_images = setImageArray;
      }
      const result = await UpdateSupportTicketAPI(
        state.ticket_id,
        formDataObject
      );
      if (result.code === 200) {
        dataList("all");
        onCloseDrawer();
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    if (formType == "EDIT") {
      setState((prevState) => ({
        ...prevState,
        ["subject"]: editData?.subject,
        ["description"]: editData?.description,
        ["ticket_id"]: editData?._id,
      }));

      let setImageObject = {};

      editData.ticket_images?.map((images) => {
        setImageObject = {
          thumbnail_1: images.thumbnail_1,
          thumbnail_2: images.thumbnail_2
            ? images.thumbnail_2
            : images.thumbnail_1,
          type: "image",
        };
        files.push(setImageObject);
        oldImagesArray.push(setImageObject);
      });
    }
  }, [formType]);

  const getCommentImage = (file) => {
    if (file.type === "file") {
      const ext = file.path.name.split(".").pop();
      if (
        ext == "jpg" ||
        ext == "JPG" ||
        ext == "png" ||
        ext == "webp" ||
        ext == "jpeg" ||
        ext == "JPEG" ||
        ext == "PNG"
      ) {
        return URL.createObjectURL(file.path);
      } else if (imageLinks[ext]) {
        return imageLinks[ext];
      } else {
        return imageLinks.other;
      }
    } else {
      const ext = file.thumbnail_2.split(".").pop();
      if (
        ext == "jpg" ||
        ext == "JPG" ||
        ext == "png" ||
        ext == "webp" ||
        ext == "jpeg" ||
        ext == "JPEG" ||
        ext == "PNG"
      ) {
        return s3baseUrl + file.thumbnail_2;
      } else if (imageLinks[ext]) {
        return imageLinks[ext];
      } else {
        return imageLinks.other;
      }
    }
  };

  return (
    <div className="container new-memories">
      <form onSubmit={formType === "ADD" ? handleSubmit : handleUpdate}>
        <TextField
          className="mt-4 inputs-fields"
          id="outlined-basic"
          label="Ticket Subject"
          variant="outlined"
          name="subject"
          value={state.subject}
          required={true}
          onChange={(e) => handleChange(e)}
        />
        <TextField
          id="outlined-multiline-static"
          label="Enter Description"
          multiline
          rows={5}
          name="description"
          value={state.description}
          onChange={(e) => handleChange(e)}
          variant="outlined"
          style={{ width: "100%" }}
          className="mt-3"
          required={true}
        />
        <div className="col-md-12 mt-2 d-flex">
          <div className="row w-100 mb-3 add-photo">
            <p className="mt-2">Recommended Size (1000x670)</p>
            {files &&
              files?.map((file, index) => (
                <div className="col-3 mt-2" key={index}>
                  <span className="preview">
                    <span onClick={() => handleRemove(index)}>x</span>
                    <img src={getCommentImage(file)} />
                  </span>
                </div>
              ))}
            <div className="col-3 mt-2">
              <span className="upload-button">
                <input
                  color="primary"
                  accept="image/*,.pdf,.xlsx,.xls,.docx,.csv,.doc"
                  type="file"
                  id="icon-button-file"
                  style={{ display: "none" }}
                  onChange={handleUpload}
                  multiple={true}
                />
                <label htmlFor="icon-button-file">
                  <CloudUploadIcon />
                </label>
              </span>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <span className="float-end">
            <button className="submit-button" disabled={isLoading}>
              {isLoading
                ? formType === "ADD"
                  ? "Saving..."
                  : "Updating..."
                : formType === "ADD"
                ? "Save"
                : "Update"}
            </button>
          </span>
        </div>
      </form>
    </div>
  );
}
