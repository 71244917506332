import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Icon } from "@iconify/react";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import closeFill from "@iconify/icons-eva/close-fill";
import { get_root_value } from "src/utils/domUtils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 270,

  bgcolor: get_root_value("--sidebars-background-color"),
  border: "1px solid #000",
  boxShadow: 24,
};

const ProgrammesImgStyle = styled("img")({
  height: "auto",

  objectFit: "cover",
  width: "100%",
  position: "relative", // Added positioning to the image container
});

const CloseIconButton = styled(IconButton)({
  position: "absolute",
  color: "var(--portal-theme-primary)",
  top: 5,
  right: 5,
  zIndex: 1,

  backgroundColor: "#1c1c1c",
  padding: "0",
  ":hover": {
    backgroundColor: "#1c1c1c",
  },
});

export default function ImageModelBox({ open, handleClose, image_url }) {
  return (
    <div>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style} className="mui-btn-close">
          <ProgrammesImgStyle src={image_url} />
          <CloseIconButton onClick={handleClose}>
            <Icon icon={closeFill} width={25} height={25} />
          </CloseIconButton>
        </Box>
      </Modal>
    </div>
  );
}
