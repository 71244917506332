import React from "react";
import { useChat } from "../../Hooks/ChatContext";
import { is_small_screen } from "../../utils/constant";
import ChatList from "./ChatList";
import ChatRight from "./ChatRight";

function Chat() {
  const { selectedChat } = useChat();
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>{"Messages"}</h2>
          </div>
        </div>
      </div>
      <div
        className={
          is_small_screen()
            ? "row w-100 chat-section m-0 mt-2"
            : "row w-100 chat-section mt-2 ps-1"
        }
      >
        {(!is_small_screen() || (is_small_screen() && !selectedChat?._id)) && (
          <ChatList />
        )}
        {(!is_small_screen() || (is_small_screen() && selectedChat?._id)) && (
          <div className="col-12 col-md-8 chat-right-part p-2">
            <ChatRight />
          </div>
        )}
      </div>
    </>
  );
}

export default Chat;
