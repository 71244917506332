import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import { getAllTemplatesListByType } from "src/DAL/FormTemplate/feedbackTemplates";
import { addForm } from "src/DAL/SendFormTemplate/sendFormTemplate";
import { form_filter_data } from "src/DAL/Programme/Programme";
import MUIAutocomplete from "src/components/GeneralComponents/MUIAutocomplete";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import moment from "moment";
import { addDays } from "date-fns";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AddForm() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [allTemplates, setAllTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [personName, setPersonName] = useState([]);
  const [coachesListing, setCoachesListing] = useState([]);
  const [serchText, setSerchText] = useState("");
  const [programsList, setProgramsList] = useState([]);

  const [inputs, setInputs] = useState({
    start_date: null,
    recurring_end_date: null,
    template_type: false,
    description: "",
    title: "",
    form_template: "",
    is_recurring: false,
    receiver_type: "coach",
    send_to: [],
    programs_id: null,
    recurring_form_days: "",
    status: true,
  });

  const getMemberList = (data) => {
    let member = data.map((data) => {
      let full_name =
        data.first_name + " " + data.last_name + " (" + data.email + ")";
      return {
        full_name,
        _id: data._id,
        chip_label: `Member: ${full_name}`,
        chip_value: data._id,
      };
    });
    setPersonName(member);
  };

  const getCoachList = (data) => {
    let coach = data.map((data) => {
      let full_name =
        data.first_name + " " + data.last_name + " (" + data.email + ")";
      return {
        full_name,
        _id: data._id,
        chip_label: `Coach: ${full_name}`,
        chip_value: data._id,
      };
    });
    setCoachesListing(coach);
  };

  const getProgList = (data) => {
    let program = data.map((data) => {
      return {
        title: data.title,
        _id: data._id,
        chip_label: `Program: ${data.title}`,
        chip_value: data._id,
      };
    });
    setProgramsList(program);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let selected_array = [];

    if (inputs.send_to.length > 0) {
      selected_array = inputs.send_to.map((item) => item._id);
    }

    let postData = {
      title: inputs?.title,
      form_template: inputs?.form_template?._id,
      send_to: selected_array,
      receiver_type: inputs.receiver_type,
      send_all: inputs?.template_type,
      description: inputs?.description,
      program: inputs?.programs_id?._id,
      status: inputs.status,
      is_recurring: inputs.is_recurring,
    };

    if (inputs.is_recurring) {
      postData.start_date = moment(inputs.start_date).format("YYYY-MM-DD");
      postData.recurring_end_date = moment(inputs.recurring_end_date).format(
        "YYYY-MM-DD"
      );
      postData.recurring_form_days = inputs.recurring_form_days;
    }

    setIsLoading(true);
    const res = await addForm(postData);
    if (res.code === 200) {
      navigate(-1);
      enqueueSnackbar(res.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(res.message, { variant: "error" });
    }
  };

  const get_data_list = async (type, search_text) => {
    let search = search_text || "";
    const result = await form_filter_data(type, search);
    if (result.code === 200) {
      if (type === "coach") {
        getCoachList(result?.coach_list);
      } else if (type === "member") {
        getMemberList(result?.member);
      } else if (type === "program") {
        getProgList(result?.program);
      }
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    if (name == "is_recurring") {
      setInputs((values) => ({
        ...values,
        start_date: null,
        recurring_end_date: null,
        recurring_form_days: "",
      }));
    }
    if (name == "receiver_type") {
      setInputs((values) => ({
        ...values,
        form_template: null,
        description: "",
      }));
      setSerchText("");
      setProgramsList([]);
      setCoachesListing([]);
      setPersonName([]);
    }
  };

  const handleSelectOther = (name, value) => {
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleChangeTemplate = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({
      ...values,
      [name]: value,
      ["description"]: value.description,
    }));
  };

  const getFormListing = async (type) => {
    const result = await getAllTemplatesListByType(type);
    if (result.code === 200) {
      setAllTemplates(result?.form_templates);
    }
  };

  useEffect(() => {
    getFormListing(inputs.receiver_type);
  }, [inputs.receiver_type]);

  useEffect(() => {
    get_data_list(inputs.receiver_type, serchText);
  }, [inputs.receiver_type, serchText]);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex align-items-center">
          <IconButton
            className="back-screen-button mb-0"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <h2 className="mb-0 ms-2">Send Form</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              sx={{ width: "100%" }}
              required
              name="title"
              value={inputs.title}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">Form type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="receiver_type"
                value={inputs.receiver_type}
                label="Form type"
                onChange={handleChange}
              >
                <MenuItem value="coach">Coach</MenuItem>
                <MenuItem value="member"> Member</MenuItem>
                <MenuItem value="program">Program</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl sx={{ width: "100%" }} required>
              <InputLabel id="demo-simple-selec-label">
                Form Template
              </InputLabel>
              <Select
                labelId="demo-simple-selec-label"
                id="demo-simple-select"
                name="form_template"
                value={inputs?.form_template}
                label="Form Template"
                onChange={handleChangeTemplate}
              >
                {allTemplates.map((item) => (
                  <MenuItem key={item._id} value={item}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {inputs.receiver_type === "program" ? (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <MUIAutocomplete
                  inputLabel="Program *"
                  selectedOption={inputs.programs_id}
                  setSelectedOption={(e) => {
                    handleSelectOther("programs_id", e);
                  }}
                  optionsList={programsList}
                  setCustomSearch={setSerchText}
                  name="title"
                />
              </div>
            </>
          ) : (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">
                    Send To All
                  </InputLabel>
                  <Select
                    required
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="template_type"
                    value={inputs?.template_type}
                    label="Send To All"
                    onChange={handleChange}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {inputs.receiver_type === "coach" ? (
                <>
                  {!inputs?.template_type && (
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                      <MUIAutocomplete
                        inputLabel="Coach *"
                        multiple
                        selectedOption={inputs.send_to}
                        setSelectedOption={(e) => {
                          handleSelectOther("send_to", e);
                        }}
                        optionsList={coachesListing}
                        setCustomSearch={setSerchText}
                        name="full_name"
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {!inputs?.template_type && (
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                      <MUIAutocomplete
                        inputLabel="Member *"
                        multiple
                        selectedOption={inputs.send_to}
                        setSelectedOption={(e) => {
                          handleSelectOther("send_to", e);
                        }}
                        optionsList={personName}
                        setCustomSearch={setSerchText}
                        name="full_name"
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Recurring Forms?
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="is_recurring"
                value={inputs.is_recurring}
                label="Recurring Forms?"
                onChange={handleChange}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
          {inputs.is_recurring && (
            <>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <LocalizationProvider dateAdapter={AdapterDateFns} required>
                  <DatePicker
                    label="Start Date"
                    name="start_date"
                    disablePast
                    inputFormat="dd-MM-yyyy"
                    value={inputs.start_date}
                    onChange={(e) => {
                      handleSelectOther("start_date", e);
                    }}
                    format="DD-MM-YYYY"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="inputs-fields"
                        fullWidth
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <TextField
                  id="outlined-basic"
                  label="No Of Recurring Days"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  required
                  type="number"
                  name="recurring_form_days"
                  value={inputs.recurring_form_days}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
                <LocalizationProvider dateAdapter={AdapterDateFns} required>
                  <DatePicker
                    label="End Date"
                    name="recurring_end_date"
                    disablePast
                    inputFormat="dd-MM-yyyy"
                    value={inputs.recurring_end_date}
                    minDate={addDays(new Date(), 1)}
                    onChange={(e) => {
                      handleSelectOther("recurring_end_date", e);
                    }}
                    format="DD-MM-YYYY"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="inputs-fields"
                        fullWidth
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </>
          )}
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Description"
              multiline
              variant="outlined"
              sx={{ width: "100%" }}
              rows={4}
              required
              name="description"
              value={inputs.description}
              onChange={(e) => {
                if (e.target.value[0] === " ") {
                  return;
                }
                handleChange(e);
              }}
            />
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">Submit</button>
          </div>
        </div>
      </form>
    </div>
  );
}
