import * as React from "react";

import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  // Add your custom styles here
  backdrop: {
    backgroundColor: "white", // Change the color as per your requirement
  },
}));
const getValueText = (value) => {
  switch (value) {
    case "1rm":
      return "%1RM";
    case "body_weight":
      return "%BW";
    case "kg":
      return "kg";
    case "lb":
      return "pound";
    case "poods":
      return "poods";
    case "m":
      return "meter";
    case "km":
      return "km";
    case "mi":
      return "mile";
    case "calories":
      return "calories";
    case "el":
      return "Easy/Long";
    case "c":
      return "Compromised";
    case "t":
      return "Threshold";
    case "i":
      return "Interval";
    case "r":
      return "Repetition";
    default:
      return "";
  }
};
const getPaceTextinPaces = (type) => {
  let result = "";
  if (type == "m") {
    result = "Time Cap";
  } else if (type == "mi") {
    result = "Pace Min/Mile";
  } else if (type == "km") {
    result = "Pace Min/Km";
  }
  return result;
};
const convertSecondsToTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
};
const getTitleByKey = (key) => {
  switch (key) {
    case "e_km":
      return "1 KM Pace";
    case "e_mi":
      return "1 MILE Pace";
    case "i_400_m":
      return "400 Meters Pace";
    case "i_km":
      return "1 KM Pace";
    case "i_1200_m":
      return "1200 Meters Pace";
    case "i_mi":
      return "1 MILE Pace";
    case "t_400_m":
      return "400 Meters Pace";
    case "t_km":
      return "1 KM Pace";
    case "t_mi":
      return "1 MILE Pace";
    case "c_km":
      return "1 KM Pace";
    case "c_mi":
      return "1 MILE Pace";
    case "r_200_m":
      return "200 Meters Pace";
    case "r_300_m":
      return "300 Meters Pace";
    case "r_400_m":
      return "400 Meters Pace";
    case "r_600_m":
      return "600 Meters Pace";
    case "r_800_m":
      return "800 Meters Pace";
    default:
      return "";
  }
};

export default function DisplaySet({ parameters, sets_array, type }) {
  const classes = useStyles();

  return (
    <>
      {sets_array &&
        sets_array.length > 0 &&
        sets_array.map((sets, index) => {
          return (
            <div key={index}>
              {sets_array.length > 1 && (
                <span className="fw-bold">{index + 1}:</span>
              )}
              {parameters.includes("Vdot") && (
                <>
                  <span>{` ${
                    sets?.race_type
                      ? getValueText(sets?.race_type)
                      : getValueText("c")
                  } ${getTitleByKey(sets?.pace_type)} ,`}</span>
                </>
              )}
              {parameters.includes("Pace") && (
                <>
                  <span>{` ${
                    sets?.race_type
                      ? getValueText(sets?.race_type)
                      : getValueText("c")
                  } `}</span>
                  <span>
                    {` ${sets?.distance ? sets?.distance : 0} ${getValueText(
                      sets?.distance_unit
                    )} ${"Distance"}, `}
                  </span>
                  <span>
                    {` ${
                      sets?.pace_low
                        ? convertSecondsToTime(sets?.pace_low)
                        : convertSecondsToTime(0)
                    } ${getPaceTextinPaces(sets?.distance_unit)} ,`}
                  </span>
                </>
              )}{" "}
              {parameters.includes("Distance") && (
                <span>
                  {` ${sets?.distance ? sets?.distance : 0} ${getValueText(
                    sets?.distance_unit
                  )} ${"Distance"} , `}
                </span>
              )}
              {(parameters.includes("Duration/Pace") ||
                parameters.includes("Time")) && (
                <span>
                  {`${sets?.duration ? sets?.duration : 0} ${
                    sets?.duration_unit ? sets?.duration_unit : ""
                  } ${
                    parameters.includes("Duration/Pace")
                      ? "Duration/Pace"
                      : "Time"
                  } ,`}
                </span>
              )}
              {parameters.includes("Reps") && !type && (
                <span>{` ${sets?.reps ? sets?.reps : 0}  ${"Reps"} ,`}</span>
              )}
              {parameters.includes("Max_Reps") && !type && (
                <span>{` ${
                  sets?.maxReps ? sets?.maxReps : 0
                }  ${"Max Reps"} ,`}</span>
              )}
              {parameters.includes("Height") && (
                <span>
                  {` ${sets?.height ? sets?.height : 0} ${
                    sets?.height_unit ? sets?.height_unit : ""
                  } ${"Height"} ,`}
                </span>
              )}
              {parameters.includes("Weight") && (
                <span>
                  {` ${sets?.weight ? sets?.weight : 0} ${getValueText(
                    sets?.weight_unit
                  )} ${"Weight"} ,`}
                </span>
              )}
              {parameters.includes("Distance/Calories") && (
                <span>
                  {` ${sets?.distance ? sets?.distance : 0} ${getValueText(
                    sets?.distance_unit
                  )} ${
                    sets?.distance_unit == "calories" ? "Calories" : "Distance"
                  } ,`}
                </span>
              )}
              {parameters.includes("%MHR") && (
                <span>{` ${sets?.mhr ? sets?.mhr : 0}  ${"%MHR"} ,`}</span>
              )}
              {parameters.includes("Tempo") && (
                <span>{` ${sets?.tempo ? sets?.tempo : 0}  ${"Tempo"} ,`}</span>
              )}
              {parameters.includes("Rest") && (
                <span>
                  {` ${sets?.rest ? sets?.rest : 0} ${
                    sets?.rest_unit ? sets?.rest_unit : ""
                  } ${"Rest"} ,`}
                </span>
              )}
            </div>
          );
        })}
    </>
  );
}
