import React, { useState } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@mui/styles";
import { get_root_value } from "src/utils/domUtils";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCkeditor";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import { update_event_iteration_api } from "src/DAL/Calender/Calender";
import dayjs from "dayjs";
import { useEffect } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import PerformActionOn from "./PerformActionOn";

const useStyles = makeStyles(() => ({
  paper: {
    background: get_root_value("--popup-background-color"),
    color: get_root_value("--input-text-color"),
  },
}));

const notification_object = {
  notify_before_unit: "minutes",
  notify_before_time: 30,
  notification_title: "",
};

export default function UpdateCalendarEvent({
  editValues,
  dataList,
  handleCloseDrawer,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [notificationArray, setNotificationArray] = useState([
    notification_object,
  ]);
  const [openPopup, setOpenPopup] = useState(false);
  const [oldTime, setOldTime] = useState({});
  const [state, setState] = useState({
    description: "",
    event_title: "",
    event_color: "#000",
    notify_before_unit: "minutes",
    notify_before_time: 30,
    recurring_type: "daily",
    start_date: dayjs(new Date()),
    end_date: dayjs(new Date()),
    start_time: dayjs(new Date()),
    end_time: dayjs(new Date()),
    status: true,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeNotification = (event, index) => {
    const { name, value } = event.target;
    let temp_state = [...notificationArray];
    let temp_element = { ...temp_state[index] };
    temp_element[name] = value;
    temp_state[index] = temp_element;
    setNotificationArray(temp_state);
  };

  const handleChangeDate = (name, event) => {
    setState((prevState) => {
      return {
        ...prevState,
        [name]: event.$d,
      };
    });
  };

  const updateIteration = async (value) => {
    setOpenPopup(false);
    setIsLoading(true);

    let startDate = state.start_date;
    let endDate = state.end_date;
    let startTime = state.start_time;
    let endTime = state.end_time;

    if (state.start_date.$d) {
      startDate = state.start_date.$d;
    }

    if (state.end_date.$d) {
      endDate = state.end_date.$d;
    }

    if (state.start_time.$d) {
      startTime = state.start_time.$d;
    }

    if (state.end_time.$d) {
      endTime = state.end_time.$d;
    }

    let checkStartTime = moment(startTime, "HH:mm").format("hh:mm A");
    let checkEndTime = moment(endTime, "HH:mm").format("hh:mm A");

    let checkStartTimeMoment = moment(checkStartTime, "hh:mm A");
    let checkEndTimeMoment = moment(checkEndTime, "hh:mm A");

    if (checkStartTimeMoment.isAfter(checkEndTimeMoment)) {
      enqueueSnackbar("Start time cannot be greater than end time.", {
        variant: "error",
      });
      setIsLoading(false);
      return;
    }

    const formData = {
      title: state.event_title,
      color: state.event_color,
      description: state.description,
      iteration_id: state._id,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      start_time: moment(startTime).format("HH:mm"),
      end_time: moment(endTime).format("HH:mm"),
      update_type: value,
      is_notify_user: notificationArray.length > 0 ? true : false,
      notify_before: notificationArray,
    };

    const result = await update_event_iteration_api(formData, state.event_id);
    if (result.code === 200) {
      dataList();
      handleCloseDrawer();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpenPopup(true);
  };

  const handleAddNotification = () => {
    setNotificationArray((old_array) => [...old_array, notification_object]);
  };

  const handleRemoveNotification = (index) => {
    const updatedItems = [...notificationArray];
    updatedItems.splice(index, 1);
    setNotificationArray(updatedItems);
  };

  useEffect(() => {
    setState({
      ...editValues,
      start_date: dayjs(editValues.start_date_time),
      end_date: dayjs(editValues.end_date_time),
      start_time: dayjs(editValues.start_time, "hh:mm A"),
      end_time: dayjs(editValues.end_time, "hh:mm A"),
    });

    let notify_before = [];
    if (editValues.notify_before && editValues.notify_before.length > 0) {
      notify_before = editValues.notify_before;
    }
    setNotificationArray(notify_before);
  }, []);

  return (
    <>
      <div className="container">
        <form className="row" onSubmit={handleSubmit}>
          <div className="text-end mb-4">
            <button
              className="small-contained-button event-submit-button"
              disabled={isLoading}
            >
              {isLoading ? "Updating..." : "Update"}
            </button>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <TextField
              className="mt-3"
              id="outlined-basic"
              label="Title"
              variant="outlined"
              name="event_title"
              fullWidth
              value={state.event_title}
              required={true}
              onChange={handleChange}
            />
          </div>
          <div
            className={`col-12 col-md-6 ${
              state.recurring_type == "weekly" ? "col-lg-3" : "col-lg-4"
            }`}
          >
            <TextField
              id="event_color"
              type="color"
              label="Color"
              fullWidth
              name="event_color"
              className="mt-3"
              variant="outlined"
              required={true}
              value={state.event_color}
              onChange={handleChange}
            />
          </div>
          <div
            className={`col-12 col-md-6 ${
              state.recurring_type == "weekly" ? "col-lg-2" : "col-lg-4"
            }`}
          >
            <FormControl variant="outlined" className="mt-3" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">
                Recurring Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={state.recurring_type}
                onChange={(e) => handleChange(e)}
                label="Recurring Type"
                name="recurring_type"
                className="inputs-fields svg-color"
                MenuProps={{
                  classes: {
                    paper: classes.paper,
                  },
                }}
                sx={{
                  color: get_root_value("--input-text-color"),
                }}
                disabled
              >
                <MenuItem value="daily">Daily</MenuItem>
                <MenuItem value="weekly">Weekly</MenuItem>
                <MenuItem value="monthly">Monthly</MenuItem>
              </Select>
            </FormControl>
          </div>
          {state.recurring_type == "weekly" && (
            <div className={`col-12 col-md-6 col-lg-3`}>
              <FormControl variant="outlined" className="mt-3" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Day
                </InputLabel>
                <Select
                  multiple
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={state.weekday}
                  onChange={(e) => handleChange(e)}
                  label="Select Day"
                  name="weekday"
                  className="inputs-fields svg-color"
                  MenuProps={{
                    classes: {
                      paper: classes.paper,
                    },
                  }}
                  sx={{
                    color: get_root_value("--input-text-color"),
                  }}
                  disabled
                >
                  <MenuItem value={0}>Sunday</MenuItem>
                  <MenuItem value={1}>Monday</MenuItem>
                  <MenuItem value={2}>Tuesday</MenuItem>
                  <MenuItem value={3}>Wednesday</MenuItem>
                  <MenuItem value={4}>Thursday</MenuItem>
                  <MenuItem value={5}>Friday</MenuItem>
                  <MenuItem value={6}>Saturday</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
          <div className="col-12 col-md-6 col-lg-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Start Date"
                name="start_date"
                inputFormat="DD-MM-YYYY"
                value={state.start_date}
                disabled
                format="DD-MM-YYYY"
                className="mt-3 w-100"
                onChange={(e) => handleChangeDate("start_date", e)}
                renderInput={(params) => (
                  <TextField {...params} required={true} />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Start Time"
                name="start_time"
                value={state.start_time}
                sx={{ color: "#fff" }}
                className="mt-3 w-100"
                onChange={(e) => handleChangeDate("start_time", e)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="End Date"
                name="end_date"
                inputFormat="DD-MM-YYYY"
                value={state.end_date}
                disabled
                format="DD-MM-YYYY"
                className="mt-3 w-100"
                onChange={(e) => handleChangeDate("end_date", e)}
                renderInput={(params) => (
                  <TextField {...params} required={true} />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="End Time"
                name="end_time"
                value={state.end_time}
                sx={{ color: "#fff" }}
                className="mt-3 w-100"
                onChange={(e) => handleChangeDate("end_time", e)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div className="col-12 mt-3">
            <div className="mt-4">
              <h4>Notification Setting</h4>
            </div>
            <div className="row mb-3">
              {notificationArray.length > 0 &&
                notificationArray.map((notification, index) => {
                  return (
                    <>
                      <div className="col-12 col-md-6">
                        <FormControl
                          variant="outlined"
                          className="mt-3"
                          fullWidth
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Notify Before
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={notification.notify_before_time}
                            onChange={(e) => {
                              handleChangeNotification(e, index);
                            }}
                            label="Notify Before"
                            name="notify_before_time"
                            className="inputs-fields svg-color"
                            MenuProps={{
                              classes: {
                                paper: classes.paper,
                              },
                            }}
                            sx={{
                              color: get_root_value("--input-text-color"),
                            }}
                          >
                            <MenuItem value={10}>10 Minutes</MenuItem>
                            <MenuItem value={30}>30 Minutes</MenuItem>
                            <MenuItem value={60}>1 Hours</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      {/* <div className="col-12 col-md-5">
                        <TextField
                          className="mt-3"
                          id="outlined-basic"
                          label={`${
                            notification.notify_before_unit == "days"
                              ? "Days"
                              : notification.notify_before_unit == "hours"
                              ? "Hours"
                              : "Minutes"
                          }`}
                          variant="outlined"
                          fullWidth
                          name="notify_before_time"
                          type="number"
                          value={notification.notify_before_time}
                          required={true}
                          onChange={(e) => {
                            handleChangeNotification(e, index);
                          }}
                          inputProps={{
                            min: 0,
                            max:
                              notification.notify_before_unit == "days"
                                ? 100
                                : notification.notify_before_unit == "hours"
                                ? 24
                                : 60,
                          }}
                        />
                      </div> */}
                      {/* <div className="col-1 p-0 mt-4">
                        <Tooltip title="Remove">
                          <RemoveCircleOutlineIcon
                            onClick={() => handleRemoveNotification(index)}
                            className="diary-icon-remove"
                          />
                        </Tooltip>
                      </div> */}
                    </>
                  );
                })}
              {/* <div className="col-12 mt-3 mb-4">
                <FormControlLabel
                  control={
                    <AddCircleOutlineIcon className="diary-icon-add mx-2" />
                  }
                  label="Add Notification"
                  onClick={handleAddNotification}
                />
              </div> */}
            </div>
            <h4>Event Description </h4>
            <GeneralCkeditor
              setInputs={setState}
              inputs={state}
              name="description"
              editorHeight={320}
            />
          </div>
        </form>
      </div>
      <PerformActionOn
        open={openPopup}
        setOpen={setOpenPopup}
        title={"Perform this action On?"}
        handleAgree={updateIteration}
      />
    </>
  );
}
