import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import Label from "src/components/Label";
import AddOrUpdateTickets from "./AddOrUpdateTickets";
import CustomMUITable from "src/components/CustomMUITable";
import {
  clearTrash_api,
  DeleteSupportTicket,
  DeleteSupportTicketApi,
  get_list_support_ticket_with_pagination,
  markResolved,
} from "src/DAL/SupportTicket/SupportTicket";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import CustomDrawer from "src/components/DrawerForm/CustomDrawer";
import { timeAgo } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
function SupportTicketsTabs({
  tabValue,
  valueData,
  setOpen,
  setUserList,
  userList,
}) {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [editDrawerState, setEditDrawerState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [editData, setEditData] = useState({});
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pageCount, setPageCount] = useState(1);
  const [ticketId, setTicketId] = useState("");
  const [openResolved, setOpenResolved] = useState(false);
  const [openClearTrash, setOpenClearTrash] = useState(false);
  const [filterName, setFilterName] = useState("");

  const getTickestsListing = async (value, search) => {
    setIsLoading(true);
    const formData = new FormData();
    if (!value) {
      formData.append("filter_by", "all");
    } else {
      formData.append("filter_by", value);
    }

    let search_text = search ? search : "";
    let filter_by = value ? value : "all";

    const result = await get_list_support_ticket_with_pagination(
      filter_by,
      page,
      rowsPerPage,
      search_text
    );
    if (result.code === 200) {
      const data = result.support_ticket.map((ticket) => {
        let support_ticket_date = moment
          .utc(ticket.support_ticket_date)
          .format("YYYY-MM-DD HH:mm");

        let timeCreated = timeAgo(support_ticket_date);
        let resolvedData = "N/A";
        if (ticket.resolve_date) {
          resolvedData = moment
            .utc(ticket.resolve_date)
            .format("DD-MM-YYYY hh:mm A");
        }

        return {
          ...ticket,
          timeCreated,
          resolvedData,
          MENU_OPTIONS: handleMenu(ticket),
        };
      });
      setOpen(result.counts);
      setTotalCount(result?.total_support_ticket_count);
      setTotalPages(result?.total_pages);
      setUserList(data);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleCloseEditDrawer = () => {
    setEditDrawerState(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };

  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const hanleChangePage = (value) => {
    delete value.MENU_OPTIONS;
    navigate(`/support-ticket/${value._id}`, {
      state: { value, tabValue },
    });
  };

  const handleAgreeDelete = (value) => {
    setTicketId(value);
    setOpenDelete(true);
  };

  const handleEdit = (value) => {
    delete value.MENU_OPTIONS;
    setEditData(value);
    setEditDrawerState(true);
  };

  const handleOpenEditDrawer = () => {
    setEditDrawerState(true);
  };

  const handleMarkResolvedPopup = (value) => {
    setTicketId(value);
    setOpenResolved(true);
  };

  const TABLE_HEAD = [
    {
      id: "action",
      label: "Action",
      type: "action",
      MENU_OPTIONS: "MENU_OPTIONS",
    },
    { id: "reference_number", label: "#" },
    {
      id: "subject",
      label: "Subject",
      renderData: (data) => {
        const truncatedSubject =
          data.subject.length > 40
            ? data.subject.substring(0, 40) + "..."
            : data.subject;
        return <div>{truncatedSubject}</div>;
      },
    },
    { id: "timeCreated", label: "Created At" },
    { id: "resolvedData", label: "Resolved Date" },
    {
      id: "added_by",
      label: " Added By",
      renderData: (data) => {
        return (
          <>
            <Stack direction="column" alignItems="start" spacing={0}>
              <Typography variant="subtitle2" noWrap>
                {data.action_by === "coach_user" ? "Coach" : "Member"}
              </Typography>
              {data.action_info && (
                <Typography
                  sx={{ fontSize: 11, opacity: 0.5 }}
                  variant="subtitle2"
                  noWrap
                >
                  {data?.action_info?.first_name +
                    " " +
                    data?.action_info?.last_name +
                    " (" +
                    data?.action_info?.email +
                    ")"}
                </Typography>
              )}
            </Stack>
          </>
        );
      },
    },
    {
      id: "ticket_status",
      label: "Status",
      renderData: (data) => {
        let ticket_status = data.ticket_status;
        let response_status = data.response_status;
        return (
          <Label
            variant="ghost"
            className={
              ticket_status === 0 && response_status == 1
                ? "answer-ticket"
                : ticket_status == 1
                ? "solved-ticket"
                : ticket_status == 2
                ? "trash-ticket"
                : "pending-ticket"
            }
          >
            {ticket_status === 0 && response_status == 1
              ? "Answered"
              : ticket_status == 1
              ? "Closed"
              : ticket_status == 2
              ? "Trash"
              : "waiting"}
          </Label>
        );
      },
    },
  ];

  const handleMenu = (row) => {
    let MENU_OPTIONS = null;
    if (row.ticket_status === 1 || row.ticket_status === 2) {
      MENU_OPTIONS = [
        {
          label: "Detail",
          icon: "gg:details-more",
          handleClick: hanleChangePage,
        },
        {
          label: "Delete",
          icon: "ant-design:delete-twotone",
          handleClick: handleAgreeDelete,
        },
      ];
    } else {
      MENU_OPTIONS = [
        {
          label: "Detail",
          icon: "gg:details-more",
          handleClick: hanleChangePage,
        },
        {
          label: "Delete",
          icon: "ant-design:delete-twotone",
          handleClick: handleAgreeDelete,
        },
        {
          label: "Mark Resolved",
          icon: "emojione-monotone:heavy-check-mark",
          handleClick: handleMarkResolvedPopup,
        },
      ];
    }
    return MENU_OPTIONS;
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result =
      ticketId.ticket_status === 2
        ? await DeleteSupportTicketApi(ticketId._id)
        : await DeleteSupportTicket(ticketId._id);
    if (result.code === 200) {
      getTickestsListing(valueData, filterName);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleMarkResolved = async () => {
    setOpenResolved(false);
    setIsLoading(true);
    const result = await markResolved(ticketId._id);
    if (result.code === 200) {
      getTickestsListing(valueData, filterName);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/support-ticket`);
    }
  };

  const handleClear = () => {
    setOpenClearTrash(true);
  };

  const handleClearTrash = async () => {
    setOpenClearTrash(false);
    setIsLoading(true);
    const result = await clearTrash_api();
    if (result.code === 200) {
      getTickestsListing(valueData, filterName);
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    getTickestsListing(valueData, filterName);
    localStorage.setItem("support_tickets_data", filterName);
  };

  useEffect(() => {
    let search_text = localStorage.getItem("support_tickets_data");
    if (search_text) {
      setFilterName(search_text);
    }
    getTickestsListing(valueData, search_text);
  }, [rowsPerPage, page, valueData]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container mt-4 p-0">
      <CustomMUITable
        TABLE_HEAD={TABLE_HEAD}
        data={userList}
        className="card-with-background"
        custom_pagination={{
          total_count: totalCount,
          rows_per_page: rowsPerPage,
          page: page,
          handleChangePage: handleChangePage,
          onRowsPerPageChange: handleChangeRowsPerPage,
        }}
        custom_search={{
          searchText: filterName,
          setSearchText: setFilterName,
          handleSubmit: searchFunction,
        }}
        pageCount={pageCount}
        totalPages={totalPages}
        handleChangePages={handleChangePages}
        rows_per_page_count={rowsPerPage}
        pagePagination={true}
        show_extra_button={{
          isShow: valueData === "trash",
          handleClick: handleClear,
          title: "Clear Trash",
        }}
      />
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Ticket?"}
        handleAgree={handleDelete}
      />
      <CustomConfirmation
        open={openResolved}
        setOpen={setOpenResolved}
        title={"Are you sure you want to mark this ticket as resolved?"}
        handleAgree={handleMarkResolved}
      />
      <CustomConfirmation
        open={openClearTrash}
        setOpen={setOpenClearTrash}
        title={"Are you sure you want to clear your trash tickets?"}
        handleAgree={handleClearTrash}
      />
      <CustomDrawer
        isOpenDrawer={editDrawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Edit Support Ticket"
        componentToPassDown={
          <AddOrUpdateTickets
            editData={editData}
            formType="EDIT"
            dataList={getTickestsListing}
            onCloseDrawer={handleCloseEditDrawer}
          />
        }
      />
    </div>
  );
}
export default SupportTicketsTabs;
