import { Avatar } from "@mui/material";
import React, { useState } from "react";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { Icon } from "@iconify/react";
import { getTimeBasedOnDate, string_avatar } from "src/utils/constant";
import ChatMessages from "./ChatMessages";
import { s3baseUrl } from "src/config/config";

function ChatBox(props) {
  const { selectedChat, selectedUser, handleCloseChat } = props;
  const [isExpandedChat, setIsExpandedChat] = useState(false);

  const bottom_in_chat = {
    hidden: {
      y: "100px",
      x: "0px",
      height: "0",
      opacity: 1,
      scale: 1,
    },
    visible: {
      opacity: 1,
      scale: 1,
      height: "67vh",
      y: "0px",
      x: "0px",
      transition: { delay: 0, duration: 0.5 },
    },
  };

  const profile = selectedUser ? selectedUser : selectedChat?.receiver_profile;

  useEffect(() => {
    setIsExpandedChat(true);
  }, [selectedChat, selectedUser]);

  return (
    <>
      <div
        className="user-chat-box-header d-flex align-items-center justify-content-between"
        onClick={() => setIsExpandedChat(!isExpandedChat)}
      >
        <div className="d-flex align-items-center">
          <div className="user-profile">
            <Avatar
              src={s3baseUrl + profile?.profileImage}
              style={{ height: 30, width: 30 }}
            >
              {string_avatar(profile?.name[0])}
            </Avatar>
            <div className={profile?.isOnline ? "online" : "offline"}></div>
          </div>
          <div className="user-box-heading ms-2">
            {profile?.name}
            {getTimeBasedOnDate(
              profile?.lastMessageDateTime,
              "DD MMM YYYY [,] hh:mm A"
            )}
          </div>
        </div>
        <div className="d-flex align-items-center">
          {isExpandedChat ? (
            <Icon icon="akar-icons:chevron-down" />
          ) : (
            <Icon icon="akar-icons:chevron-up" />
          )}
          <Icon
            onClick={handleCloseChat}
            className="ms-2 chat-box-cross-icon"
            icon="charm:cross"
          />
        </div>
      </div>
      <motion.div
        variants={bottom_in_chat}
        initial="hidden"
        animate={isExpandedChat ? "visible" : "hidden"}
        exit="hidden"
        className={""}
      >
        <ChatMessages is_popup={true} isExpandedChat={isExpandedChat} />
      </motion.div>
    </>
  );
}

export default ChatBox;
