import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { htmlDecode } from "src/utils/convertHtml";
import moment from "moment";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { event_status_change_api } from "src/DAL/Calender/Calender";
import { useSnackbar } from "notistack";

export default function EventDetailNew({ eventDetailData, dataList }) {
  const [checked, setChecked] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event) => {
    handleComplate(event.target.checked);
  };

  const handleComplate = async (check) => {
    let type = check ? "complete" : "uncomplete";
    let postData = {};
    const result = await event_status_change_api(
      postData,
      eventDetailData._id,
      type
    );
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setChecked(check);
      dataList();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    setChecked(eventDetailData.completion_status);
  }, [eventDetailData.completion_status]);

  return (
    <>
      <div className="event-details">
        <h1>
          {checked ? "✔" : ""} {htmlDecode(eventDetailData.event_title)}
        </h1>
        <div className="row">
          <div className="col-12 mt-1">
            <div className="d-flex flex-column flex-md-row">
              <div className="d-flex me-4">
                <span>From : </span>
                <p className="ms-1 date-color">
                  {moment(eventDetailData.start_date_time).format("DD-MM-YYYY")}{" "}
                  {eventDetailData.start_time}
                </p>
              </div>
              <div className="d-flex">
                <span>To : </span>
                <p className="ms-1 date-color">
                  {moment(eventDetailData.end_date_time).format("DD-MM-YYYY")}{" "}
                  {eventDetailData.end_time}
                </p>
              </div>
            </div>
            {eventDetailData.description && (
              <>
                <div className="col-12 mb-3">
                  <b>Description :</b>&nbsp;
                </div>
                <div className="col-12">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: eventDetailData.description,
                    }}
                  ></div>
                </div>
              </>
            )}
          </div>
          <div className="col-12 d-flex justify-content-end">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Mark as Complete"
              />
            </FormGroup>
          </div>
        </div>
      </div>
    </>
  );
}
