import { CircularProgress, TextField, Tooltip, Box, Chip } from "@mui/material";

import React, { useEffect, useRef, useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { useSnackbar } from "notistack";

import { makeStyles } from "@mui/styles";

import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  loading2: {
    position: "absolute",
    top: "50%",
    right: "50%",
    zIndex: 1000,
  },
  customInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed

      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },

  select: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed
      backgroundColor: "#ebebeb",
      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },
}));

const SomeThingElseExercisesUI = ({
  addArray,
  setaddArray,
  setModalChangeExe,
  isHistory,
  memberId,
  mainindex,
  message,
  isPersonal,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isReaload, setIsReaload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isHovered, setIsHovered] = useState("");

  const classes = useStyles();

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const maxLength = 50;

  const getPlainText = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
  };

  const plainText = getPlainText(addArray[mainindex].description);

  // Function to update intervalTime state
  const timeoutRef = useRef(null);

  useEffect(() => {
    // Cleanup function to clear the timeout on component unmount
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);
  const handleMouseLeave = () => {
    // Introduce a delay of 100 milliseconds before updating state on hover out
    const delay = 0;
    timeoutRef.current = setTimeout(() => {
      setIsHovered("");
    }, delay);
  };

  const handleAdd = (i) => {
    let list = addArray;

    list.splice(i + 1, 0, {
      exercise: "",
      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          heightType: "cm",
          height: "",
          distance: "",
          distanceType: "m",
          timeType: "sec",
          restType: "sec",
          reps: "",
          rest: "",
          tempo: "",
        },
      ],
      parameters: ["Weight", "Reps", "Rest"],
      time_interval: 60,
      rounds: 10,
      type: "general",
      emomSets: [[]],
      description: "",
      superSet: [],
      superset_sets: "",
    });

    setaddArray([...list]);
    setModalChangeExe(true);
  };

  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);

    setaddArray(delValue);
    setModalChangeExe(true);
  };

  const handleChangeDes = (e, index) => {
    const { name, value } = e.target;

    const list = [...addArray];
    list[index][name] = value;

    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleChangeUp = (value) => {
    if (value == 0) {
      enqueueSnackbar("You are already on Top !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value - 1, 0, reorderedItem);

    setaddArray(items);
    setModalChangeExe(true);
  };

  const handleChangeDown = (value) => {
    if (value >= addArray.length - 1) {
      enqueueSnackbar("You are already on Bottom !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value + 1, 0, reorderedItem);

    setaddArray(items);
    setModalChangeExe(true);
  };
  const memueOptions = (value) => {
    const MENU_OPTIONS = [];
    MENU_OPTIONS.unshift(
      {
        label: "Move Up",
        icon: "mdi:arrow-up",
        handleClick: handleChangeUp,
        disabled: value == 0 ? true : false,
      },
      {
        label: "Move Down",
        icon: "ic:outline-arrow-downward",
        handleClick: handleChangeDown,
        disabled: value >= addArray.length - 1 ? true : false,
      }
      // {
      //   label: "Parameters",
      //   icon: "pajamas:labels",
      //   handleClick: handleParameters,
      // }
    );

    return MENU_OPTIONS;
  };

  if (isReaload === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="my-2">
      {isLoading && (
        <CircularProgress className={classes.loading2} color="primary" />
      )}

      <div className="mt-2 ">
        <div className="characters">
          {/* <form onSubmit={handleSubmit}> */}

          <form onSubmit={() => {}}>
            <div
              style={{
                width: "100%",
                padding: "1px",
                position: "relative",
              }}
            >
              <div
                // direction="row"

                className="d-flex my-1 workout-set-card w-100"
                onMouseEnter={() => handleMouseLeave()}
                style={{
                  width: "100%",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                  }}
                  onMouseEnter={() => handleMouseLeave()}
                >
                  <div
                    className="d-flex align-items-start justify-content-between w-100 "
                    onMouseEnter={() => handleMouseLeave()}
                  >
                    <div className="w-100 d-flex align-items-center">
                      {addArray[mainindex].is_modified && (
                        <span
                          style={{
                            position: "absolute",
                            top: "-11px",
                            justifyContent: "center",
                            zIndex: "1",
                            left: "-38px",
                            transform: "rotate(-53deg)",
                          }}
                        >
                          <Chip
                            label={"Modified"}
                            variant="contained"
                            color={"error"}
                            sx={{
                              fontSize: "10px",
                              height: "auto",
                              width: "auto",
                            }}
                          />
                        </span>
                      )}
                      <Tooltip placement="top" title={message}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          placeholder="Name"
                          value={
                            addArray[mainindex].title
                              ? addArray[mainindex].title
                              : ""
                          }
                          name="title"
                          onChange={(e) => handleChangeDes(e, mainindex)}
                          InputLabelProps={{
                            className: "textfiels-input-label",
                            shrink: true,
                            style: { fontSize: "14px" },
                          }}
                          label="Name"
                          InputProps={{
                            className: "textfiels-input-value",
                            style: {
                              fontSize: "13px",
                              paddingLeft: "6px",
                            },

                            inputProps: {
                              readOnly: true,
                            },
                          }}
                          sx={{
                            borderRadius: "5px",

                            fontSize: "13px",
                            width: "50%",
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div className=" cross-icon d-flex justify-content-end ">
                      {addArray.length > 1 ? (
                        <>
                          <Tooltip placement="top" title={message}>
                            <RemoveCircleOutlineIcon
                              // onClick={() => handleDelete(mainindex)}
                              className="diary-icon-remove"
                            />
                          </Tooltip>
                        </>
                      ) : (
                        ""
                      )}
                      <Tooltip placement="top" title={message}>
                        <AddCircleOutlineIcon
                          // onClick={() => handleAdd(mainindex)}
                          className="diary-icon-add"
                        />
                      </Tooltip>
                      <Tooltip placement="top" title={message}>
                        <span>
                          <CustomPopoverSection menu={[]} data={mainindex} />
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                  <Tooltip placement="top" title={message}>
                    {addArray[mainindex].description && (
                      <div className="col-12 mt-3 something_else_description">
                        <div className="custom_description_title">
                          Description
                        </div>
                        <div className="custom_description_text">
                          <div
                            style={{
                              whiteSpace: isExpanded ? "normal" : "pre-wrap",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: isExpanded
                                ? addArray[mainindex].description
                                : plainText.substring(0, maxLength),
                            }}
                          />
                          {plainText.length > maxLength && (
                            <span
                              className="toggle-text"
                              style={{
                                color: "var(--portal-theme-primary)",
                                cursor: "pointer",
                              }}
                              onClick={toggleExpand}
                            >
                              {isExpanded ? "See Less" : "See More"}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                    {/* <TextField
                      id="outlined-basic"
                      size="small"
                      variant="outlined"
                      placeholder="Description"
                      label="Description"
                      type="number"
                      value={addArray[mainindex].description}
                      name="description"
                      multiline
                      rows={3}
                      onChange={(e) => handleChangeDes(e, mainindex)}
                      InputLabelProps={{
                        className: "textfiels-input-label",
                        shrink: true,
                        style: { fontSize: "14px" },
                      }}
                      InputProps={{
                        className: "textfiels-input-value",
                        style: { fontSize: "14px" },
                        readOnly: true,
                      }}
                      sx={{
                        borderRadius: "5px",
                        // "& legend": { display: "none" },
                        // "& fieldset": { top: 0 },

                        mt: 2,
                        width: "100%",
                      }}
                    /> */}
                  </Tooltip>
                </Box>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default SomeThingElseExercisesUI;
