import {
  Button,
  CircularProgress,
  FormHelperText,
  IconButton,
  TextField,
} from "@mui/material";

import React, { useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { makeStyles } from "@mui/styles";

import { AddWorkoutApi } from "src/DAL/workout/workout";
import FoodMattersAndMindsetUI from "./FoodMattersAndMindsetUI";
import FileUploadIcon from "@mui/icons-material/FileUpload";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  customInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed

      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },

  select: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed
      backgroundColor: "#ebebeb",
      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },
}));
const AddVideoSet = ({}) => {
  // console.log(addArray, setaddArray, "dflkadsjjkfahds");

  const { state } = useLocation();

  const [addArray, setaddArray] = useState([
    {
      exercise: "",
      title: "",
      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          heightType: "cm",
          height: "",
          distance: "",
          distanceType: "m",
          timeType: "sec",
          restType: "sec",
          reps: "",
          rest: "",
          tempo: "",
          vdot: null,
          race_type: "c",
          pace_type: "c_km",
          pace: 0,
          paceUp: 423,
          optionsType: [
            { title: "1 KM Pace", key: "c_km" },
            { title: "1 MILE Pace", key: "c_mi" },
          ],
        },
      ],
      parameters: ["Weight", "Reps", "Rest"],
      time_interval: 60,
      rounds: 10,
      type: "something_else",
      description: "",

      superSet: [],
      emomSets: [[]],
      superset_sets: "",
    },
  ]);
  const handleAdd = () => {
    let list = addArray;
    list.splice(addArray.length, 0, {
      exercise: "",
      title: "",
      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          heightType: "cm",
          height: "",
          distance: "",
          distanceType: "m",

          timeType: "sec",
          restType: "sec",
          reps: "",
          rest: "",
          tempo: "",
          vdot: null,
          race_type: "c",
          pace_type: "c_km",
          pace: 0,
          paceUp: 423,
          optionsType: [
            { title: "1 KM Pace", key: "c_km" },
            { title: "1 MILE Pace", key: "c_mi" },
          ],
        },
      ],
      parameters: ["Weight", "Reps", "Rest"],
      time_interval: 60,
      rounds: 10,
      type: "something_else",
      description: "",

      superSet: [],
      emomSets: [[]],
      superset_sets: "",
    });

    setaddArray([...list]);
  };
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [isReaload, setIsReaload] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const [inputs, setInputs] = useState({
    video_url: "",
    image: "",
  });
  const [file, setImage] = React.useState();
  const [imageStatus, setImageStatus] = useState(false);
  const urlPatternValidation = (URL) => {
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    return regex.test(URL);
  };
  const fileChangedHandler = (e) => {
    setImageStatus(true);
    setImage(URL.createObjectURL(e.target.files[0]));
    console.log(e.target.files[0], "sdalkfjlkdsjfa");
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    if (
      urlPatternValidation(inputs.video_url) === false &&
      inputs.video_url != ""
    ) {
      enqueueSnackbar("Enter Valid Video URL", { variant: "error" });
      setIsLoading(false);
      return;
    }
    if (inputs.video_url == "" && inputs.image != "") {
      enqueueSnackbar("Please Enter Video URL ", {
        variant: "error",
      });
      setIsLoading(false);
      return;
    }

    if (inputs.video_url && inputs.image == "") {
      enqueueSnackbar("Please Upload Thumbnail", { variant: "error" });
      setIsLoading(false);
      return;
    }
    let isError = false;
    let exerciesArray = [];
    if (isError) {
      setIsLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("title", state.title);

    formData.append("image", state.image);
    formData.append("practice_type", "video");
    if (imageStatus) {
      formData.append("video_thumbnail", inputs.image);
    }

    formData.append("video_url", inputs.video_url);
    formData.append("type", state.type);
    formData.append(
      "difficulty_levels",
      JSON.stringify(state?.difficulty_levels)
    );
    formData.append("description", state.short_description);
    formData.append("status", state.status);
    formData.append("exercises", JSON.stringify(exerciesArray));
    console.log(exerciesArray, "dskjfhakjdsh");
    console.log(...formData, "dskjfhakjdsh");

    const result = await AddWorkoutApi(formData);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      navigate(-1);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  if (isReaload === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className=" mt-3">
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className="col-9">
          <h1 className="programmes-heading">{state?.title}</h1>
          <div className="col-12 section-space">
            <p>
              <div
                dangerouslySetInnerHTML={{
                  // __html: lessonDetailInfo?.detailed_description,
                  __html: state?.short_description,
                }}
              ></div>
            </p>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-lg-5 col-md-5 col-sm-12 ">
            <TextField
              id="outlined-basic"
              label="Video Url"
              variant="outlined"
              fullWidth
              required
              name="video_url"
              value={inputs.video_url}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-7 col-md-7 col-sm-12">
            <div className="row w-100 div-style ms-0 p-0">
              <div className="col-lg-5 col-md-5 col-sm-12">
                <p className="mb-0">Upload thumbnail *</p>
                <FormHelperText className="pt-0" sx={{ fontSize: 9 }}>
                  Image Size(500 X 500) ("JPG", "JPEG", "PNG","WEBP")
                </FormHelperText>
              </div>
              <div className=" col-lg-2 col-md-2 col-sm-12">
                {file ? (
                  <img className="image-border" src={file} height="50" />
                ) : (
                  ""
                )}
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12  text-lg-end text-md-end text-sm-start pt-2">
                <label htmlFor="contained-button-file">
                  <input
                    accept=".jpg, .jpeg, .png, .webp"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    required
                    onChange={fileChangedHandler}
                    style={{ display: "none" }}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                    variant="contained"
                    size="small"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs.image.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs.image.name}</p>
            )}
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="text-end mt-4">
            <button className="small-contained-button">
              {isLoading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default AddVideoSet;
