import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Button,
} from "@mui/material";
import {
  add_booking_setting_api,
  add_booking_setting_api_program,
  detail_booking_setting_api_program,
  siteSettingApi,
} from "src/DAL/SiteSetting/siteSetting";
import TinyEditor from "src/components/ckeditor/Ckeditor";
import { s3baseUrl } from "src/config/config";
import { useNavigate, useParams } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const Input = styled("input")({
  display: "none",
});

export default function ProgrammeBoookingSetting() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [loginFormHeading, setloginFormHeading] = useState("");
  const [inputs, setInputs] = useState({
    title: "",
    redirect_url: "",
    status: true,
    image: "",
    description: "",
  });

  const siteSettingData = async () => {
    setIsLoading(true);
    const result = await detail_booking_setting_api_program(id);
    if (result.code == 200) {
      setInputs({
        ...inputs,
        ...result.bookingDetail,
        image: result.bookingDetail?.image?.thumbnail_1 || "",
      });
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const fileChangedHandler = (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));
    setInputs((values) => ({ ...values, image: e.target.files[0] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputs.image) {
      enqueueSnackbar("Please upload Booking image", { variant: "error" });
      return;
    }
    setIsLoadingForm(true);
    const postData = new FormData();
    postData.append("title", inputs.title);
    postData.append("redirect_url", inputs.redirect_url);
    postData.append("status", inputs.status);
    postData.append("description", inputs.description);
    postData.append("program", id);
    if (file) {
      postData.append("image", inputs.image);
    }

    console.log(...postData, "postData");

    const result = await add_booking_setting_api_program(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    siteSettingData();
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <h2>
            <IconButton
              className="back-screen-button mb-0 me-2"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
            Manage Booking Setting
          </h2>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Title"
              variant="outlined"
              fullWidth
              required
              name="title"
              value={inputs.title}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <TextField
              id="outlined-basic"
              label="Redirect URI"
              variant="outlined"
              type="url"
              fullWidth
              required
              name="redirect_url"
              value={inputs.redirect_url}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
            <FormControl fullWidth>
              <InputLabel id="status">Status</InputLabel>
              <Select
                labelId="status"
                id="demo-simple-select"
                name="status"
                value={inputs.status}
                label="Status"
                required
                onChange={handleChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="row w-100 div-style ms-0 pt-0">
              <div className="col-5">
                <p>Upload Booking Image *</p>
                <FormHelperText className="pt-0">
                  (Recommended Size 1000 X 670)
                </FormHelperText>
              </div>
              <div className="col-2">
                {file ? (
                  <img className="image-border" src={file} height="50" />
                ) : (
                  <>
                    {inputs.image && (
                      <img
                        className="image-border"
                        src={s3baseUrl + inputs.image}
                        height="50"
                      />
                    )}
                  </>
                )}
              </div>
              <div className="col-5 text-end pt-2">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="image"
                    onChange={fileChangedHandler}
                  />

                  <Button
                    className="small-contained-button"
                    startIcon={<FileUploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </div>
            </div>
            {inputs?.image?.name == "" ? (
              ""
            ) : (
              <p className="text-secondary">{inputs?.image?.name}</p>
            )}
          </div>
          <div className="col-12 mt-3">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Short Description"
                multiline
                rows={6}
                name="description"
                value={inputs.description}
                onChange={handleChange}
              />
              <FormHelperText>Maximum limit 500 characters</FormHelperText>
            </FormControl>
          </div>
          <div className="text-end mt-4">
            <button className="small-contained-button">
              {isLoadingForm ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
