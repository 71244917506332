import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import barchartfill from "@iconify/icons-eva/bar-chart-fill";

import twotoneSportsGymnastics from "@iconify/icons-ic/twotone-sports-gymnastics";
// ----------------------------------------------------------------------

const getIcon = (name, hFlip) => (
  <Icon
    icon={name}
    width={22}
    height={22}
    hFlip={hFlip}
    className="navbar-icon"
  />
);
const getImageIcon = (name) => (
  <img className="navbar-icon" src={name} width={22} height={22} />
);
const sidebarConfig = [];

sidebarConfig.push(
  // {
  //   title: "dashboard",
  //   path: "/dashboard",
  //   icon: getIcon(pieChart2Fill),
  // },
  {
    title: "My Calendar",
    path: "/calendar-events",
    icon: getIcon("mdi:calendar"),
  },
  {
    title: "Programmes",
    path: "/list",
    icon: getIcon(barchartfill),
    children: [
      {
        title: "Active Programmes",
        path: "/programmes",
        icon: getIcon(barchartfill),
      },
      {
        title: "Inactive Programmes",
        path: "/inactive-programmes",
        icon: getIcon(barchartfill),
      },
      {
        title: "Coach Programmes Templates",
        path: "/programme_templats",
        icon: getIcon(barchartfill),
      },
    ],
  },

  // {
  //   title: "Programmes",
  //   path: "/programmes",
  //   icon: getIcon(barchartfill),
  // },
  {
    title: "Members",
    path: "/member",
    icon: getIcon("mdi:person"),
  },
  {
    title: "Chat",
    path: "/chat",
    icon: getIcon("mdi:chat"),
  },
  {
    title: "Coach",
    path: "/coach",
    icon: getIcon("mdi:whistle-outline", true),
  },
  {
    title: "Subscription List",
    path: "/subscription-list",
    icon: getIcon("wpf:renew-subscription"),
  },
  {
    title: "Exercises",
    path: "/list",
    icon: getIcon("healthicons:exercise-walking"),
    children: [
      {
        title: "Exercise library",
        path: "/exercise",

        icon: getIcon("healthicons:exercise-walking"),
      },
      {
        title: "Exercises Type",
        path: "/type-exercise",
        icon: getIcon("healthicons:exercise-walking"),
      },
      {
        title: "Exercises Tags",
        path: "/tages-exercise",
        icon: getIcon("healthicons:exercise-walking"),
      },
    ],
  },
  {
    title: "Workout",
    path: "/list",
    icon: getIcon("mdi:gym"),
    children: [
      {
        title: "workout Library",
        path: "/workout",

        icon: getIcon("mdi:gym"),
      },
      {
        title: "workout Type",
        path: "/type-workout",
        icon: getIcon("mdi:gym"),
      },
    ],
  },
  {
    title: "Food Matters",
    path: "/list",
    icon: getIcon("fluent:food-24-filled"),
    children: [
      {
        title: "Food Matters Library",
        path: "/food_matters",

        icon: getIcon("fluent:food-24-filled"),
      },
      {
        title: "Food Matters Type",
        path: "/food_matters_type",
        icon: getIcon("fluent:food-24-filled"),
      },
    ],
  },
  {
    title: "Mindset",
    path: "/list",
    icon: getIcon("flowbite:brain-outline"),
    children: [
      {
        title: "Mindset Library",
        path: "/mindset",

        icon: getIcon("flowbite:brain-outline"),
      },
      {
        title: "Mindset Type",
        path: "/mindset_type",
        icon: getIcon("flowbite:brain-outline"),
      },
    ],
  },
  {
    title: "Videos",
    path: "/list",
    icon: getIcon("octicon:video-24"),
    children: [
      {
        title: "Videos Library",
        path: "/videoset",

        icon: getIcon("octicon:video-24"),
      },
      {
        title: "Videos Type",
        path: "/videoset_type",
        icon: getIcon("octicon:video-24"),
      },
    ],
  },
  {
    title: "Community Area",
    path: "/main-community-area",
    icon: getIcon("healthicons:group-discussion-meetingx3"),
    children: [
      {
        title: "General Community Area",
        path: "/community-area",

        icon: getIcon("fluent:people-community-16-filled"),
      },
      {
        title: "Pending Posts",
        path: "/pending-posts",

        icon: getIcon("mdi:account-pending"),
      },
      {
        title: "Rejected Posts",
        path: "/rejected-posts",

        icon: getIcon("mdi:account-cancel"),
      },
      {
        title: "Groups",
        path: "/groups",
        icon: getIcon("fluent:people-community-16-filled"),
      },
    ],
  }
);
// ===============================> unhide that options when asked for the feature

// sidebarConfig.push({
//   title: "BMS Recording",
//   path: "/list",

//   icon: getIcon("ic:round-date-range"),
//   children: [
//     {
//       title: "Recordings",
//       path: "/recording-list",

//       icon: getIcon("ic:round-date-range"),
//     },
//     {
//       title: "Categories",
//       path: "/categories",
//       icon: getIcon("ic:round-date-range"),
//     },
//   ],
//   /* "children" key can be added in these objects, example:children:[{...}] */
// });
sidebarConfig.push({
  title: "Payments",
  path: "/list",

  icon: getIcon("ic:round-date-range"),
  children: [
    {
      title: "Stripe Products",
      path: "/stripe-products",

      icon: getIcon("ic:round-date-range"),
    },
    {
      title: "Transactions",
      path: "/payment-transactions",
      icon: getIcon("ic:round-date-range"),
    },
    // {
    //   title: "Payment Templates",
    //   path: "/payment-templates",
    //   icon: getIcon("ic:round-date-range"),
    // },
    // {
    //   title: "Payment Requests",
    //   path: "/payment-requests",
    //   icon: getIcon("ic:round-date-range"),
    // },
  ],
  /* "children" key can be added in these objects, example:children:[{...}] */
});
// sidebarConfig.push({
//   title: "Calendar",
//   path: "/list",

//   icon: getIcon("ic:round-date-range"),
//   children: [
//
//     {
//       title: "Calendar Events",
//       path: "/calendarEvents",
//       icon: getIcon("ic:round-date-range"),
//     },
//     {
//       title: "Pods",
//       path: "/calendar-pods",
//       icon: getIcon("ic:round-date-range"),
//     },
//   ],
//   /* "children" key can be added in these objects, example:children:[{...}] */
// });

sidebarConfig.push({
  title: "Support",
  icon: getIcon("ic:round-support-agent"),
  children: [
    {
      title: "Support Tickets",
      path: "/support-ticket",
      icon: getIcon("ic:round-task-alt"),
    },
  ],
});

sidebarConfig.push({
  title: "Client Portal",
  path: "/list",

  icon: getIcon("fluent:settings-20-filled"),
  children: [
    {
      title: "User Form",
      path: "/form-list",
      icon: getIcon("fluent:form-48-filled"),
    },

    {
      title: "User Form Templates",
      path: "/user_form-templates",

      icon: getIcon("fluent:form-48-filled"),
    },

    {
      title: "General Setting",
      path: "/general-setting",

      icon: getIcon("ic:round-date-range"),
    },
    // {
    //   title: "Client Dashboard",
    //   path: "/client-dashboard",
    //   icon: getIcon("ic:round-date-range"),
    // },
    {
      title: "Content Setting",
      path: "/content-setting",
      icon: getIcon("ic:round-date-range"),
    },
  ],
  /* "children" key can be added in these objects, example:children:[{...}] */
});
sidebarConfig.push({
  title: "General Setting",
  path: "/list",

  icon: getIcon("fluent:settings-20-filled"),
  children: [
    {
      title: "Default Setting",
      path: "default-setting",
      icon: getIcon("ic:round-date-range"),
    },
    {
      title: "Email Setting",
      path: "/email-setting",
      icon: getIcon("ic:round-date-range"),
    },
    {
      title: "Stripe Setting",
      path: "/stripe-setting",
      icon: getIcon("ic:round-date-range"),
    },
    // {
    //   title: "Campaign Setting",
    //   path: "/campaign-setting",
    //   icon: getIcon("ic:round-date-range"),
    // },
    {
      title: "Invoice Setting",
      path: "/invoice-setting",
      icon: getIcon("ic:round-date-range"),
    },
    {
      title: "Email Templates",
      path: "/email-templates",
      icon: getIcon("ic:round-date-range"),
    },
    {
      title: "Calendar Setting",
      path: "/calendar-setting",
      icon: getIcon("ic:round-date-range"),
    },
    {
      title: "Manage Booking Setting",
      path: "/manage-booking-setting",
      icon: getIcon("ic:round-date-range"),
    },
  ],
  /* "children" key can be added in these objects, example:children:[{...}] */
});
sidebarConfig.push({
  title: "Manage Website",
  path: "/list",

  icon: getIcon("fluent:settings-20-filled"),
  children: [
    {
      title: "Plan Category",
      path: "/plan_category",

      icon: getIcon("iconamoon:category"),
    },
    {
      title: "Pages",
      path: "/website-pages",

      icon: getIcon("ic:round-date-range"),
    },
    {
      title: "Template Pages",
      path: "/template-pages",
      icon: getIcon("ic:round-date-range"),
    },
    {
      title: "Menus",
      path: "/menus",
      icon: getIcon("ic:round-date-range"),
    },
  ],
  //   /* "children" key can be added in these objects, example:children:[{...}] */
});
sidebarConfig.push({
  title: "Helping Videos",
  path: "/list",
  icon: getIcon("fluent:video-clip-20-filled"),
  children: [
    {
      title: "Coach Videos",
      path: "/coaches_videos_category",
      icon: getIcon("ic:round-video-library"),
    },
    {
      title: "Member Videos",
      path: "/members_videos_category",
      icon: getIcon("ic:round-video-library"),
    },
  ],
  /* "children" key can be added in these objects, example:children:[{...}] */
});
sidebarConfig.push({
  title: "Gym TV",
  path: "/WorkoutDisplay",
  icon: getIcon("ic:baseline-tv"),

  /* "children" key can be added in these objects, example:children:[{...}] */
});
export default sidebarConfig;
