import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Icon } from "@iconify/react";

import {
  Card,
  CardContent,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
import { useSnackbar } from "notistack";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalToSetprogram({
  open,
  setOpen,
  inputs,
  setInputs,
  setSelectedWorkout,
}) {
  const handleClose = () => {
    setOpen(false);
  };
  const { enqueueSnackbar } = useSnackbar();
  const handleOk = () => {
    if (!inputs.programs_id) {
      enqueueSnackbar("Please Select Program", { variant: "error" });
      return;
    }
    if (!inputs.workout_of_day) {
      enqueueSnackbar("Please Select Workout", { variant: "error" });
      return;
    }
    setSelectedWorkout(inputs);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle>{"Select today workout"}</DialogTitle>
        <DialogContent className="">
          <DialogContentText id="alert-dialog-slide-description">
            <Autocomplete
              id="tags-outlined"
              options={inputs.programs || []}
              clearOnBlur={true}
              getOptionLabel={(option) => option?.title || ""}
              filterSelectedOptions
              value={inputs.programs_id}
              onChange={(event, newValue) => {
                // setMember(newValue);
                setInputs((values) => ({
                  ...values,
                  programs_id: newValue,
                  workout_of_day: newValue?.workouts?.[0] || null,
                }));
              }}
              className="mui-autocomplete mt-2"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Programs"
                  placeholder="Programs"
                />
              )}
            />
            <Autocomplete
              id="tags-outlined"
              options={inputs?.programs_id?.workouts || []}
              clearOnBlur={true}
              getOptionLabel={(option) => option?.title || ""}
              filterSelectedOptions
              value={inputs.workout_of_day}
              onChange={(event, newValue) => {
                // setMember(newValue);
                setInputs((values) => ({
                  ...values,
                  workout_of_day: newValue,
                }));
              }}
              className="mui-autocomplete mt-2"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Workout"
                  placeholder="Workout"
                  className="mt-2"
                />
              )}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleOk}>Ok</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
