import { useFormik } from "formik";
import { useEffect, useState } from "react";
// material
import {
  CircularProgress,
  Container,
  Stack,
  TextField,
  Typography,
  Chip,
} from "@mui/material";
// components

//

import { deleteProgramme, programmeListing } from "src/DAL/Programme/Programme";

import { s3baseUrl } from "src/config/config";
import { convertToTitleCase, htmlDecode } from "src/utils/convertHtml";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { filter } from "lodash";
import { useNavigate } from "react-router-dom";

import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";

// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
export default function ProgrammesTemplate() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);
  const [orderBy, setOrderBy] = useState("number");
  const [order, setOrder] = useState("asc");
  const [program, setProgram] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState("");

  const formik = useFormik({
    initialValues: {
      gender: "",
      category: "",
      colors: "",
      priceRange: "",
      rating: "",
    },
    onSubmit: () => {
      setOpenFilter(false);
    },
  });

  const { resetForm, handleSubmit } = formik;
  const programsList = async () => {
    setIsLoading(true);
    const programType = true;
    const result = await programmeListing(programType);
    if (result.code == 200) {
      // console.log(result.program, "kkkkkkkkkkk");

      setIsLoading(false);
      let res = [
        {
          action_by: "admin_user",
          action_id: "6465b185fb021a104be41c7f",
          audio_file: "",
          createdAt: "2023-07-21T04:44:52.533Z",

          is_default: true,
          is_program_show_on_list: true,
          locked_program_info: {
            video_url: "",
            short_description: "",
            detailed_description: "",
            image: "",
          },
          nav_items: [],
          no_of_lesson: 17,
          no_of_limited_days: 90,
          no_of_start_days: 0,
          order: 1,
          program_access_type: "limited",
          program_images: {
            thumbnail_1:
              "https://media.istockphoto.com/id/922112426/photo/person-writing-workout-plan-in-notebook.jpg?s=612x612&w=0&k=20&c=qBQrdMpBrjEZrpAAbVksFyZPPFmzBDNdDdAH69qbArU=",
            thumbnail_2: "program/b6a3c3f8-91e5-4be2-a46a-e672a46077ad.png",
            thumbnail_3: "program/3408d4cd-7ff6-412a-9bde-4b96313aa338.png",
          },
          program_slug: "default-program-1",
          short_description:
            "Lorem ipsum dolor sit amet consectetur adipiscing, elit penatibus leo aptent eros rhoncus magnis.",
          status: true,
          title: "90 Days Transformation",
          total_lesson_duration: "39 hr 15 min 17 sec",
          updatedAt: "2023-12-01T05:12:22.428Z",
          video_url: "https://vimeo.com/46926279",
        },
      ];
      setProgram(result.program);
      // setProgram(res);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleNavigateDetailForms = (value) => {
    ////console.log(value, "valueeeee");
    navigate(`/programmes/programmes-form/${value._id}`, {
      state: value,
    });
  };
  const handleNavigateDetail = (value) => {
    //console.log(value, "valueeeee");
    navigate(
      `/programme_templats/programme_templats-detail/${value.program_slug}`,
      {
        state: value,
      }
    );
  };

  const filteredPrograme = applySortFilter(
    program,
    getComparator(order, orderBy),
    filterName.trim()
  );
  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };
  const handleNavigateEdit = (value) => {
    console.log("edit value", value);
    navigate(
      `/programme_templats/edit-programme_templats/${value?.program_slug}`,
      {
        state: value,
      }
    );
  };
  const handleNavigateAdd = (value) => {
    navigate(`/programmes/${"add"}/${value._id}`, {
      state: value,
    });
  };
  const handleNavigateRemove = (value) => {
    navigate(`/programmes/${"remove"}/${value._id}`, {
      state: value,
    });
  };
  const handleNavigateCalendar = (value) => {
    console.log("edit value", value);
    if (value.program_length_type == "limited") {
      navigate(`/programme_templats-limited-calendar/${value?.program_slug}`, {
        state: value,
      });
    } else {
      navigate(`/programme_templats-calendar/${value?.program_slug}`, {
        state: value,
      });
    }
  };
  const handleDelete = async () => {
    //console.log(state.detailValues.program_slug, "state.program_slug");
    let result = await deleteProgramme(deleteDoc?.program_slug);
    if (result.code === 200) {
      handleCloseDelete();
      enqueueSnackbar(result.message, { variant: "success" });
      programsList();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      handleCloseDelete();
    }
  };
  const handleOpenDelete = (value) => {
    setDeleteDoc(value);

    setOpenDelete(true);
    //console.log(openDelete, "openDelete");
  };
  const handleCloseDelete = () => setOpenDelete(false);

  const handleResetFilter = () => {
    handleSubmit();
    resetForm();
  };
  useEffect(() => {
    programsList();
  }, []);
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleNavigateEdit,
    },

    {
      label: "Details",
      icon: "bx:detail",
      handleClick: handleNavigateDetail,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleOpenDelete,
    },
  ];
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  const isRecordNotFound = filteredPrograme.length === 0;
  console.log(isRecordNotFound, "dsflksd");
  return (
    // <div
    //   style={{
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     height: "100%",
    //   }}
    // >
    //   <img
    //     src={bgConsImage}
    //     alt="under construction"
    //     style={{ height: "468px" }}
    //   />
    // </div>
    <div className="container mt-2">
      <CustomConfirmation
        handleOpenDelete={handleOpenDelete}
        setOpen={setOpenDelete}
        handleCloseDelete={handleCloseDelete}
        handleAgree={handleDelete}
        open={openDelete}
        setOpenDelete={setOpenDelete}
      />
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <h2>Coach Programme Template</h2>
        </div>
        <div className="col-lg-6 col-sm-12 d-flex justify-content-end">
          <TextField
            fullWidth
            label="Search"
            sx={{ marginRight: 2, width: 240 }}
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
          />
          <button
            onClick={() =>
              navigate(`/programme_templats/add-programme_templats`)
            }
            className="small-contained-button"
          >
            Add Template
          </button>
        </div>
        {filteredPrograme.map((value, index) => {
          return (
            <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={index}>
              <div className="card mt-4 pods-cards-shadow cursor h-100 overflow-hidden ">
                {!value?.status && (
                  <span
                    style={{
                      position: "absolute",
                      top: "36px",
                      justifyContent: "center",
                      zIndex: "1",
                      left: "-38px",
                      transform: "rotate(-53deg)",
                    }}
                  >
                    {/* {!x?.status && ( */}
                    <Chip
                      label={"Inactive"}
                      variant="contained"
                      color={"error"}
                      sx={{
                        fontSize: "10px",
                        height: "auto",
                        width: "150px",
                      }}
                    />
                    {/* )} */}
                  </span>
                )}
                <img
                  src={s3baseUrl + value.program_images.thumbnail_1}
                  className="card-img-top pods-image"
                  alt="Programme"
                  onClick={() => handleNavigateCalendar(value)}
                />
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <h3
                        className="h2-heading"
                        onClick={() => handleNavigateCalendar(value)}
                      >
                        {htmlDecode(value.title)}
                      </h3>
                    </div>

                    <div className="col-2 programmes-menu-option">
                      {/* <CustomPopover menu={MENU_OPTIONS} data={value} /> */}
                      <CustomPopoverSection menu={MENU_OPTIONS} data={value} />
                    </div>
                    {/* <CustomConfirmation
                        open={openDelete}
                        setOpen={setOpenDelete}
                        title={
                          "Are you sure you want to delete this recording?"
                        }
                        handleAgree={handleDelete}
                      /> */}
                  </div>
                  <p
                    className="programme-card-desc mb-3"
                    onClick={() => handleNavigateCalendar(value)}
                  >
                    {htmlDecode(value.short_description)}
                  </p>
                  {/* <p className="programme-card-desc mb-3 programme-card-desc-muted">
                      <b className="text-muted">Programme:</b>{" "}
                      {value.program.title}
                    </p> */}
                  <div
                    className="row recording-card-date mb-2"
                    onClick={() => handleNavigateCalendar(value)}
                  >
                    <div className="col-5 card-button recording-card-date-position">
                      <p className="pods-active-members">
                        {/* {value?.status && <span> {value.members} Members</span>} */}
                      </p>
                    </div>
                    <div className="col-7 text-end ms-auto pods-active-members text-capital">
                      {/* {!x?.status && (
                      <Chip
                        label={""}
                        variant="contained"
                        color={"error"}
                        sx={{
                          fontSize: "10px",
                          height: "auto",
                          width: "auto",
                        }}
                      />
                      )} */}
                      {/* Limited */}
                      {`${
                        value.program_type ? value.program_type : "General"
                      } | ${convertToTitleCase(value.program_length_type)}`}
                      {/* {convertToTitleCase(value.program_length_type)} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {isRecordNotFound && (
          <Typography
            variant="h5"
            align="center"
            style={{ marginTop: 90 }}
            justifyContent="center"
          >
            No results found
            {/* for &nbsp;
        <strong>&quot;{searchQuery}&quot;</strong>. Try checking for typos or using complete words. */}
          </Typography>
        )}
      </div>
      {/* <Modal
        open={openDelete}
        onClose={handleCloseDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}>
            <b> Are you sure you want to delete ?</b>
          </Typography>
          <div className="text-end mt-2">
            <button className="me-2 small-contained-button">Cancel</button>
            <button className="small-contained-button">Agreed</button>
            <Button onClick={handleCloseDeleteModal}>Cancel</Button>
            <Button onClick={handleAgreeDelete}>Agree</Button>
          </div>
        </Box>
      </Modal> */}
    </div>
  );
}
