import React, { useCallback, useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { Avatar, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { _member_list_with_search } from "src/DAL/Chat/Chat";
import { string_avatar } from "src/utils/constant";
import { s3baseUrl } from "src/config/config";

export default function NewChatUsers({ handleAddChat, onCloseDrawer }) {
  const observer = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [chatUsersList, setChatUsersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [moreLoad, setMoreLoad] = useState(false);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const load_more_path = "api/chat/users?page=0&limit=20";
  const [state, setState] = useState({
    search_text: "",
  });

  const getChatUsersList = async (path, search_text, loading) => {
    if (!loading) {
      path = load_more_path;
    }
    const result = await _member_list_with_search(path, search_text);
    if (result.code === 200) {
      setLoadMorePath(result?.load_more_url);
      setMoreLoad(result?.coaches?.hasMore || result?.members?.hasMore);
      let new_coaches_list = [];
      if (result?.coaches?.data.length > 0) {
        new_coaches_list = result?.coaches?.data.map((coach) => {
          let full_name = coach.first_name + " " + coach.last_name + " (Coach)";
          return {
            ...coach,
            full_name: full_name,
            profile_image: coach?.image?.thumbnail_1,
          };
        });
      }
      let new_members_list = [];
      if (result?.members?.data.length > 0) {
        new_members_list = result?.members?.data.map((member) => {
          let full_name =
            member.first_name + " " + member.last_name + " (Member)";
          return {
            ...member,
            full_name: full_name,
            profile_image: member?.profile_image,
          };
        });
      }

      let final_list = [...new_coaches_list, ...new_members_list];

      if (loading) {
        setChatUsersList((prevList) => [...prevList, ...final_list]);
      } else {
        setChatUsersList(final_list);
      }
      setLoading(false);
      setIsLoadingMore(false);
    } else {
      setLoading(false);
      setIsLoadingMore(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const loadMoreData = () => {
    setIsLoadingMore(true);
    getChatUsersList(loadMorePath, state.search_text, true);
  };

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && moreLoad) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, moreLoad, loadMorePath, observer]
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    getChatUsersList(load_more_path, state.search_text);
  }, [state.search_text]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center mt-5">
        <CircularProgress size={30} />
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 padding_left">
          <div className="new-message-search-box mt-3">
            {/* <Icon
              fontSize={20}
              className="new-message-search-icon"
              icon="akar-icons:search"
            /> */}
            <input
              className="new-message-search-input"
              type="text"
              placeholder="Search"
              name="search_text"
              value={state.search_text}
              onChange={handleChange}
            />

            <div className="new-message-users-list mt-4">
              <h3>Users List</h3>
              {chatUsersList.map((user, index) => {
                return (
                  <div
                    className="message-user-box p-2 mt-3"
                    onClick={() => {
                      handleAddChat(user._id, onCloseDrawer);
                      const newUrl = new URL(window.location.href);
                      newUrl.searchParams.set("chat_id", user._id);
                      window.history.replaceState(null, "", newUrl.toString());
                    }}
                    key={index}
                  >
                    <div className="d-flex align-items-center pointer">
                      <div className="user-profile">
                        <Avatar
                          src={s3baseUrl + user?.profile_image}
                          className="message-user-avatar"
                          alt="photoURL"
                          style={{ width: 33, height: 33 }}
                        >
                          {string_avatar(user.full_name)}
                        </Avatar>
                        <div
                          className={user.is_online ? "online" : "offline"}
                        ></div>
                      </div>
                      <div className="ms-2">
                        <p className="mb-0"> {user.full_name}</p>
                        <p
                          className="mb-0"
                          style={{ fontSize: 12, color: "#969696" }}
                        >
                          {user?.email}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
              {moreLoad ? (
                <div className="text-center mt-3">
                  <span
                    ref={lastBookElementRef}
                    className="mt-3"
                    onClick={loadMoreData}
                    id="load-more-feed"
                  >
                    {isLoadingMore ? (
                      <CircularProgress color="primary" size="1.5rem" />
                    ) : (
                      "Load More"
                    )}
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
