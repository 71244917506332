import {
  Avatar,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Box,
  Typography,
  Chip,
  FormControl,
  InputLabel,
  Link,
  Button,
  Paper,
  InputBase,
  IconButton,
  Divider,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";
import React, { useEffect, useRef, useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { useSnackbar } from "notistack";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import SyncIcon from "@mui/icons-material/Sync";

import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";

import { s3baseUrl } from "src/config/config";

// import CustomPopovers from "src/components/GeneralComponents/CustomPopovers";
import ToshowExercise from "./components/modalToshowExercise";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import ConfirmationWithDescription from "src/components/ModalPopover/ConfirmationWithDescription";
import Label from "src/components/Label";
import ParameterModel from "./components/ParameterModel";
import { Icon } from "@iconify/react";
import ExerciseHistoryModel from "./components/ExerciseHistoryModel";
import { programme_workout_exercise_history_list_api } from "src/DAL/programmeWorkout/programmeWorkout";
import { get_root_value } from "src/utils/domUtils";
import EMOMExercisesUI from "./EMOMExercisesUI";
import AMRAPExercisesUI from "./AMRAPExercisesUI";
import faker from "faker";
import SomeThingElseExercisesUI from "./SomeThingElseExercisesUI";
import { is_small_medium_screen, paceFromVdot } from "src/utils/constant";
import CustomConfirmationOk from "src/components/ModalPopover/ConfirmationOk";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;
import { makeStyles, useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ModalToShowAnExerciseInfo from "./components/ModalToShowAnExerciseInfo";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import RoundsForTimeExercisesUI from "./RoundsForTimeExercisesUI";
import RepsForTimeExercisesUI from "./RepsForTimeExercisesUI";

const useStyles = makeStyles((theme) => ({
  marginSmMd: {
    [theme.breakpoints.down("md")]: {
      "& > :not(style) + :not(style)": {
        marginTop: "11px", // Margin for small and medium screens
      },
    },
    [theme.breakpoints.up("md")]: {
      "& > :not(style) + :not(style)": {
        marginTop: "11px", // Default margin for large screens
      },
    },
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  loading2: {
    position: "absolute",
    top: "50%",
    right: "50%",
    zIndex: 1000,
  },
  customInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed

      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },

  select: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed
      backgroundColor: "#ebebeb",
      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },
}));
const findPaceObjectByVdot = (vdotValue) => {
  return paceFromVdot.find((paceObject) => paceObject.vdot == vdotValue);
};
const WorkoutExercisesUI = ({
  addArray,
  setaddArray,
  setModalChangeExe,
  isHistory,
  memberId,
  member_vdot,
  isSidebar,
  isWithHistoryAndBigScreen,
}) => {
  const {
    setUserExerciseHistory,
    setUserExerciseHistoryLoading,
    selectedExercise,
    setSelectedExercise,
  } = useContentSetting();
  const { state } = useLocation();
  const myInputRef = useRef(null);
  const theme = useTheme();
  const isSmMd = useMediaQuery(theme.breakpoints.down("md"));

  const [exerInfo, setExsrInfo] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenForSuperset, setModalOpenForSuperset] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [historyModalOpenSuperset, setHistoryModalOpenSuperset] =
    useState(false);
  const vdotwholeObject = findPaceObjectByVdot(member_vdot);
  const [historyModalData, setHistoryModalData] = useState([]);
  const [superAlterIndex, setSuperAlterIndex] = useState("");
  const [alterChanges, setAlterChanges] = useState("");
  const [toAlterChanges, setToAlterChanges] = useState("");
  const [openExerciseAlter, setOpenExerciseAlter] = useState(false);
  const [openSuperSetAlter, setOpenSuperSetAlter] = useState(false);
  const [openPaceAlert, setOpenPaceAlert] = useState(false);
  const [alterIndex, setalterIndex] = useState();
  const [isEditable, setIsEditable] = useState(false);

  const handleClick = () => {
    setIsEditable(true);
  };

  const handleCloseClick = (event) => {
    event.stopPropagation();
    setIsEditable(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const [isReaload, setIsReaload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [detailsData, setDetailsData] = useState("");
  const [exerIndex, setExerIndex] = useState("");
  const [outerIndex, setOuterIndex] = useState("");

  const [openDetails, setOpenDetails] = useState(false);
  const [isWithHistory, setIsWithHistory] = useState(false);
  const [isHovered, setIsHovered] = useState("");
  const [isHoveredExercise, setIsHoveredExercise] = useState("");
  const [isExerciseForSuperSet, setIsExerciseForSuperSet] = useState(false);
  const [openSingleExerciseInfo, setOpenSingleExerciseInfo] = useState(false);
  const [singleExerciseInfo, setSingleExerciseInfo] = useState("");
  const [staticNumber, setStaticNumber] = useState(1);
  const [isAddMultipleSets, setIsAddMultipleSets] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();

  const handleMouseEnter = (setIndex, exerciseIndex) => {
    setIsHovered(setIndex);
    setIsHoveredExercise(exerciseIndex);
  };
  const timeoutRef = useRef(null);

  useEffect(() => {
    // Cleanup function to clear the timeout on component unmount
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);
  const handleMouseLeave = () => {
    // Introduce a delay of 100 milliseconds before updating state on hover out
    const delay = 0;
    timeoutRef.current = setTimeout(() => {
      setIsHovered("");
    }, delay);
  };

  const handleAdd = (i) => {
    let list = addArray;

    list.splice(i + 1, 0, {
      exercise: "",
      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          heightType: "cm",
          height: "",
          distance: "",
          distanceType: "m",
          timeType: "sec",
          restType: "sec",
          reps: "",
          maxReps: "",
          rest: "",
          tempo: "",
          vdot: member_vdot ? member_vdot : null,
          race_type: "c",
          pace_type: "c_km",
          pace: member_vdot
            ? vdotwholeObject.c_km
              ? vdotwholeObject.c_km
              : 0
            : 0,
          paceUp: 423,
          optionsType: [
            { title: "1 KM Pace", key: "c_km" },
            { title: "1 MILE Pace", key: "c_mi" },
          ],
        },
      ],
      parameters: ["Weight", "Reps", "Rest"],
      time_interval: 60,
      rounds: 10,
      type: "general",
      total_rounds: "",
      emomSets: [[]],
      description: "",
      superSet: [],
      superset_sets: "",
    });

    setaddArray([...list]);

    setModalChangeExe(true);
    handleScrollCalled(i + 1);
  };

  const handleAddSet = (mainindex, index) => {
    let list = addArray;
    const data = { ...list[mainindex]["set"][index] };
    list[mainindex]["set"].splice(index + 1, 0, data);
    setaddArray([...list]);
    setModalChangeExe(true);
    handleSetScrollCalled(mainindex, index + 1);
  };

  const handleAddMultipleSets = (mainindex, data) => {
    const numberOfSets = parseInt(staticNumber, 10);
    if (isNaN(numberOfSets) || numberOfSets <= 0) {
      enqueueSnackbar("Please enter a valid number greater than 0.", {
        variant: "error",
      });
      return;
    }

    if (numberOfSets > 10) {
      enqueueSnackbar("Please enter a valid number less than 10.", {
        variant: "error",
      });
      return;
    }

    let list = [...addArray];

    const lastSet =
      list[mainindex]["set"].length > 0
        ? list[mainindex]["set"][list[mainindex]["set"].length - 1]
        : {};

    const newSets = {
      mhr: lastSet.mhr || "",
      time: lastSet.time || "",
      weight: lastSet.weight || "",
      weightType: lastSet.weightType || "kg",
      heightType: lastSet.heightType || "cm",
      height: lastSet.height || "",
      distance: lastSet.distance || "",
      distanceType: lastSet.distanceType || "m",
      timeType: lastSet.timeType || "sec",
      restType: lastSet.restType || "sec",
      reps: lastSet.reps || "",
      maxReps: lastSet.maxReps || "",
      rest: lastSet.rest || "",
      tempo: lastSet.tempo || "",
      vdot: lastSet.vdot || (member_vdot ? member_vdot : null),
      race_type: lastSet.race_type || "c",
      pace_type: lastSet.pace_type || "c_km",
      pace:
        lastSet.pace ||
        (member_vdot ? (vdotwholeObject.c_km ? vdotwholeObject.c_km : 0) : 0),
      paceUp: lastSet.paceUp || 423,
      optionsType: lastSet.optionsType || [
        { title: "1 KM Pace", key: "c_km" },
        { title: "1 MILE Pace", key: "c_mi" },
      ],
    };
    for (let i = 0; i < numberOfSets; i++) {
      list[mainindex]["set"].push({ ...newSets });
    }
    setaddArray(list);
    setModalChangeExe(true);
    setIsEditable(false);
    setStaticNumber(1);
  };

  const handleDeleteSet = (mainindex, index) => {
    let list = addArray;

    list[mainindex]["set"].splice(index, 1);

    setaddArray([...list]);
    setModalChangeExe(true);
  };

  const handleDeleteExer = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);

    setaddArray(delValue);
    setModalChangeExe(true);
  };

  const removeSuperSet = (mainIndex, index) => {
    //  const { name, value } = e.target;
    const list = [...addArray];
    let firstPart = list[mainIndex]["superSet"].slice(0, index + 1);
    let secondPart = list[mainIndex]["superSet"].slice(index + 1);
    console.log(firstPart, secondPart, "dlfkjksdhfjas");
    let firstObject = { ...firstPart[0] };
    if (index > 0) {
      firstObject.superSet = firstPart;
      firstObject.superset_sets = list[mainIndex].superset_sets;
      console.log(firstObject, "fdjhsjkdfjkajds1", list[mainIndex]);
      // superset_sets;
    }

    let secondObject = { ...secondPart[0] };
    if (secondPart.length > 1) {
      secondObject.superSet = secondPart;
      secondObject.superset_sets = list[mainIndex].superset_sets;
      console.log(firstObject, "fdjhsjkdfjkajds2", list[mainIndex]);
    }

    let list1 = addArray;
    list1.splice(mainIndex + 1, 0, secondObject);
    list1.splice(mainIndex + 1, 0, firstObject);

    setaddArray([...list1]);
    setModalChangeExe(true);
    handleDeleteExer(mainIndex);
  };

  const handleAgreeSuperSet = (mainindex) => {
    setSuperAlterIndex(mainindex);

    setAlterChanges(
      addArray[mainindex + 1].superSet.length
        ? addArray[mainindex + 1].superset_sets
        : addArray[mainindex + 1].set.length
    );

    setToAlterChanges(
      addArray[mainindex].superSet.length
        ? addArray[mainindex].superset_sets
        : addArray[mainindex].set.length
    );

    const first = addArray[mainindex].superSet.length
      ? addArray[mainindex].superset_sets
      : addArray[mainindex].set.length;
    const second = addArray[mainindex + 1].superSet.length
      ? addArray[mainindex + 1].superset_sets
      : addArray[mainindex + 1].set.length;

    if (first == second) {
      addInSuperSet(mainindex);
      console.log(alterChanges, toAlterChanges, "dfjkhakjsdhfa");
    } else setOpenSuperSetAlter(true);
  };
  const handleAgreeExercise = (mainindex) => {
    setalterIndex(mainindex);

    setAlterChanges(
      addArray[mainindex + 1].superSet.length
        ? addArray[mainindex + 1].superset_sets
        : addArray[mainindex + 1].set.length
    );

    setToAlterChanges(
      addArray[mainindex].superSet.length
        ? addArray[mainindex].superSet.length
        : addArray[mainindex].set.length
    );

    const first = addArray[mainindex].superSet.length
      ? addArray[mainindex].superSet.length
      : addArray[mainindex].set.length;
    const second = addArray[mainindex + 1].superSet.length
      ? addArray[mainindex + 1].superset_sets
      : addArray[mainindex + 1].set.length;

    if (first == second) {
      addSuperSet(mainindex);
    } else {
      setOpenExerciseAlter(true);
    }
  };
  const addAgreeSuperSet = (index) => {
    //  const { name, value } = e.target;

    let current = { ...addArray[alterIndex] };
    current.superSet = [];
    let array = [current];

    if (
      addArray[alterIndex + 1]?.superSet &&
      addArray[alterIndex + 1].superSet.length > 0
    ) {
      addArray[alterIndex + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[alterIndex + 1]);
    }

    const list = [...addArray];
    list[alterIndex]["superSet"] = array;

    setaddArray(list);
    setModalChangeExe(true);

    let e = {
      target: {
        name: "superset_sets",
        value: addArray[alterIndex].set.length,
      },
    };
    handleChangeSuperSetCount(e, alterIndex);
    handleDelete(alterIndex + 1);
    setOpenExerciseAlter(false);
  };
  const addSuperSet = (index) => {
    //  const { name, value } = e.target;

    console.log(index, "dsjkhdkfgs", alterIndex);
    let current = { ...addArray[index] };
    current.superSet = [];
    let array = [current];

    if (
      addArray[index + 1]?.superSet &&
      addArray[index + 1].superSet.length > 0
    ) {
      addArray[index + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[index + 1]);
    }

    const list = [...addArray];
    list[index]["superSet"] = array;

    setaddArray(list);
    setModalChangeExe(true);

    let e = {
      target: {
        name: "superset_sets",
        value: addArray[index].set.length,
      },
    };
    handleChangeSuperSetCount(e, index);
    handleDelete(index + 1);
    setOpenExerciseAlter(false);
  };
  const addInSuperSet = (index) => {
    let array = addArray[index].superSet;
    if (addArray[index + 1].superSet.length > 0) {
      addArray[index + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[index + 1]);
    }
    const list = [...addArray];
    list[index]["superSet"] = array;

    setaddArray(list);
    setModalChangeExe(true);
    let e = {
      target: {
        name: "superset_sets",
        value: list[index].superset_sets,
      },
    };
    handleChangeSuperSetCount(e, index);
    handleDelete(index + 1);
    setOpenSuperSetAlter(false);
  };
  const addAgreeInSuperSet = () => {
    let array = addArray[superAlterIndex].superSet;
    if (addArray[superAlterIndex + 1].superSet.length > 0) {
      addArray[superAlterIndex + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[superAlterIndex + 1]);
    }
    const list = [...addArray];
    list[superAlterIndex]["superSet"] = array;

    setaddArray(list);
    setModalChangeExe(true);
    let e = {
      target: {
        name: "superset_sets",
        value: list[superAlterIndex].superset_sets,
      },
    };
    handleChangeSuperSetCount(e, superAlterIndex);
    handleDelete(superAlterIndex + 1);
    setOpenSuperSetAlter(false);
  };
  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);

    setaddArray(delValue);
    setModalChangeExe(true);
  };

  const handleArrow = (value, index) => {
    //  const { name, value } = e.target;

    const list = [...addArray];
    list[index]["open"] = value;

    setaddArray(list);
  };

  const handleArrowSuperSet = (value, mainindex, exerIndex) => {
    //  const { name, value } = e.target;

    const list = [...addArray];
    list[mainindex]["superSet"][exerIndex]["open"] = value;

    setaddArray(list);
  };

  const handleChange = (e, index, index1) => {
    const { name, value } = e.target;

    const list = [...addArray];

    list[index]["set"][index1][name] = value;

    setaddArray(list);
    setModalChangeExe(true);
  };

  const getVdotPace = (index, index1) => {
    const list = [...addArray];
    if (!list[index]["set"][index1]["vdot"]) {
      return;
    }
    const vodt = list[index]["set"][index1]["vdot"];
    const distanceType = list[index]["set"][index1]["race_type"];
    const paceType = list[index]["set"][index1]["pace_type"];

    // Example: Find object with vdot value of 33
    const vdot33Object = findPaceObjectByVdot(vodt);
    if (distanceType != "el") {
      list[index]["set"][index1]["pace"] = vdot33Object[paceType];
      if (!vdot33Object[paceType]) {
        memberId && setOpenPaceAlert(true);
      }
    } else {
      list[index]["set"][index1]["pace"] = vdot33Object[paceType + "_low"];
      list[index]["set"][index1]["paceUp"] = vdot33Object[paceType + "_up"];
    }

    setaddArray(list);
    // setModalChangeExe(true);
  };
  const handleChangeVdotPaceType = (e, index, index1) => {
    const { name, value } = e.target;

    const list = [...addArray];

    list[index]["set"][index1][name] = value;
    setaddArray(list);
    setModalChangeExe(true);
    getVdotPace(index, index1);
  };
  const handleChangeVdot = (e, index, index1) => {
    const { name, value } = e.target;

    const list = [...addArray];

    list[index]["set"][index1][name] = Math.min(Math.max(value, 30), 85);

    setaddArray(list);
    setModalChangeExe(true);
    getVdotPace(index, index1);
  };
  const handleChangeAddVdot = (e, index, index1) => {
    console.log("kfjjkdshfjkasjd");
    const previousValue = parseInt(addArray[index]["set"][index1]["vdot"]);
    const value = previousValue + 1;
    const list = [...addArray];

    list[index]["set"][index1]["vdot"] = Math.min(Math.max(value, 30), 85);

    setaddArray(list);
    setModalChangeExe(true);
    getVdotPace(index, index1);
  };
  const handleChangeSubtractVdot = (e, index, index1) => {
    const value = parseInt(addArray[index]["set"][index1]["vdot"]) - 1;
    const list = [...addArray];

    list[index]["set"][index1]["vdot"] = Math.min(Math.max(value, 30), 85);

    setaddArray(list);
    setModalChangeExe(true);
    getVdotPace(index, index1);
  };
  const handleChangeVdotDistance = (e, index, index1) => {
    const { name, value } = e.target;
    const list = [...addArray];
    list[index].set[index1][name] = value;

    let optionsTypeSArray = [];

    switch (value) {
      case "r":
        optionsTypeSArray = [
          { title: "200 Meters Pace", key: "r_200_m" },
          { title: "300 Meters Pace", key: "r_300_m" },
          { title: "400 Meters Pace", key: "r_400_m" },
          { title: "600 Meters Pace", key: "r_600_m" },
          { title: "800 Meters Pace", key: "r_800_m" },
        ];
        break;

      case "c":
        optionsTypeSArray = [
          { title: "1 KM Pace", key: "c_km" },
          { title: "1 MILE Pace", key: "c_mi" },
        ];
        break;

      case "t":
        optionsTypeSArray = [
          { title: "400 Meters Pace", key: "t_400_m" },
          { title: "1 KM Pace", key: "t_km" },
          { title: "1 MILE Pace", key: "t_mi" },
        ];
        break;

      case "i":
        optionsTypeSArray = [
          { title: "400 Meters Pace", key: "i_400_m" },
          { title: "1 KM Pace", key: "i_km" },
          { title: "1200 Meters Pace", key: "i_1200_m" },
          { title: "1 MILE Pace", key: "i_mi" },
        ];
        break;

      default:
        optionsTypeSArray = [
          { title: "1 KM Pace", key: "e_km" },
          { title: "1 MILE Pace", key: "e_mi" },
        ];
    }
    list[index].set[index1]["optionsType"] = optionsTypeSArray;
    list[index].set[index1]["pace_type"] = optionsTypeSArray[0].key;

    setaddArray(list);
    setModalChangeExe(true);

    if (list[index].parameters.includes("Vdot")) getVdotPace(index, index1);
  };

  const handleChangeTempo = (e, index, index1) => {
    const { name, value } = e.target;
    if (value.length <= 4) {
      const list = [...addArray];

      list[index]["set"][index1][name] = value;

      setaddArray(list);
      setModalChangeExe(true);
    }
  };
  const handleChangeSuperSetDes = (e, index, index1) => {
    const { name, value } = e.target;
    const list = [...addArray];
    list[index]["superSet"][index1][name] = value;

    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleChangeDes = (e, index) => {
    const { name, value } = e.target;

    const list = [...addArray];
    list[index][name] = value;

    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleChangeSuperSet = (e, mainindex, exerIndex, setIndex) => {
    const { name, value } = e.target;

    const list = [...addArray];

    list[mainindex]["superSet"][exerIndex]["set"][setIndex][name] = value;

    setaddArray(list);
    setModalChangeExe(true);
  };
  const handleChangeSuperSetTempo = (e, mainindex, exerIndex, setIndex) => {
    const { name, value } = e.target;
    if (value.length <= 4) {
      const list = [...addArray];
      list[mainindex]["superSet"][exerIndex]["set"][setIndex][name] = value;
      setaddArray(list);
      setModalChangeExe(true);
    }
  };
  const handleAddSetCount = (index) => {
    const list = [...addArray];
    const name = "superset_sets";
    const value = parseInt(list[index].superset_sets) + 1;
    // console.log(list[index].superset_sets, "ksdfjhkjsdnfkjs");
    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));

    list[index][name] = targetValue;

    // Iterate over superSet array and update set array
    list[index]?.superSet.forEach((superSetItem) => {
      const currentSetLength = superSetItem.set.length;

      const data = { ...superSetItem.set[currentSetLength - 1] };
      if (currentSetLength < targetValue) {
        const objectsToAdd = targetValue - currentSetLength;

        for (let i = 0; i < objectsToAdd; i++) {
          superSetItem.set.push(data);
        }
      } else if (currentSetLength > targetValue) {
        // If the new count is less than the current count, remove excess objects
        superSetItem.set.splice(targetValue);
      }
    });

    setaddArray(list);
    setModalChangeExe(true);
  };
  const handleRearageSetCount = (index, count) => {
    const list = [...addArray];
    const name = "superset_sets";
    const value = count;
    // console.log(list[index].superset_sets, "ksdfjhkjsdnfkjs");
    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));

    list[index][name] = targetValue;

    // Iterate over superSet array and update set array
    list[index]?.superSet.forEach((superSetItem) => {
      const currentSetLength = superSetItem.set.length;

      if (currentSetLength < targetValue) {
        const objectsToAdd = targetValue - currentSetLength;

        for (let i = 0; i < objectsToAdd; i++) {
          superSetItem.set.push({
            time: "",
            weight: "",
            weightType: "kg",
            heightType: "cm",
            height: "",
            distance: "",
            distanceType: "m",
            timeType: "sec",
            restType: "sec",
            reps: "",
            maxReps: "",
            rest: "",
            race_type: "c",
            pace: 0,
          });
        }
      } else if (currentSetLength > targetValue) {
        // If the new count is less than the current count, remove excess objects
        superSetItem.set.splice(targetValue);
      }
    });

    setaddArray(list);
    setModalChangeExe(true);
    setHistoryModalOpenSuperset(false);
  };
  const handleMinusSetCount = (index) => {
    const list = [...addArray];
    const name = "superset_sets";
    const value = parseInt(list[index].superset_sets) - 1;
    // console.log(list[index].superset_sets, "ksdfjhkjsdnfkjs");
    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));

    list[index][name] = targetValue;

    // Iterate over superSet array and update set array
    list[index]?.superSet.forEach((superSetItem) => {
      const currentSetLength = superSetItem.set.length;

      if (currentSetLength < targetValue) {
        const objectsToAdd = targetValue - currentSetLength;

        for (let i = 0; i < objectsToAdd; i++) {
          superSetItem.set.push({
            time: "",
            weight: "",
            weightType: "kg",
            heightType: "cm",
            height: "",
            distance: "",
            distanceType: "m",
            timeType: "sec",
            restType: "sec",
            reps: "",
            maxReps: "",
            rest: "",
            race_type: "c",
            pace: 0,
          });
        }
      } else if (currentSetLength > targetValue) {
        // If the new count is less than the current count, remove excess objects
        superSetItem.set.splice(targetValue);
      }
    });

    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleChangeSuperSetCount = (e, index) => {
    const { name, value } = e.target;
    const list = [...addArray];

    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));

    list[index][name] = targetValue;

    // Iterate over superSet array and update set array
    list[index]?.superSet.forEach((superSetItem) => {
      const currentSetLength = superSetItem.set.length;

      if (currentSetLength < targetValue) {
        const objectsToAdd = targetValue - currentSetLength;

        for (let i = 0; i < objectsToAdd; i++) {
          superSetItem.set.push({
            time: "",
            weight: "",
            weightType: "kg",
            heightType: "cm",
            height: "",
            distance: "",
            distanceType: "m",
            reps: "",
            maxReps: "",
            rest: "",
            race_type: "c",
            pace: 0,
          });
        }
      } else if (currentSetLength > targetValue) {
        // If the new count is less than the current count, remove excess objects
        superSetItem.set.splice(targetValue);
      }
    });

    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleChangeUp = (value) => {
    if (value == 0) {
      enqueueSnackbar("You are already on Top !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value - 1, 0, reorderedItem);

    setaddArray(items);
    setModalChangeExe(true);
  };

  const handleExerciseList = (value, isWithHistory) => {
    setIsExerciseForSuperSet(false);
    setDetailsData(value);
    setOpenDetails(true);
    setIsWithHistory(isWithHistory);
  };
  const handleSupersetExerciseList = (mainIndex, exerInde, history) => {
    setDetailsData(mainIndex);
    setExerIndex(exerInde);
    setIsExerciseForSuperSet(true);
    setIsWithHistory(history);
  };

  const handleSelectExercise = (values) => {
    const list = [...addArray];
    if (Array.isArray(values)) {
      const updatedList = list.slice(0, detailsData);
      values.forEach((exercise) => {
        const newExercise = {
          exercise: exercise,
          description: exercise?.instructions,
          open: true,
          emomSets: [[]],
          parameters: ["Weight", "Reps", "Rest"],
          rounds: 10,
          set: [
            {
              time: "",
              weight: "",
              weightType: "kg",
              heightType: "cm",
              height: "",
              distance: "",
              distanceType: "m",
              timeType: "sec",
              restType: "sec",
              reps: "",
              maxReps: "",
              rest: "",
              tempo: "",
              vdot: member_vdot ? member_vdot : null,
              race_type: "c",
              pace_type: "c_km",
              pace: member_vdot
                ? vdotwholeObject.c_km
                  ? vdotwholeObject.c_km
                  : 0
                : 0,
              paceUp: 423,
              optionsType: [
                {
                  title: "1 KM Pace",
                  key: "c_km",
                },
                {
                  title: "1 MILE Pace",
                  key: "c_mi",
                },
              ],
            },
          ],
          superSet: [],
          superset_sets: "",
          time_interval: 60,
          total_rounds: "",
          type: "general",
        };
        updatedList.push(newExercise);
      });

      const remainingItems = list.slice(detailsData);

      const filteredArray = remainingItems.filter(
        (item) =>
          !(item.type === "general" && Object.keys(item.exercise).length === 0)
      );
      const finalList = [...updatedList, ...filteredArray];
      setaddArray(finalList);
    }
    setModalChangeExe(false);
    if (isWithHistoryAndBigScreen)
      values.forEach((value, index) => {
        const targetIndex = detailsData + index;
        handleHistoryOFAnExercise(value, targetIndex);
      });
  };

  const handleSelectExerciseSingle = (value) => {
    const list = [...addArray];
    if (list[detailsData]["exercise"]._id == value._id) {
      return;
    }
    list[detailsData]["exercise"] = value;
    list[detailsData]["description"] = value?.instructions;
    list[detailsData]["open"] = true;
    setaddArray(list);
    setModalChangeExe(false);
    if (isWithHistoryAndBigScreen)
      handleHistoryOFAnExercise(value, detailsData);
  };

  const handleSelectExerciseType = (e, index) => {
    const list = [...addArray];

    list[index]["type"] = e.target.value;
    list[index]["time_interval"] = e.target.value == "emom" ? 60 : 600;
    list[index]["description"] = "";

    setaddArray(list);
    setModalChangeExe(false);
    // if (isHistory) handleHistory(value, detailsData);
  };
  const handleCopyExercise = (value) => {
    const list = [...addArray];
    list[detailsData]["set"] = value.set;
    list[detailsData]["parameters"] = value?.parameters;
    setaddArray(list);
    setModalChangeExe(true);
    setHistoryModalOpen(false);
  };
  const handleCopySupersetExercise = (value) => {
    const list = [...addArray];
    console.log(list[detailsData], "dskjfjkdshafkja");
    list[detailsData]["superSet"][exerIndex]["set"] = value.set;
    list[detailsData]["superSet"][exerIndex]["parameters"] = value?.parameters;
    setaddArray(list);
    setModalChangeExe(true);

    handleRearageSetCount(detailsData, list[detailsData].superset_sets);
  };

  const handleSelectSupersetExercise = (values) => {
    const updatedList = values.map((value, index) => {
      const targetSuperset = addArray[detailsData]?.superSet[exerIndex + index];
      if (targetSuperset) {
        if (targetSuperset["exercise"]?._id !== value._id) {
          return {
            ...targetSuperset,
            exercise: value,
            description: value?.instructions || "",
            open: true,
          };
        }
        return targetSuperset;
      }

      return {
        exercise: value,
        description: value?.instructions || "",
        open: true,
        emomSets: [[]],
        parameters: ["Weight", "Reps", "Rest"],
        rounds: 10,
        set: [
          {
            time: "",
            weight: "",
            weightType: "kg",
            heightType: "cm",
            height: "",
            distance: "",
            distanceType: "m",
            timeType: "sec",
            restType: "sec",
            reps: "",
            maxReps: "",
            rest: "",
            tempo: "",
            vdot: member_vdot ? member_vdot : null,
            race_type: "c",
            pace_type: "c_km",
            pace: member_vdot
              ? vdotwholeObject.c_km
                ? vdotwholeObject.c_km
                : 0
              : 0,
            paceUp: 423,
            optionsType: [
              {
                title: "1 KM Pace",
                key: "c_km",
              },
              {
                title: "1 MILE Pace",
                key: "c_mi",
              },
            ],
          },
        ],
        superSet: [],
        superset_sets: "",
        time_interval: 60,
        total_rounds: "",
        type: "general",
      };
    });

    // Update the superSet within the addArray
    const newArray = [...addArray];
    const currentSuperSet = [...(newArray[detailsData]?.superSet || [])];
    updatedList.forEach((item, index) => {
      currentSuperSet[exerIndex + index] = item;
    });

    // Assign the updated superSet back to the main list
    newArray[detailsData] = {
      ...newArray[detailsData],
      superSet: currentSuperSet,
    };

    setaddArray(newArray);
    setModalChangeExe(false);

    if (isWithHistoryAndBigScreen) {
      values.forEach((value, index) => {
        handleHistoryOFAnExerciseSuperset(
          value,
          detailsData,
          exerIndex + index
        );
      });
    }
  };

  const handleSelectSupersetExerciseSingle = (value) => {
    const list = [...addArray];
    if (list[detailsData]["superSet"][exerIndex]["exercise"]._id == value._id) {
      return;
    }
    list[detailsData]["superSet"][exerIndex]["exercise"] = value;
    list[detailsData]["superSet"][exerIndex]["description"] =
      value?.instructions;
    list[detailsData]["superSet"][exerIndex]["open"] = true;

    console.log(list, "listlistlist");

    setaddArray(list);
    setModalChangeExe(true);
    if (isWithHistoryAndBigScreen)
      handleHistoryOFAnExerciseSuperset(value, detailsData, exerIndex);
  };

  const handleChangeDown = (value) => {
    if (value >= addArray.length - 1) {
      enqueueSnackbar("You are already on Bottom !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value + 1, 0, reorderedItem);

    setaddArray(items);
    setModalChangeExe(true);
  };

  const handleParameters = (value) => {
    setModalOpen(true);
    setModalOpenForSuperset(false);
    setOuterIndex(value);
    setExsrInfo(addArray[value].parameters);
  };
  const handleHistoryOpen = async (value, index) => {
    setDetailsData(index);
    handleHistory(value?.exercise, index);

    // setHistoryModalData(value.history);
  };
  const handleHistoryPreviousOpen = async (value) => {
    setSelectedExercise(value?.exercise?._id);
    setUserExerciseHistoryLoading(true);
    setUserExerciseHistory(value?.history);
    setUserExerciseHistoryLoading(false);
  };
  const handleClickOpen = async (exercise) => {
    setSingleExerciseInfo(exercise);
    setOpenSingleExerciseInfo(true);
  };
  const handleHistory = async (value, index) => {
    setDetailsData(index);
    let postData = {
      exercise: value?._id,
      member: memberId,
    };
    setHistoryModalData([]);
    setIsLoading(true);
    try {
      let result = await programme_workout_exercise_history_list_api(postData);
      if (result.code == 200) {
        if (!result.exercise_history.length > 0) {
          const list = [...addArray];
          delete list[index]["history"];
          setaddArray(list);

          return;
        }

        let givenExercise = result.exercise_history.map((item) => {
          return {
            ...item,
            date: item?.date,
            exercise: item?.exercise_info,

            set: item?.sets.map((item1) => {
              return {
                time: item1?.duration,
                mhr: item1?.mhr,
                weight: item1?.weight,
                weightType: item1?.weight_unit,
                height: item1?.height,
                heightType: item1?.height_unit,
                reps: item1?.reps,
                maxReps: item1?.maxReps,
                rest: item1?.rest,
                distance: item1?.distance,
                distanceType: item1?.distance_unit,
                timeType: item1?.duration_unit,
                restType: item1?.rest_unit,
                tempo: item1?.tempo,
                vdot: item1?.vdot ? item1?.vdot : null,
                race_type: item1?.race_type ? item1?.race_type : "c",
                pace_type: item1?.pace_type ? item1?.pace_type : "c_km",
                pace: item1?.pace_low ? item1?.pace_low : 0,
                paceUp: item1?.pace_up ? item1?.pace_up : 423,
                optionsType:
                  item1?.pace_options && item1?.pace_options.length > 0
                    ? item1?.pace_options
                    : [
                        { title: "1 KM Pace", key: "c_km" },
                        { title: "1 MILE Pace", key: "c_mi" },
                      ],
              };
            }),
            parameters: item?.parameters,
            open: true,
          };
        });

        setHistoryModalData(givenExercise);
        // setHistoryModalOpen(true);
        const list = [...addArray];
        list[index]["history"] = givenExercise;
        setaddArray(list);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
      setHistoryModalOpen(true);
    }
  };
  const handleHistorySupersetOpen = async (value, mainIndex, exerInde) => {
    setDetailsData(mainIndex);
    setExerIndex(exerInde);
    // setHistoryModalData(value.history);
    handleHistorySuperset(value.exercise, mainIndex, exerInde);
  };
  const handleHistoryOFAnExercise = async (value, index) => {
    let postData = {
      exercise: value?._id,
      member: memberId,
    };
    try {
      setUserExerciseHistoryLoading(true);
      let result = await programme_workout_exercise_history_list_api(postData);

      if (result.code == 200) {
        setSelectedExercise(value?._id);
        let givenExercise = result.exercise_history.map((item) => {
          if (!result.exercise_history.length > 0) {
            // const list = [...addArray];
            // delete list[index]["history"];
            setaddArray((prevArray) => {
              const updatedArray = [...prevArray];
              updatedArray[index] = {
                ...updatedArray[index],
                history: givenExercise,
              };
              return updatedArray;
            });

            return;
          }
          return {
            ...item,
            date: item?.date,
            exercise: item?.exercise_info,
            set: item?.sets.map((item1) => {
              return {
                time: item1?.duration,
                mhr: item1?.mhr,
                weight: item1?.weight,
                weightType: item1?.weight_unit,
                height: item1?.height,
                heightType: item1?.height_unit,
                reps: item1?.reps,
                maxReps: item1?.maxReps,
                rest: item1?.rest,
                distance: item1?.distance,
                distanceType: item1?.distance_unit,
                timeType: item1?.duration_unit,
                restType: item1?.rest_unit,
                tempo: item1?.tempo,
                vdot: item1?.vdot ? item1?.vdot : null,
                race_type: item1?.race_type ? item1?.race_type : "c",
                pace_type: item1?.pace_type ? item1?.pace_type : "c_km",
                pace: item1?.pace_low ? item1?.pace_low : 0,
                paceUp: item1?.pace_up ? item1?.pace_up : 423,
                optionsType:
                  item1?.pace_options && item1?.pace_options.length > 0
                    ? item1?.pace_options
                    : [
                        { title: "1 KM Pace", key: "c_km" },
                        { title: "1 MILE Pace", key: "c_mi" },
                      ],
              };
            }),
            parameters: item?.parameters,
            open: true,
          };
        });
        setUserExerciseHistory(givenExercise);
        // const list = [...addArray];
        // list[index]["history"] = givenExercise;
        // setaddArray(list);
        setaddArray((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[index] = {
            ...updatedArray[index],
            history: givenExercise,
          };
          return updatedArray;
        });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
    } finally {
      setUserExerciseHistoryLoading(false);
    }
  };
  const handleHistoryOFAnExerciseSuperset = async (
    value,
    mainIndex,
    exerInde
  ) => {
    let postData = {
      exercise: value?._id,
      member: memberId,
    };
    try {
      setUserExerciseHistoryLoading(true);
      let result = await programme_workout_exercise_history_list_api(postData);
      if (result.code == 200) {
        setSelectedExercise(value?._id);
        let givenExercise = result.exercise_history.map((item) => {
          if (!result.exercise_history.length > 0) {
            // const list = [...addArray];
            // delete list[mainIndex]["superSet"][exerInde]["history"];
            setaddArray((prevArray) => {
              const updatedArray = [...prevArray];
              updatedArray[mainIndex]["superSet"][exerInde]["history"] = [];
              return updatedArray;
            });

            return;
          }

          return {
            ...item,
            date: item?.date,
            exercise: item?.exercise_info,
            set: item?.sets.map((item1) => {
              return {
                time: item1?.duration,
                mhr: item1?.mhr,
                weight: item1?.weight,
                weightType: item1?.weight_unit,
                height: item1?.height,
                heightType: item1?.height_unit,
                reps: item1?.reps,
                maxReps: item1?.maxReps,
                rest: item1?.rest,
                distance: item1?.distance,
                distanceType: item1?.distance_unit,
                timeType: item1?.duration_unit,
                restType: item1?.rest_unit,
                tempo: item1?.tempo,
                vdot: item1?.vdot ? item1?.vdot : null,
                race_type: item1?.race_type ? item1?.race_type : "c",
                pace_type: item1?.pace_type ? item1?.pace_type : "c_km",
                pace: item1?.pace_low ? item1?.pace_low : 0,
                paceUp: item1?.pace_up ? item1?.pace_up : 423,
                optionsType:
                  item1?.pace_options && item1?.pace_options.length > 0
                    ? item1?.pace_options
                    : [
                        { title: "1 KM Pace", key: "c_km" },
                        { title: "1 MILE Pace", key: "c_mi" },
                      ],
              };
            }),
            parameters: item?.parameters,
            open: true,
          };
        });
        setUserExerciseHistory(givenExercise);
        // const list = [...addArray];
        // list[mainIndex]["superSet"][exerInde]["history"] = givenExercise;
        setaddArray((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[mainIndex]["superSet"][exerInde]["history"] =
            givenExercise;
          return updatedArray;
        });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
    } finally {
      setUserExerciseHistoryLoading(false);
    }
  };
  const handleHistorySuperset = async (value, mainIndex, exerInde) => {
    setDetailsData(mainIndex);
    setExerIndex(exerInde);
    let postData = {
      exercise: value?._id,
      member: memberId,
    };
    setHistoryModalData([]);
    setIsLoading(true);
    try {
      let result = await programme_workout_exercise_history_list_api(postData);
      if (result.code == 200) {
        if (!result.exercise_history.length > 0) {
          const list = [...addArray];
          delete list[mainIndex]["superSet"][exerInde]["history"];
          setaddArray(list);

          return;
        }
        let givenExercise = result.exercise_history.map((item) => {
          return {
            ...item,
            date: item?.date,
            exercise: item?.exercise_info,
            set: item?.sets.map((item1) => {
              return {
                time: item1?.duration,
                mhr: item1?.mhr,
                weight: item1?.weight,
                weightType: item1?.weight_unit,
                height: item1?.height,
                heightType: item1?.height_unit,
                reps: item1?.reps,
                maxReps: item1?.maxReps,
                rest: item1?.rest,
                distance: item1?.distance,
                distanceType: item1?.distance_unit,
                timeType: item1?.duration_unit,
                restType: item1?.rest_unit,
                tempo: item1?.tempo,
                vdot: item1?.vdot ? item1?.vdot : null,
                race_type: item1?.race_type ? item1?.race_type : "c",
                pace_type: item1?.pace_type ? item1?.pace_type : "c_km",
                pace: item1?.pace_low ? item1?.pace_low : 0,
                paceUp: item1?.pace_up ? item1?.pace_up : 423,
                optionsType:
                  item1?.pace_options && item1?.pace_options.length > 0
                    ? item1?.pace_options
                    : [
                        { title: "1 KM Pace", key: "c_km" },
                        { title: "1 MILE Pace", key: "c_mi" },
                      ],
              };
            }),
            parameters: item?.parameters,
            open: true,
          };
        });
        console.log(givenExercise, "kjdshakfjsdfhaj");
        setHistoryModalData(givenExercise);
        // setIsLoading(false);
        // setHistoryModalOpenSuperset(true);
        const list = [...addArray];
        list[mainIndex]["superSet"][exerInde]["history"] = givenExercise;
        setaddArray(list);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
      setHistoryModalOpenSuperset(true);
    }
  };
  const handleParametersSuperset = (main, inr) => {
    setModalOpen(true);
    setModalOpenForSuperset(true);
    setOuterIndex(main);
    setExerIndex(inr);
    setExsrInfo(addArray[main].superSet[inr].parameters);
  };
  const handleChangeParametersInsetFieldValueSuperSet = (
    value,
    outerIndex,
    exerIndex
  ) => {
    const items = Array.from(addArray);

    const sets = items[outerIndex].superSet[exerIndex].set.map((item) => {
      return {
        ...item,
        time: items[outerIndex].superSet[exerIndex].parameters.includes(
          "Duration/Pace"
        )
          ? item?.time
          : "",
        weight: items[outerIndex].superSet[exerIndex].parameters.includes(
          "Weight"
        )
          ? item?.weight
          : "",
        weightType: item?.weightType,
        reps: items[outerIndex].superSet[exerIndex].parameters.includes("Reps")
          ? item?.reps
          : "",
        maxReps: items[outerIndex].superSet[exerIndex].parameters.includes(
          "Max_Reps"
        )
          ? item?.maxReps
          : "",
        height: items[outerIndex].superSet[exerIndex].parameters.includes(
          "Height"
        )
          ? item?.height
          : "",
        heightType: item?.heightType,
        rest: items[outerIndex].superSet[exerIndex].parameters.includes("Rest")
          ? item?.rest
          : "",
        distance:
          items[outerIndex].superSet[exerIndex].parameters.includes(
            "Distance/Calories"
          ) || items[outerIndex].superSet[exerIndex].parameters.includes("Pace")
            ? item?.distance
            : "",
        distanceType: item?.distanceType,
        timeType: item?.timeType,
        restType: item?.restType,
        tempo: items[outerIndex].superSet[exerIndex].parameters.includes(
          "Tempo"
        )
          ? item?.tempo
          : "",
      };
    });
    // setExsrInfo(items[outerIndex].superSet[exerIndex].parameters);
    items[outerIndex].superSet[exerIndex].set = sets;
    setaddArray(items);
  };
  const handleChangeParametersInsetFieldValue = (value, outerIndex) => {
    const items = Array.from(addArray);
    const sets = items[outerIndex].set.map((item) => {
      return {
        ...item,

        time: items[outerIndex].parameters.includes("Duration/Pace")
          ? item?.time
          : "",
        time: items[outerIndex].parameters.includes("Time") ? item?.time : "",
        weight: items[outerIndex].parameters.includes("Weight")
          ? item?.weight
          : "",
        weightType: item?.weightType,
        reps: items[outerIndex].parameters.includes("Reps") ? item?.reps : "",
        maxReps: items[outerIndex].parameters.includes("Max_Reps")
          ? item?.maxReps
          : "",
        height: items[outerIndex].parameters.includes("Height")
          ? item?.height
          : "",
        heightType: item?.heightType,
        rest: items[outerIndex].parameters.includes("Rest") ? item?.rest : "",
        distance:
          items[outerIndex].parameters.includes("Distance/Calories") ||
          items[outerIndex].parameters.includes("Pace")
            ? item?.distance
            : "",
        distance: items[outerIndex].parameters.includes("Distance")
          ? item?.distance
          : "",
        distanceType: item?.distanceType,
        timeType: item?.timeType,
        restType: item?.restType,
        tempo: items[outerIndex].parameters.includes("Tempo")
          ? item?.tempo
          : "",
      };
    });
    console.log(items[outerIndex], "sdkfjklsdjfjlkakj");
    // setExsrInfo(items[outerIndex].parameters);
    items[outerIndex].set = sets;
    setaddArray(items);
  };
  const handleChangeParametersInset = (value) => {
    const items = Array.from(addArray);
    if (modalOpenForSuperset) {
      items[outerIndex].superSet[exerIndex].parameters = value;
      setExsrInfo(items[outerIndex].superSet[exerIndex].parameters);
      setaddArray(items);
      setModalChangeExe(true);
      handleChangeParametersInsetFieldValueSuperSet(
        value,
        outerIndex,
        exerIndex
      );
      return;
    }
    if (value.includes("Vdot")) {
      items[outerIndex].set = [items[outerIndex].set[0]];
    }
    items[outerIndex].parameters = value;
    setExsrInfo(items[outerIndex].parameters);
    setaddArray(items);
    setModalChangeExe(true);
    handleChangeParametersInsetFieldValue(value, outerIndex);
  };

  const memueOptions = (value) => {
    const MENU_OPTIONS = [];
    MENU_OPTIONS.unshift(
      {
        label: "Move Up",
        icon: "mdi:arrow-up",
        handleClick: handleChangeUp,
        disabled: value == 0 ? true : false,
      },
      {
        label: "Move Down",
        icon: "ic:outline-arrow-downward",
        handleClick: handleChangeDown,
        disabled: value >= addArray.length - 1 ? true : false,
      }
      // {
      //   label: "Parameters",
      //   icon: "pajamas:labels",
      //   handleClick: handleParameters,
      // }
    );

    return MENU_OPTIONS;
  };
  const getPaceTextinPaces = (type) => {
    let result = "";
    if (type == "m") {
      result = "Time Cap";
    } else if (type == "mi") {
      result = "Pace Min/Mile";
    } else if (type == "km") {
      result = "Pace Min/Km";
    }
    return result;
  };
  const getKmhrForPace = (value, time, type) => {
    if (!value || !time) {
      return 0;
    }
    let result = 0;
    if (type == "m") {
      result = (value / time) * 3.6;
    } else if (type == "mi") {
      result = ((1 * 1000) / time) * 3.6 * 1.609;
    } else if (type == "km") {
      result = ((1 * 1000) / time) * 3.6;
    }
    return result.toFixed(2);
  };
  const getMihrForPace = (value, time, type) => {
    if (!value || !time) {
      return 0;
    }
    let result = 0;
    if (type == "m") {
      result = result = ((value / time) * 3.6) / 1.609;
    } else if (type == "mi") {
      result = ((1 * 1000) / time) * 3.6;
    } else if (type == "km") {
      result = (((1 * 1000) / time) * 3.6) / 1.609;
    }
    return result.toFixed(2);
  };
  const getPaceText = (type) => {
    let result = "";
    if (type?.includes("_200_")) {
      result = "Time Cap";
    } else if (type?.includes("_300_")) {
      result = "Time Cap";
    } else if (type?.includes("_400_")) {
      result = "Time Cap";
    } else if (type?.includes("_600_")) {
      result = "Time Cap";
    } else if (type?.includes("_800_")) {
      result = "Time Cap";
    } else if (type?.includes("_1200_")) {
      result = "Time Cap";
    } else if (type?.includes("mi")) {
      result = "Pace Min/Mile";
    } else if (type?.includes("km")) {
      result = "Pace Min/Km";
    }
    return result;
  };
  const getKmhr = (value, type) => {
    let result = 0;
    if (type?.includes("_200_")) {
      result = (200 * 3600) / (parseInt(value) * 1000);
    } else if (type?.includes("_300_")) {
      result = (300 * 3600) / (parseInt(value) * 1000);
    } else if (type?.includes("_400_")) {
      result = (400 * 3600) / (parseInt(value) * 1000);
    } else if (type?.includes("_600_")) {
      result = (600 * 3600) / (parseInt(value) * 1000);
    } else if (type?.includes("_800_")) {
      result = (800 * 3600) / (parseInt(value) * 1000);
    } else if (type?.includes("_1200_")) {
      result = (1200 * 3600) / (parseInt(value) * 1000);
    } else if (type?.includes("mi")) {
      result = (3600 / parseInt(value)) * 1.609;
    } else if (type?.includes("km")) {
      result = 3600 / parseInt(value);
    }
    return result.toFixed(2);
  };
  const getMihr = (value, type) => {
    let result = 0;
    if (type?.includes("_200_")) {
      result = (200 * 3600) / (parseInt(value) * 1000) / 1.609;
    } else if (type?.includes("_300_")) {
      result = (300 * 3600) / (parseInt(value) * 1000) / 1.609;
    } else if (type?.includes("_400_")) {
      result = (400 * 3600) / (parseInt(value) * 1000) / 1.609;
    } else if (type?.includes("_600_")) {
      result = (600 * 3600) / (parseInt(value) * 1000) / 1.609;
    } else if (type?.includes("_800_")) {
      result = (800 * 3600) / (parseInt(value) * 1000) / 1.609;
    } else if (type?.includes("_1200_")) {
      result = (1200 * 3600) / (parseInt(value) * 1000) / 1.609;
    } else if (type?.includes("mi")) {
      result = 3600 / parseInt(value);
    } else if (type?.includes("km")) {
      result = 3600 / parseInt(value) / 1.609;
    }
    return result.toFixed(2);
  };
  const handleMinutesChangeSets = (e, mainindex, exerIndex, setIndex) => {
    const { name, value } = e.target;

    const list = [...addArray];

    const minutes = parseInt(e.target.value) || 0;
    const seconds =
      list[mainindex]["superSet"][exerIndex]["set"][setIndex][name] % 60;
    updateIntervalTimeSets(
      minutes,
      seconds,
      mainindex,
      exerIndex,
      setIndex,
      name
    );
  };

  const handleSecondsChangeSets = (e, mainindex, exerIndex, setIndex) => {
    const { name, value } = e.target;
    const list = [...addArray];

    let seconds = parseInt(e.target.value) || 0;
    seconds = Math.min(Math.max(seconds, 0), 59); // Ensure the value is between 0 and 60
    const minutes = Math.floor(
      list[mainindex]["superSet"][exerIndex]["set"][setIndex][name] / 60
    );
    updateIntervalTimeSets(
      minutes,
      seconds,
      mainindex,
      exerIndex,
      setIndex,
      name
    );
  };

  // Function to update intervalTime state
  const updateIntervalTimeSets = (
    minutes,
    seconds,
    mainindex,
    exerIndex,
    setIndex,
    name
  ) => {
    const totalSeconds = minutes * 60 + seconds;
    const list = [...addArray];
    list[mainindex]["superSet"][exerIndex]["set"][setIndex][name] =
      totalSeconds;
    setaddArray(list);
    setModalChangeExe(true);
  };
  const handleMinutesChange = (e, index, index1) => {
    const { name, value } = e.target;
    const list = [...addArray];

    const minutes = parseInt(e.target.value) || 0;
    const seconds = list[index]["set"][index1][name] % 60;
    updateIntervalTime(minutes, seconds, index, index1, name);
  };

  const handleSecondsChange = (e, index, index1) => {
    const { name, value } = e.target;
    const list = [...addArray];

    let seconds = parseInt(e.target.value) || 0;
    seconds = Math.min(Math.max(seconds, 0), 59); // Ensure the value is between 0 and 60
    const minutes = Math.floor(list[index]["set"][index1][name] / 60);
    updateIntervalTime(minutes, seconds, index, index1, name);
  };

  // Function to update intervalTime state
  const updateIntervalTime = (minutes, seconds, index, index1, name) => {
    const totalSeconds = minutes * 60 + seconds;
    const list = [...addArray];
    list[index]["set"][index1][name] = totalSeconds;
    setaddArray(list);
    setModalChangeExe(true);
  };
  const formatWithLeadingZeros = (value) => {
    return value < 10 ? `0${value}` : `${value}`;
  };
  const handleScrollCalled = (data) => {
    console.log("scrollkdsfkjhsdkjfal");
    const todayCell = document.querySelector(
      `.exercise-label-box[id="${data}"]`
    ); // Find the DOM element corresponding to today's date

    if (todayCell)
      todayCell.scrollIntoView({ behavior: "smooth", block: "center" });
  };
  const handleSetScrollCalled = (mainindex, index) => {
    console.log(
      "scrollkdsfkjhsdkjfal",
      `.workout-card-inset[id="${index},${mainindex}"]`
    );
    setTimeout(() => {
      const todayCell = document.querySelector(
        `.workout-card-inset[id="${index},${mainindex}"]`
      ); // Find the DOM element corresponding to today's date

      if (todayCell)
        todayCell.scrollIntoView({ behavior: "smooth", block: "center" });
    });
  };

  if (isReaload === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="w-100">
      {isLoading && (
        <CircularProgress className={classes.loading2} color="primary" />
      )}
      <CustomConfirmationOk
        open={openPaceAlert}
        setOpen={setOpenPaceAlert}
        title="Oops!"
        descriotion="This type & distance not suitable for that pace"
      />
      <ConfirmationWithDescription
        open={openSuperSetAlter}
        setOpen={setOpenSuperSetAlter}
        title={"Are you sure you want to take this action?"}
        descriotion={`You are trying to alternate an exercise with  ${toAlterChanges} sets with an exercise with ${alterChanges} sets. If you proceed, we’ll modify the exercise to have  ${toAlterChanges} sets.`}
        handleAgree={addAgreeInSuperSet}
      />
      <ConfirmationWithDescription
        open={openExerciseAlter}
        setOpen={setOpenExerciseAlter}
        title={"Are you sure you want to take this action?"}
        descriotion={`You are trying to alternate an exercise with  ${toAlterChanges} sets with an exercise with ${alterChanges} sets. If you proceed, we’ll modify the exercise to have  ${toAlterChanges} sets.`}
        handleAgree={addAgreeSuperSet}
      />
      <ModalToShowAnExerciseInfo
        open={openSingleExerciseInfo}
        setOpen={setOpenSingleExerciseInfo}
        Id={singleExerciseInfo}
      />
      <ToshowExercise
        open={openDetails}
        setOpen={setOpenDetails}
        handleChange={
          isWithHistory ? handleSelectExerciseSingle : handleSelectExercise
        }
        isWithHistory={isWithHistory}
      />
      <ToshowExercise
        open={isExerciseForSuperSet}
        setOpen={setIsExerciseForSuperSet}
        handleChange={
          isWithHistory
            ? handleSelectSupersetExerciseSingle
            : handleSelectSupersetExercise
        }
        isWithHistory={isWithHistory}
      />
      <ParameterModel
        open={modalOpen}
        setOpen={setModalOpen}
        data={exerInfo}
        handleChange={handleChangeParametersInset}
        type={"VDOT"}
      />
      <ExerciseHistoryModel
        open={historyModalOpen}
        setOpen={setHistoryModalOpen}
        data={historyModalData}
        handleChange={setHistoryModalData}
        handleCopyExercise={handleCopyExercise}
      />
      <ExerciseHistoryModel
        open={historyModalOpenSuperset}
        setOpen={setHistoryModalOpenSuperset}
        data={historyModalData}
        handleChange={setHistoryModalData}
        handleCopyExercise={handleCopySupersetExercise}
      />

      <div className="characters">
        {/* <form onSubmit={handleSubmit}> */}

        <form onSubmit={() => {}}>
          {addArray?.map((data, mainindex) => {
            return (
              <>
                {data?.type == "something_else" ? (
                  <SomeThingElseExercisesUI
                    addArray={addArray}
                    setaddArray={setaddArray}
                    setModalChangeExe={setModalChangeExe}
                    mainindex={mainindex}
                    isHistory={isHistory}
                    memberId={memberId}
                    member_vdot={member_vdot}
                  />
                ) : data?.type == "amrap" ? (
                  <AMRAPExercisesUI
                    isWithHistoryAndBigScreen={isWithHistoryAndBigScreen}
                    addArray={addArray}
                    setaddArray={setaddArray}
                    setModalChangeExe={setModalChangeExe}
                    mainindex={mainindex}
                    isHistory={isHistory}
                    memberId={memberId}
                    member_vdot={member_vdot}
                  />
                ) : data?.type == "rounds_for_time" ? (
                  <RoundsForTimeExercisesUI
                    isWithHistoryAndBigScreen={isWithHistoryAndBigScreen}
                    addArray={addArray}
                    setaddArray={setaddArray}
                    setModalChangeExe={setModalChangeExe}
                    mainindex={mainindex}
                    isHistory={isHistory}
                    memberId={memberId}
                    member_vdot={member_vdot}
                  />
                ) : data?.type == "reps_for_time" ? (
                  <RepsForTimeExercisesUI
                    isWithHistoryAndBigScreen={isWithHistoryAndBigScreen}
                    addArray={addArray}
                    setaddArray={setaddArray}
                    setModalChangeExe={setModalChangeExe}
                    mainindex={mainindex}
                    isHistory={isHistory}
                    memberId={memberId}
                    member_vdot={member_vdot}
                  />
                ) : data?.type == "emom" ? (
                  <EMOMExercisesUI
                    isWithHistoryAndBigScreen={isWithHistoryAndBigScreen}
                    addArray={addArray}
                    setaddArray={setaddArray}
                    setModalChangeExe={setModalChangeExe}
                    mainindex={mainindex}
                    isHistory={isHistory}
                    memberId={memberId}
                    member_vdot={member_vdot}
                  />
                ) : data?.superSet && data?.superSet.length > 0 ? (
                  <>
                    <div
                      style={{ position: "relative" }}
                      className="workout-Superset-card my-1"
                    >
                      {/* <SuperSets length={data?.superset_sets} data={data} /> */}
                      <Box>
                        <div className="d-flex align-items-start justify-content-between ">
                          <div className="d-flex align-items-center w-100  mb-2">
                            <div>Superset of</div>
                            <div className="ms-2 cross-icon ">
                              <RemoveCircleIcon
                                onClick={() => handleMinusSetCount(mainindex)}
                                className="diary-icon-plus-minus"
                              />
                            </div>
                            <TextField
                              id="outlined-basic"
                              size="small"
                              variant="outlined"
                              placeholder="Sets"
                              type="number"
                              value={data?.superset_sets}
                              name="superset_sets"
                              disabled
                              onChange={(e) =>
                                handleChangeSuperSetCount(e, mainindex)
                              }
                              InputLabelProps={{
                                className: "textfiels-input-label",
                                shrink: true,
                                style: { fontSize: "14px" },
                              }}
                              //   label="Set"
                              InputProps={{
                                className: "textfiels-input-value",
                                style: { fontSize: "13px", padding: "0" },
                              }}
                              sx={{
                                mx: 1,
                                borderRadius: "5px",
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                                fontSize: "13px",
                                "& input": {
                                  padding: "3px 5px",
                                  textAlign: "center",
                                },
                                width: "30px",
                              }}
                            />

                            <div className=" cross-icon me-2">
                              <AddCircleIcon
                                onClick={() => handleAddSetCount(mainindex)}
                                className="diary-icon-plus-minus"
                              />
                            </div>
                            <div>sets</div>
                          </div>

                          <div className=" cross-icon d-flex justify-content-end">
                            <>
                              <Tooltip title="Remove Exercise">
                                <RemoveCircleOutlineIcon
                                  onClick={() => handleDelete(mainindex)}
                                  className="diary-icon-remove"
                                />
                              </Tooltip>
                            </>

                            <Tooltip title="Add Exercise">
                              <AddCircleOutlineIcon
                                onClick={() => handleAdd(mainindex)}
                                className="diary-icon-add"
                              />
                            </Tooltip>

                            <CustomPopoverSection
                              menu={memueOptions(mainindex)}
                              data={mainindex}
                            />
                          </div>
                        </div>

                        <div className=" mb-2 ">
                          {data?.superSet?.map((exerData, exerIndex) => (
                            <div
                              style={{
                                width: "100%",
                                padding: "1px",
                                position: "relative",
                              }}
                            >
                              <div
                                className="d-flex workout-set-card"
                                style={{
                                  width: "100%",
                                  position: "relative",
                                  overflow: "hidden",
                                }}
                              >
                                <Box
                                  sx={{
                                    position: "relative",
                                    width: "100%",
                                  }}
                                >
                                  <div
                                    className="row  "
                                    onMouseEnter={() => handleMouseLeave()}
                                  >
                                    <div
                                      className={`col-sm-12 ${
                                        isHistory ? "col-md-6" : "col-md-7"
                                      } col-lg-6 d-flex align-items-center p-0 mt-2 mt-md-0 mt-lg-0`}
                                    >
                                      {exerData?.is_modified && (
                                        <span
                                          style={{
                                            position: "absolute",
                                            top: "-11px",
                                            justifyContent: "center",
                                            zIndex: "1",
                                            left: "-38px",
                                            transform: "rotate(-53deg)",
                                          }}
                                        >
                                          <Chip
                                            label={"Modified"}
                                            variant="contained"
                                            color={"error"}
                                            sx={{
                                              fontSize: "10px",
                                              height: "auto",
                                              width: "auto",
                                            }}
                                          />
                                        </span>
                                      )}
                                      <div className="exercise-label-box">
                                        <span className="outer-label">
                                          Exercise
                                        </span>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <div
                                            className="exercise-label w-100"
                                            onClick={() =>
                                              handleSupersetExerciseList(
                                                mainindex,
                                                exerIndex,
                                                exerData?.exercise
                                                  ? true
                                                  : false
                                              )
                                            }
                                          >
                                            <div className="d-flex align-items-center">
                                              {exerData?.exercise?.image
                                                ?.thumbnail_3 && (
                                                <Avatar
                                                  sx={{ borderRadius: "5px" }}
                                                  alt="User Image"
                                                  src={
                                                    s3baseUrl +
                                                    exerData?.exercise?.image
                                                      ?.thumbnail_3
                                                  }
                                                />
                                              )}
                                              <div className="label-text ms-1">
                                                {exerData?.exercise?.title
                                                  ? exerData?.exercise?.title
                                                  : ""}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="exercise-action">
                                            <div style={{ fontSize: "16px" }}>
                                              {exerData?.exercise ? (
                                                <div>
                                                  <InfoIcon
                                                    onClick={() =>
                                                      handleClickOpen(
                                                        exerData?.exercise?._id
                                                      )
                                                    }
                                                    className="d-block"
                                                    fontSize="14px"
                                                    sx={{
                                                      opacity: 0.5,
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                  <EditIcon
                                                    onClick={() =>
                                                      handleSupersetExerciseList(
                                                        mainindex,
                                                        exerIndex,
                                                        true
                                                      )
                                                    }
                                                    fontSize="12px"
                                                    sx={{
                                                      opacity: 0.5,
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                </div>
                                              ) : (
                                                <AddIcon
                                                  onClick={() =>
                                                    handleSupersetExerciseList(
                                                      mainindex,
                                                      exerIndex
                                                    )
                                                  }
                                                  sx={{
                                                    opacity: 0.5,
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <Button
                                        sx={{ textTransform: "inherit" }}
                                        size="small"
                                        color="primary"
                                        variant="contained"
                                        className="d-flex align-items-center ms-2 small-contained-dialog-button"
                                        onClick={() => {
                                          handleArrowSuperSet(
                                            !exerData?.open ? true : false,
                                            mainindex,
                                            exerIndex
                                          );
                                        }}
                                      >
                                        {!exerData?.open ? (
                                          <KeyboardArrowDownIcon
                                            sx={{
                                              cursor: "pointer",
                                              // opacity: 0.5,
                                              fontSize: "20px",
                                            }}
                                          />
                                        ) : (
                                          <KeyboardArrowUpIcon
                                            sx={{
                                              cursor: "pointer",
                                              // opacity: 0.5,
                                              fontSize: "20px",
                                            }}
                                          />
                                        )}

                                        <Typography
                                          variant="body2"
                                          sx={{
                                            // opacity: 0.6,
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {` Sets ${exerData?.set?.length}`}
                                        </Typography>
                                      </Button>
                                    </div>

                                    <div
                                      className={`col-sm-12 ${
                                        isHistory ? "col-md-6" : "col-md-5"
                                      } col-lg-6 p-0 mt-2 mt-md-0 mt-lg-0 d-flex align-items-center justify-content-start `}
                                    >
                                      <div className="d-flex align-items-center mx-1">
                                        <div
                                          className="d-flex align-items-center ms-3 set-parameters "
                                          onClick={() => {
                                            handleParametersSuperset(
                                              mainindex,
                                              exerIndex
                                            );
                                          }}
                                        >
                                          <Icon
                                            icon="pajamas:labels"
                                            fontSize="10px"
                                          />
                                          <Typography
                                            variant="body2"
                                            sx={{ ml: 0.5 }}
                                          >
                                            {`Parameters`}
                                          </Typography>
                                        </div>
                                        {!isWithHistoryAndBigScreen &&
                                        isHistory &&
                                        exerData?.exercise ? (
                                          <Button
                                            sx={{ textTransform: "inherit" }}
                                            size="small"
                                            color="primary"
                                            variant="contained"
                                            className="d-flex align-items-center ms-2 small-contained-dialog-button"
                                            onClick={() => {
                                              handleHistorySupersetOpen(
                                                exerData,
                                                mainindex,
                                                exerIndex
                                              );
                                            }}
                                          >
                                            <Icon
                                              icon="fa-solid:history"
                                              fontSize="10px"
                                            />
                                            <Typography
                                              variant="body2"
                                              sx={{ ml: 0.5 }}
                                            >
                                              {`History`}
                                            </Typography>
                                          </Button>
                                        ) : (
                                          isWithHistoryAndBigScreen &&
                                          exerData?.history &&
                                          selectedExercise !=
                                            exerData?.exercise?._id && (
                                            <Button
                                              sx={{ textTransform: "inherit" }}
                                              size="small"
                                              color="primary"
                                              variant="contained"
                                              className="d-flex align-items-center ms-2 small-contained-dialog-button"
                                              onClick={() => {
                                                handleHistoryPreviousOpen(
                                                  exerData
                                                );
                                              }}
                                            >
                                              <Icon
                                                icon="fa-solid:history"
                                                fontSize="10px"
                                              />
                                              <Typography
                                                variant="body2"
                                                sx={{ ml: 0.5 }}
                                              >
                                                {`History`}
                                              </Typography>
                                            </Button>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  {exerData?.open &&
                                    exerData?.set?.map((item, setIndex) => (
                                      <div className="d-flex align-items-center my-3 workout-card-inset">
                                        <div className="d-flex align-items-center w-100">
                                          <Typography
                                            variant="body1"
                                            sx={{
                                              padding: "25px 4px",
                                              color: "inherit",
                                              backgroundColor: "#edeff1",
                                              height: "100%",
                                              width: "15px",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              borderRadius: "7px",
                                            }}
                                          >
                                            {setIndex + 1}
                                          </Typography>
                                          <CloseIcon sx={{ opacity: 0.3 }} />
                                          <div className="row w-100">
                                            {(exerData.parameters.includes(
                                              "Pace"
                                            ) ||
                                              exerData.parameters.includes(
                                                "Vdot"
                                              )) && (
                                              <>
                                                <div
                                                  className={
                                                    isWithHistoryAndBigScreen
                                                      ? "col-3 pe-0 py-2"
                                                      : `col-12 col-md-3 col-lg-2 pe-0 py-2`
                                                  }
                                                >
                                                  <FormControl fullWidth>
                                                    <InputLabel
                                                      className="set-Type-lable"
                                                      sx={{
                                                        fontSize: "14px",
                                                      }}
                                                      id="demo-simple-select-label"
                                                    >
                                                      Type
                                                    </InputLabel>

                                                    <Select
                                                      id="select-option"
                                                      variant="outlined"
                                                      size="small"
                                                      name="race_type"
                                                      value={item?.race_type}
                                                      label="Type"
                                                      inputProps={{
                                                        style: {
                                                          padding: 0,
                                                        },
                                                      }}
                                                      onChange={(e) => {
                                                        handleChangeSuperSet(
                                                          e,
                                                          mainindex,
                                                          exerIndex,
                                                          setIndex
                                                        );
                                                      }}
                                                      sx={{
                                                        width: "100%",
                                                        fontSize: "13px",
                                                        "& .MuiOutlinedInput-input":
                                                          {
                                                            paddingLeft: 1,
                                                          },
                                                      }}
                                                    >
                                                      <MenuItem value="el">
                                                        Easy/Long
                                                      </MenuItem>
                                                      <MenuItem value="c">
                                                        Compromised
                                                      </MenuItem>

                                                      <MenuItem value="i">
                                                        Interval
                                                      </MenuItem>
                                                      <MenuItem value="r">
                                                        Repitition
                                                      </MenuItem>
                                                    </Select>
                                                  </FormControl>
                                                </div>
                                                <div
                                                  className={
                                                    isWithHistoryAndBigScreen
                                                      ? "col-3 pe-0 py-2"
                                                      : `col-12 col-md-3 col-lg-2 pe-0 py-2`
                                                  }
                                                >
                                                  <TextField
                                                    sx={{
                                                      borderRadius: "5px",
                                                      paddingRight: 0,
                                                      width: "100%",
                                                    }}
                                                    size="small"
                                                    id="custom-input"
                                                    variant="outlined"
                                                    placeholder={"Distance"}
                                                    name="distance"
                                                    label="Distance"
                                                    value={item?.distance}
                                                    onChange={(e) => {
                                                      handleChangeSuperSet(
                                                        e,
                                                        mainindex,
                                                        exerIndex,
                                                        setIndex
                                                      );
                                                    }}
                                                    type="number"
                                                    InputLabelProps={{
                                                      className:
                                                        "textfiels-input-label",
                                                      shrink: true,
                                                      style: {
                                                        fontSize: "14px",
                                                      },
                                                    }}
                                                    InputProps={{
                                                      className:
                                                        "textfiels-input-value",
                                                      style: {
                                                        fontSize: "13px",
                                                        paddingRight: 0,
                                                      },
                                                      endAdornment: (
                                                        <InputAdornment position="end">
                                                          <Select
                                                            id="select-option"
                                                            variant="outlined"
                                                            size="small"
                                                            name="distanceType"
                                                            value={
                                                              item?.distanceType
                                                            }
                                                            label="Weight Type"
                                                            onChange={(e) => {
                                                              handleChangeSuperSet(
                                                                e,
                                                                mainindex,
                                                                exerIndex,
                                                                setIndex
                                                              );
                                                            }}
                                                            sx={{
                                                              "& legend": {
                                                                display: "none",
                                                              },
                                                              "& fieldset": {
                                                                top: 0,
                                                              },
                                                              fontSize: "13px",
                                                              border: "none",
                                                              "& fieldset": {
                                                                border: "none",
                                                              },
                                                            }}
                                                          >
                                                            <MenuItem value="m">
                                                              Meter
                                                            </MenuItem>
                                                            <MenuItem value="km">
                                                              KM
                                                            </MenuItem>
                                                            <MenuItem value="mi">
                                                              Mile
                                                            </MenuItem>
                                                          </Select>
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                  />
                                                </div>
                                                <div
                                                  className={
                                                    isWithHistoryAndBigScreen
                                                      ? "col-3 pe-0 py-2"
                                                      : `col-12 col-md-2 col-lg-2 pe-0 py-2`
                                                  }
                                                >
                                                  <TextField
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    label={getPaceTextinPaces(
                                                      item?.distanceType
                                                    )}
                                                    InputLabelProps={{
                                                      className:
                                                        "textfiels-input-label",
                                                      shrink: true,
                                                      style: {
                                                        fontSize: "14px",
                                                      },
                                                    }}
                                                    InputProps={{
                                                      className:
                                                        "textfiels-input-value",
                                                      style: {
                                                        fontSize: "13px",
                                                        paddingLeft: "0px",
                                                        paddingRight: "0px",

                                                        justifyContent:
                                                          "center",
                                                      },
                                                      readOnly: true,
                                                      endAdornment: (
                                                        <InputAdornment
                                                          position="end"
                                                          style={{
                                                            display: "flex",
                                                            alignItems:
                                                              "center",
                                                          }}
                                                        >
                                                          <TextField
                                                            id="outlined-basic-minutes"
                                                            type="number"
                                                            size="small"
                                                            placeholder="Min"
                                                            name="pace"
                                                            value={formatWithLeadingZeros(
                                                              Math.floor(
                                                                item?.pace / 60
                                                              )
                                                            )}
                                                            onChange={(e) =>
                                                              handleMinutesChangeSets(
                                                                e,
                                                                mainindex,
                                                                exerIndex,
                                                                setIndex
                                                              )
                                                            }
                                                            InputProps={{
                                                              className:
                                                                "textfiels-input-value",
                                                              style: {
                                                                fontSize:
                                                                  "14px",
                                                              },
                                                            }}
                                                            sx={{
                                                              "& fieldset": {
                                                                border: "none",
                                                              },
                                                              "& input": {
                                                                textAlign:
                                                                  "center",
                                                                padding: "0px",
                                                              },
                                                              width: "100%",
                                                            }}
                                                          />
                                                          <div className="fs-6 fw-semibold ms-1 ">
                                                            :
                                                          </div>

                                                          <TextField
                                                            id="outlined-basic-seconds"
                                                            type="number"
                                                            size="small"
                                                            placeholder="Sec"
                                                            name="pace"
                                                            value={formatWithLeadingZeros(
                                                              item?.pace % 60
                                                            )}
                                                            onChange={(e) =>
                                                              handleSecondsChangeSets(
                                                                e,
                                                                mainindex,
                                                                exerIndex,
                                                                setIndex
                                                              )
                                                            }
                                                            InputProps={{
                                                              className:
                                                                "textfiels-input-value",
                                                              style: {
                                                                fontSize:
                                                                  "14px",
                                                              },
                                                            }}
                                                            sx={{
                                                              "& fieldset": {
                                                                border: "none",
                                                              },
                                                              "& input": {
                                                                textAlign:
                                                                  "center",
                                                                padding: "0px",
                                                              },
                                                              width: "100%",
                                                            }}
                                                          />
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                    sx={{
                                                      borderRadius: "5px",
                                                      fontSize: "13px",
                                                      "& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input":
                                                        {
                                                          width:
                                                            "0px !important",
                                                          padding:
                                                            "9px 0px !important",
                                                        },
                                                      "& .css-1nuss9t": {
                                                        width: "0px !important",
                                                        padding:
                                                          "9px 0px !important",
                                                      },
                                                      width: "100%",
                                                    }}
                                                  />
                                                </div>

                                                <div
                                                  style={{
                                                    position: "absolute",
                                                    bottom: "4px",
                                                    left: "5px",
                                                  }}
                                                >
                                                  <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                      opacity: 0.6,
                                                      fontWeight: "600",
                                                      fontSize: "12px",
                                                    }}
                                                  >{` @ Speed ${getKmhrForPace(
                                                    item?.distance,
                                                    item?.pace,
                                                    item?.distanceType
                                                  )} km/hr or ${getMihrForPace(
                                                    item?.distance,
                                                    item?.pace,
                                                    item?.distanceType
                                                  )} mi/hr`}</Typography>
                                                </div>
                                              </>
                                            )}
                                            {(exerData.parameters.includes(
                                              "Duration/Pace"
                                            ) ||
                                              exerData.parameters.includes(
                                                "Time"
                                              )) && (
                                              <div
                                                className={
                                                  isWithHistoryAndBigScreen
                                                    ? "col-3 pe-0 py-2"
                                                    : `col-12 col-md-3 col-lg-2 pe-0 py-2`
                                                }
                                              >
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="0"
                                                  type="number"
                                                  value={item?.time}
                                                  name="time"
                                                  onChange={(e) => {
                                                    handleChangeSuperSet(
                                                      e,
                                                      mainindex,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  label={
                                                    exerData.parameters.includes(
                                                      "Duration/Pace"
                                                    )
                                                      ? "Duration/Pace"
                                                      : "Time"
                                                  }
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="timeType"
                                                          value={item?.timeType}
                                                          label="Weight Type"
                                                          inputProps={{
                                                            style: {
                                                              padding: 0,
                                                            },
                                                          }}
                                                          onChange={(e) => {
                                                            handleChangeSuperSet(
                                                              e,
                                                              mainindex,
                                                              exerIndex,
                                                              setIndex
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                            "& .MuiOutlinedInput-input":
                                                              {
                                                                padding: 0,
                                                              },
                                                          }}
                                                        >
                                                          <MenuItem value="sec">
                                                            sec
                                                          </MenuItem>
                                                          <MenuItem value="min">
                                                            min
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",
                                                    fontSize: "13px",
                                                    width: "100%",
                                                  }}
                                                />
                                              </div>
                                            )}

                                            {exerData.parameters.includes(
                                              "Reps"
                                            ) && (
                                              <div
                                                className={
                                                  isWithHistoryAndBigScreen
                                                    ? "col-2 pe-0 py-2"
                                                    : "col-12 col-md-2 col-lg-1 pe-0 py-2"
                                                }
                                              >
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="Reps"
                                                  type="number"
                                                  label="Reps"
                                                  value={item?.reps}
                                                  name="reps"
                                                  onChange={(e) => {
                                                    handleChangeSuperSet(
                                                      e,
                                                      mainindex,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                    },
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",
                                                    fontSize: "13px",
                                                    width: "100%",
                                                  }}
                                                />
                                              </div>
                                            )}
                                            {exerData.parameters.includes(
                                              "Max_Reps"
                                            ) && (
                                              <div
                                                className={
                                                  isWithHistoryAndBigScreen
                                                    ? "col-2 pe-0 py-2"
                                                    : "col-12 col-md-2 col-lg-1 pe-0 py-2"
                                                }
                                              >
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="Max Reps"
                                                  type="number"
                                                  label="Max Reps"
                                                  value={item?.maxReps}
                                                  name="maxReps"
                                                  onChange={(e) => {
                                                    handleChangeSuperSet(
                                                      e,
                                                      mainindex,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                    },
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",
                                                    fontSize: "13px",
                                                    width: "100%",
                                                  }}
                                                />
                                              </div>
                                            )}
                                            {exerData.parameters.includes(
                                              "Height"
                                            ) && (
                                              <div
                                                className={
                                                  isWithHistoryAndBigScreen
                                                    ? "col-3 pe-0 py-2"
                                                    : "col-12 col-md-3 col-lg-2 pe-0 py-2"
                                                }
                                              >
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="0"
                                                  type="number"
                                                  value={item?.height}
                                                  name="height"
                                                  onChange={(e) => {
                                                    handleChangeSuperSet(
                                                      e,
                                                      mainindex,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  label="Height"
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="heightType"
                                                          value={
                                                            item?.heightType
                                                          }
                                                          label="Height Type"
                                                          inputProps={{
                                                            style: {
                                                              padding: 0,
                                                            },
                                                          }}
                                                          onChange={(e) => {
                                                            handleChangeSuperSet(
                                                              e,
                                                              mainindex,
                                                              exerIndex,
                                                              setIndex
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                            "& .MuiOutlinedInput-input":
                                                              {
                                                                padding: 0,
                                                              },
                                                          }}
                                                        >
                                                          <MenuItem value="cm">
                                                            Cm
                                                          </MenuItem>
                                                          <MenuItem value="ft">
                                                            Feet
                                                          </MenuItem>
                                                          <MenuItem value="inch">
                                                            Inch
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",
                                                    fontSize: "13px",
                                                    width: "100%",
                                                  }}
                                                />
                                              </div>
                                            )}
                                            {exerData.parameters.includes(
                                              "Weight"
                                            ) && (
                                              <div
                                                className={
                                                  isWithHistoryAndBigScreen
                                                    ? "col-3 pe-0 py-2"
                                                    : "col-12 col-md-4 col-lg-2 pe-0 py-2"
                                                }
                                              >
                                                <TextField
                                                  sx={{
                                                    borderRadius: "5px",
                                                    paddingRight: 0,
                                                    width: "100%",
                                                  }}
                                                  size="small"
                                                  id="custom-input"
                                                  variant="outlined"
                                                  placeholder="Weight"
                                                  name="weight"
                                                  label="Weight"
                                                  value={item.weight}
                                                  onChange={(e) => {
                                                    handleChangeSuperSet(
                                                      e,
                                                      mainindex,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  type="number"
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="weightType"
                                                          value={
                                                            item?.weightType
                                                          }
                                                          label="Weight Type"
                                                          onChange={(e) => {
                                                            handleChangeSuperSet(
                                                              e,
                                                              mainindex,
                                                              exerIndex,
                                                              setIndex
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                            "& .MuiOutlinedInput-input":
                                                              {
                                                                padding: 0,
                                                              },
                                                          }}
                                                        >
                                                          <MenuItem value="1rm">
                                                            % 1RM
                                                          </MenuItem>
                                                          <MenuItem value="body_weight">
                                                            % BW
                                                          </MenuItem>
                                                          <MenuItem value="kg">
                                                            KG
                                                          </MenuItem>
                                                          <MenuItem value="lb">
                                                            Pound
                                                          </MenuItem>
                                                          <MenuItem value="poods">
                                                            Poods
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                              </div>
                                            )}
                                            {exerData.parameters.includes(
                                              "Distance/Calories"
                                            ) && (
                                              <div
                                                className={
                                                  isWithHistoryAndBigScreen
                                                    ? "col-3 pe-0 py-2"
                                                    : "col-12 col-md-3 col-lg-2 pe-0 py-2"
                                                }
                                              >
                                                <TextField
                                                  sx={{
                                                    borderRadius: "5px",
                                                    paddingRight: 0,
                                                    width: "100%",
                                                  }}
                                                  size="small"
                                                  id="custom-input"
                                                  variant="outlined"
                                                  placeholder={
                                                    item?.distanceType ==
                                                    "calories"
                                                      ? "Calories"
                                                      : "Distance"
                                                  }
                                                  name="distance"
                                                  label="Distance/Calories"
                                                  value={item?.distance}
                                                  onChange={(e) => {
                                                    handleChangeSuperSet(
                                                      e,
                                                      mainindex,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  type="number"
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="distanceType"
                                                          value={
                                                            item?.distanceType
                                                          }
                                                          label="Weight Type"
                                                          onChange={(e) => {
                                                            handleChangeSuperSet(
                                                              e,
                                                              mainindex,
                                                              exerIndex,
                                                              setIndex
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                            "& .MuiOutlinedInput-input":
                                                              {
                                                                padding: 0,
                                                              },
                                                          }}
                                                        >
                                                          <MenuItem value="m">
                                                            Meter
                                                          </MenuItem>
                                                          <MenuItem value="km">
                                                            KM
                                                          </MenuItem>
                                                          <MenuItem value="mi">
                                                            Mile
                                                          </MenuItem>
                                                          <MenuItem value="calories">
                                                            Calories
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                              </div>
                                            )}
                                            {exerData.parameters.includes(
                                              "%MHR"
                                            ) && (
                                              <div
                                                className={
                                                  isWithHistoryAndBigScreen
                                                    ? "col-2 pe-0 py-2"
                                                    : "col-12 col-md-2 col-lg-1 pe-0 py-2"
                                                }
                                              >
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="%MHR"
                                                  type="number"
                                                  label="%MHR"
                                                  value={item?.mhr}
                                                  name="mhr"
                                                  onChange={(e) => {
                                                    handleChangeSuperSetTempo(
                                                      e,
                                                      mainindex,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                    },
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",
                                                    fontSize: "13px",
                                                    width: "100%",
                                                    "& .MuiOutlinedInput-input":
                                                      {
                                                        padding: "8px 7px",
                                                      },
                                                  }}
                                                />
                                              </div>
                                            )}
                                            {exerData.parameters.includes(
                                              "Tempo"
                                            ) && (
                                              <div
                                                className={
                                                  isWithHistoryAndBigScreen
                                                    ? "col-2 pe-0 py-2"
                                                    : "col-12 col-md-2 col-lg-1 pe-0 py-2"
                                                }
                                              >
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="Tempo"
                                                  type="number"
                                                  label="Tempo"
                                                  value={item?.tempo}
                                                  name="tempo"
                                                  onChange={(e) => {
                                                    handleChangeSuperSetTempo(
                                                      e,
                                                      mainindex,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                    },
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",

                                                    fontSize: "13px",
                                                    width: "100%",
                                                    "& .MuiOutlinedInput-input":
                                                      {
                                                        padding: "8px 7px",
                                                      },
                                                  }}
                                                />
                                              </div>
                                            )}
                                            {exerData.parameters.includes(
                                              "Rest"
                                            ) && (
                                              <div
                                                className={
                                                  isWithHistoryAndBigScreen
                                                    ? "col-3 pe-0 py-2"
                                                    : "col-12 col-md-3 col-lg-2 pe-0 py-2"
                                                }
                                              >
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="0"
                                                  type="number"
                                                  value={item?.rest}
                                                  name="rest"
                                                  onChange={(e) => {
                                                    handleChangeSuperSet(
                                                      e,
                                                      mainindex,
                                                      exerIndex,
                                                      setIndex
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  label="Rest"
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="restType"
                                                          value={item?.restType}
                                                          label="Weight Type"
                                                          inputProps={{
                                                            style: {
                                                              padding: 0,
                                                            },
                                                          }}
                                                          onChange={(e) => {
                                                            handleChangeSuperSet(
                                                              e,
                                                              mainindex,
                                                              exerIndex,
                                                              setIndex
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                            "& .MuiOutlinedInput-input":
                                                              {
                                                                padding: 0,
                                                              },
                                                          }}
                                                        >
                                                          <MenuItem value="sec">
                                                            sec
                                                          </MenuItem>
                                                          <MenuItem value="min">
                                                            min
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",
                                                    fontSize: "13px",
                                                    width: "100%",
                                                  }}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  {exerData?.open && (
                                    <TextField
                                      id="outlined-basic"
                                      size="small"
                                      variant="outlined"
                                      placeholder="Instructions"
                                      label="Instructions"
                                      type="number"
                                      value={exerData.description}
                                      name="description"
                                      multiline
                                      rows={2}
                                      onChange={(e) =>
                                        handleChangeSuperSetDes(
                                          e,
                                          mainindex,
                                          exerIndex
                                        )
                                      }
                                      InputLabelProps={{
                                        className: "textfiels-input-label",
                                        shrink: true,
                                        style: { fontSize: "14px" },
                                      }}
                                      InputProps={{
                                        className: "textfiels-input-value",
                                        style: { fontSize: "14px" },
                                      }}
                                      sx={{
                                        borderRadius: "5px",
                                        // "& legend": { display: "none" },
                                        // "& fieldset": { top: 0 },

                                        mt: 2,
                                        width: "100%",
                                      }}
                                    />
                                  )}
                                  {exerData?.open && (
                                    <div className="row mt-3 d-flex justify-content-center">
                                      <div className="col-md-2 col-lg-1 col-3">
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleArrowSuperSet(
                                              false,
                                              mainindex,
                                              exerIndex
                                            );
                                          }}
                                          className="w-100 small-contained-set-button"
                                        >
                                          <KeyboardArrowUpIcon
                                            sx={{
                                              cursor: "pointer",
                                              opacity: 0.5,
                                              fontSize: "20px",
                                            }}
                                            onClick={() => {
                                              handleArrowSuperSet(
                                                false,
                                                mainindex,
                                                exerIndex
                                              );
                                            }}
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </Box>
                              </div>
                              <span
                                className="cross-icon"
                                style={{
                                  position: "absolute",
                                  bottom: "-10px",
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "center",
                                  zIndex: 1,
                                }}
                              >
                                {data?.superSet.length > 1 &&
                                exerIndex < data?.superSet.length - 1 ? (
                                  <div className="">
                                    <SyncDisabledIcon
                                      onClick={() =>
                                        removeSuperSet(mainindex, exerIndex)
                                      }
                                      className="sync-icon-remove"
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          ))}
                        </div>
                      </Box>
                      <span
                        className="cross-icon"
                        style={{
                          position: "absolute",
                          bottom: "-17px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          zIndex: 1,
                          // backgroundColor: "gray",
                          // borderRadius: "50%",
                          // padding: "4px 5px",
                        }}
                      >
                        {addArray.length > 1 &&
                        mainindex < addArray.length - 1 &&
                        // !addArray[mainindex + 1].parameters.includes("Vdot") &&
                        // !addArray[mainindex].parameters.includes("Vdot") &&
                        addArray[mainindex + 1]?.type == "general" ? (
                          <div className="">
                            <SyncIcon
                              onClick={() => handleAgreeSuperSet(mainindex)}
                              className="sync-icon-add"
                            />
                            {/* ) : (
                                      <SyncDisabledIcon
                                        onClick={() => removeSuperSet(index)}
                                        className="sync-icon-remove"
                                      />
                                    )} */}
                          </div>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      padding: "1px",
                      position: "relative",
                    }}
                  >
                    <div
                      className=" my-1 workout-set-card w-100"
                      onMouseEnter={() => handleMouseLeave()}
                      style={{
                        width: "100%",
                        position: "relative",
                        overflow: "hidden",
                      }}
                    >
                      <div className=" cross-icon d-flex justify-content-end">
                        <>
                          <Tooltip title="Remove Exercise">
                            <RemoveCircleOutlineIcon
                              onClick={() => handleDelete(mainindex)}
                              className="diary-icon-remove"
                            />
                          </Tooltip>
                        </>

                        <Tooltip title="Add Exercise">
                          <AddCircleOutlineIcon
                            onClick={() => handleAdd(mainindex)}
                            className="diary-icon-add"
                          />
                        </Tooltip>

                        <CustomPopoverSection
                          menu={memueOptions(mainindex)}
                          data={mainindex}
                        />
                      </div>
                      <Box
                        sx={{
                          position: "relative",
                          width: "100%",
                        }}
                        onMouseEnter={() => handleMouseLeave()}
                      >
                        <div
                          className="row  "
                          onMouseEnter={() => handleMouseLeave()}
                        >
                          <div
                            className={`col-sm-12 ${
                              isHistory ? "col-md-6" : "col-md-7"
                            } col-lg-6 d-flex align-items-center p-0 mt-2 mt-md-0 mt-lg-0`}
                          >
                            {data?.is_modified && (
                              <span
                                style={{
                                  position: "absolute",
                                  top: "-32px",
                                  justifyContent: "center",
                                  zIndex: "1",
                                  left: "-38px",
                                  transform: "rotate(-53deg)",
                                }}
                              >
                                <Chip
                                  label={"Modified"}
                                  variant="contained"
                                  color={"error"}
                                  sx={{
                                    fontSize: "10px",
                                    height: "auto",
                                    width: "auto",
                                  }}
                                />
                              </span>
                            )}
                            <div className="exercise-label-box" id={mainindex}>
                              <span className="outer-label">Exercise</span>
                              <div className="d-flex align-items-center justify-content-between">
                                <div
                                  className="exercise-label w-100"
                                  onClick={() =>
                                    handleExerciseList(
                                      mainindex,
                                      data?.exercise ? true : false
                                    )
                                  }
                                >
                                  <div className="d-flex align-items-center">
                                    {data?.exercise?.image?.thumbnail_3 && (
                                      <Avatar
                                        sx={{ borderRadius: "5px" }}
                                        alt="User Image"
                                        src={
                                          s3baseUrl +
                                          data?.exercise?.image?.thumbnail_3
                                        }
                                      />
                                    )}
                                    <div className="label-text ms-1">
                                      {data?.exercise?.title
                                        ? data?.exercise?.title
                                        : ""}
                                    </div>
                                  </div>
                                </div>
                                <div className="exercise-action">
                                  <div style={{ fontSize: "16px" }}>
                                    {data?.exercise ? (
                                      <div>
                                        <InfoIcon
                                          onClick={() =>
                                            handleClickOpen(data?.exercise?._id)
                                          }
                                          className="d-block"
                                          fontSize="14px"
                                          sx={{
                                            opacity: 0.5,
                                            cursor: "pointer",
                                          }}
                                        />
                                        <EditIcon
                                          onClick={() =>
                                            handleExerciseList(mainindex, true)
                                          }
                                          fontSize="12px"
                                          sx={{
                                            opacity: 0.5,
                                            cursor: "pointer",
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <AddIcon
                                        onClick={() =>
                                          handleExerciseList(mainindex)
                                        }
                                        sx={{
                                          opacity: 0.5,
                                          cursor: "pointer",
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <TextField
                                id="outlined-basic"
                                variant="outlined"
                                placeholder="Exercise"
                                value={
                                  data?.exercise?.title
                                    ? data?.exercise?.title
                                    : ""
                                }
                                name="Exercise"
                                // onChange={(e) => {}}
                                InputLabelProps={{className:"textfiels-input-label",
                                  shrink: true,
                                  style: { fontSize: "14px" },
                                }}
                                label="Exercise"
                                InputProps={{className:"textfiels-input-value",
                                  style: {
                                    fontSize: "13px",
                                    paddingLeft: "6px",
                                  },
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {data?.exercise?.image?.thumbnail_3 && (
                                        <Avatar
                                          onClick={() =>
                                            handleExerciseList(mainindex)
                                          }
                                          sx={{ borderRadius: "5px" }}
                                          alt="User Image"
                                          src={
                                            s3baseUrl +
                                            data?.exercise?.image?.thumbnail_3
                                          }
                                        />
                                      )}
                                    </InputAdornment>
                                  ),
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <div style={{ fontSize: "16px" }}>
                                        {data?.exercise ? (
                                          <div>
                                            <InfoIcon
                                              className="d-block"
                                              fontSize="14px"
                                              sx={{ opacity: 0.5 }}
                                            />
                                            <EditIcon
                                              onClick={() =>
                                                handleExerciseList(mainindex)
                                              }
                                              fontSize="12px"
                                              sx={{ opacity: 0.5 }}
                                            />
                                          </div>
                                        ) : (
                                          <AddIcon sx={{ opacity: 0.5 }} />
                                        )}
                                      </div>
                                    </InputAdornment>
                                  ),
                                  inputProps: {
                                    readOnly: true, // Disable editing
                                  },
                                }}
                                sx={{
                                  borderRadius: "5px",

                                  fontSize: "13px",
                                  width: "100%",
                                }}
                              /> */}
                            <Button
                              sx={{ textTransform: "inherit" }}
                              size="small"
                              color="primary"
                              variant="contained"
                              className="d-flex align-items-center ms-2 small-contained-dialog-button"
                              onClick={() => {
                                handleArrow(
                                  !data?.open ? true : false,
                                  mainindex
                                );
                              }}
                            >
                              {!data?.open ? (
                                <KeyboardArrowDownIcon
                                  sx={{
                                    cursor: "pointer",
                                    // opacity: 0.5,
                                    fontSize: "20px",
                                  }}
                                />
                              ) : (
                                <KeyboardArrowUpIcon
                                  sx={{
                                    cursor: "pointer",
                                    // opacity: 0.5,
                                    fontSize: "20px",
                                  }}
                                />
                              )}

                              <Typography
                                variant="body2"
                                sx={{
                                  // opacity: 0.6,
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {` Sets ${data?.set?.length}`}
                              </Typography>
                            </Button>
                          </div>

                          <div
                            className={`col-sm-12 ${
                              isHistory ? "col-md-6" : "col-md-5"
                            } col-lg-6 p-0 mt-2 mt-md-0 mt-lg-0 d-flex align-items-center justify-content-start `}
                          >
                            <div
                              className="d-flex align-items-center ms-3 set-parameters "
                              onClick={() => {
                                handleParameters(mainindex);
                              }}
                            >
                              <Icon icon="pajamas:labels" fontSize="10px" />
                              <Typography variant="body2" sx={{ ml: 0.5 }}>
                                {`Parameters`}
                              </Typography>
                            </div>
                            <div className="d-flex align-items-center ms-3 set-parameters ">
                              <Select
                                id="select-option"
                                variant="outlined"
                                size="small"
                                name="restType"
                                value={data?.type ? data?.type : "general"}
                                label="Weight Type"
                                inputProps={{
                                  style: {
                                    padding: 0,
                                  },
                                }}
                                onChange={(e) => {
                                  handleSelectExerciseType(e, mainindex);
                                }}
                                sx={{
                                  "& legend": {
                                    display: "none",
                                  },
                                  "& fieldset": {
                                    top: 0,
                                  },
                                  fontSize: "13px",
                                  border: "none",
                                  "& fieldset": {
                                    border: "none",
                                  },
                                  "& .MuiOutlinedInput-input": {
                                    padding: 0,
                                  },
                                  "& .MuiInputBase-inputSizeSmall": {
                                    color: `${get_root_value(
                                      "--portal-theme-primary"
                                    )} !important`,
                                  },
                                }}
                              >
                                <MenuItem value="general">General</MenuItem>
                                <MenuItem value="emom">EMOM</MenuItem>
                                <MenuItem value="amrap">AMRAP</MenuItem>
                                <MenuItem value="something_else">
                                  Something else
                                </MenuItem>
                                <MenuItem value="rounds_for_time">
                                  Rounds For Time
                                </MenuItem>
                                <MenuItem value="reps_for_time">
                                  Reps For Time
                                </MenuItem>
                              </Select>
                            </div>
                            {!isWithHistoryAndBigScreen &&
                            isHistory &&
                            data?.exercise ? (
                              <Button
                                sx={{ textTransform: "inherit" }}
                                size="small"
                                color="primary"
                                variant="contained"
                                className="d-flex align-items-center ms-2 small-contained-dialog-button"
                                onClick={() => {
                                  handleHistoryOpen(data, mainindex);
                                }}
                              >
                                <Icon icon="fa-solid:history" fontSize="10px" />
                                <Typography variant="body2" sx={{ ml: 0.5 }}>
                                  {`History`}
                                </Typography>
                              </Button>
                            ) : (
                              isWithHistoryAndBigScreen &&
                              data?.history &&
                              selectedExercise != data?.exercise?._id && (
                                <Button
                                  sx={{ textTransform: "inherit" }}
                                  size="small"
                                  color="primary"
                                  variant="contained"
                                  className="d-flex align-items-center ms-2 small-contained-dialog-button"
                                  onClick={() => {
                                    handleHistoryPreviousOpen(data);
                                  }}
                                >
                                  <Icon
                                    icon="fa-solid:history"
                                    fontSize="10px"
                                  />
                                  <Typography variant="body2" sx={{ ml: 0.5 }}>
                                    {`History`}
                                  </Typography>
                                </Button>
                              )
                            )}
                          </div>
                        </div>
                        {data?.open && (
                          <div className="mt-3 mb-4 d-flex justify-content-end">
                            <div
                              className="input-container"
                              onClick={handleClick}
                            >
                              {isEditable && (
                                <div
                                  className="close-icon"
                                  onClick={handleCloseClick}
                                >
                                  X
                                </div>
                              )}
                              <input
                                type="text"
                                value={
                                  isEditable
                                    ? staticNumber
                                    : "Add Multiple Sets"
                                }
                                readOnly={!isEditable}
                                onChange={(e) =>
                                  setStaticNumber(e.target.value)
                                }
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleAddMultipleSets(mainindex, data);
                                  }
                                }}
                                placeholder="How many more sets?"
                                className={`input ${
                                  isEditable ? "input-active" : "input-readonly"
                                }`}
                              />
                              <span
                                className={`icon ${
                                  isEditable ? "icon-moving" : "icon-left"
                                }`}
                                onClick={(e) => {
                                  if (isEditable) {
                                    e.stopPropagation();
                                    handleAddMultipleSets(mainindex, data);
                                  }
                                }}
                              >
                                +
                              </span>
                            </div>
                          </div>
                        )}

                        {data?.open &&
                          data?.set?.map((item, index1) => (
                            <div
                              className="d-flex align-items-center my-3 workout-card-inset"
                              id={`${index1},${mainindex}`}
                              onMouseEnter={() =>
                                handleMouseEnter(index1, mainindex)
                              }

                              // onMouseLeave={() => handleMouseLeave()}
                            >
                              {is_small_medium_screen() ? (
                                <div>
                                  {/* {!data.parameters.includes("Vdot") && ( */}
                                  <div
                                    className="icon-calendar-workout-small-screen"
                                    onMouseEnter={() =>
                                      handleMouseEnter(index1, mainindex)
                                    }
                                  >
                                    <Tooltip title="Add set">
                                      <AddIcon
                                        className="add-icon-calendar-workout me-1"
                                        onMouseEnter={() =>
                                          handleMouseEnter(index1, mainindex)
                                        }
                                        onClick={() =>
                                          handleAddSet(mainindex, index1)
                                        }
                                      />
                                    </Tooltip>
                                    <Tooltip
                                      title={`${
                                        data?.set.length > 1
                                          ? "Delete set"
                                          : "Can't be Deleted"
                                      }`}
                                    >
                                      <DeleteIcon
                                        onMouseEnter={() =>
                                          handleMouseEnter(index1, mainindex)
                                        }
                                        className={`${
                                          data?.set.length > 1
                                            ? "del-icon-calendar-workout-working"
                                            : "del-icon-calendar-workout-notwork"
                                        }`}
                                        onClick={
                                          data?.set.length > 1
                                            ? () =>
                                                handleDeleteSet(
                                                  mainindex,
                                                  index1
                                                )
                                            : () => {}
                                        }
                                      />
                                    </Tooltip>
                                  </div>
                                  {/* )} */}
                                </div>
                              ) : (
                                <div>
                                  {isHovered === index1 &&
                                    // !data.parameters.includes("Vdot") &&
                                    isHoveredExercise == mainindex && (
                                      <div
                                        className="icon-calendar-workout"
                                        onMouseEnter={() =>
                                          handleMouseEnter(index1, mainindex)
                                        }
                                      >
                                        <Tooltip title="Add set">
                                          <AddIcon
                                            className="add-icon-calendar-workout me-1"
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                index1,
                                                mainindex
                                              )
                                            }
                                            onClick={() =>
                                              handleAddSet(mainindex, index1)
                                            }
                                          />
                                        </Tooltip>
                                        <Tooltip
                                          title={`${
                                            data?.set.length > 1
                                              ? "Delete set"
                                              : "Can't be Deleted"
                                          }`}
                                        >
                                          <DeleteIcon
                                            onMouseEnter={() =>
                                              handleMouseEnter(
                                                index1,
                                                mainindex
                                              )
                                            }
                                            className={`${
                                              data?.set.length > 1
                                                ? "del-icon-calendar-workout-working"
                                                : "del-icon-calendar-workout-notwork"
                                            }`}
                                            onClick={
                                              data?.set.length > 1
                                                ? () =>
                                                    handleDeleteSet(
                                                      mainindex,
                                                      index1
                                                    )
                                                : () => {}
                                            }
                                          />
                                        </Tooltip>
                                      </div>
                                    )}
                                </div>
                              )}
                              {memberId &&
                              data.parameters.includes("Vdot") &&
                              !item?.vdot ? (
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                  justifyContent="start"
                                  sx={{
                                    padding: data.parameters.includes("Vdot")
                                      ? "9px 0px"
                                      : "",
                                    overflowX: "auto", // Add this line to enable horizontal scrolling
                                    "&::-webkit-scrollbar": {
                                      height: "5px", // Customize the scrollbar height
                                    },
                                    "&::-webkit-scrollbar-thumb": {
                                      backgroundColor: "#888", // Customize the scrollbar thumb color
                                      borderRadius: "5px", // Optional: Customize the thumb border radius
                                    },
                                    "&::-webkit-scrollbar-track": {
                                      backgroundColor: "#f1f1f1", // Optional: Customize the track color
                                    },
                                  }}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      opacity: 0.6,
                                      fontWeight: "600",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Auto Pacer is not calculated. Please{" "}
                                    <Link
                                      component={RouterLink}
                                      variant="subtitle2"
                                      to={`/member/edit-member/${memberId}`}
                                    >
                                      Click here
                                    </Link>{" "}
                                    to calculate it.
                                  </Typography>
                                </Stack>
                              ) : (
                                <div
                                  className="d-flex align-items-center w-100"
                                  onMouseEnter={() =>
                                    handleMouseEnter(index1, mainindex)
                                  }
                                >
                                  {!data.parameters.includes("Vdot") && (
                                    <div className="d-flex align-items-center">
                                      <Typography
                                        variant="body1"
                                        sx={{
                                          padding: "25px 4px",
                                          color: "inherit",
                                          backgroundColor: "#edeff1",
                                          height: "100%",
                                          width: "15px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          borderRadius: "7px",
                                        }}
                                      >
                                        {index1 + 1}
                                      </Typography>

                                      <CloseIcon sx={{ opacity: 0.3 }} />
                                    </div>
                                  )}
                                  <div className="row w-100">
                                    {data.parameters.includes("Vdot") && (
                                      <>
                                        {memberId && (
                                          <div
                                            className={
                                              isWithHistoryAndBigScreen
                                                ? "col-2 pe-0 py-1"
                                                : `col-12 col-md-2 col-lg-1 pe-0 py-1`
                                            }
                                          >
                                            <TextField
                                              id="outlined-basic"
                                              size="small"
                                              variant="outlined"
                                              placeholder="0"
                                              type="number"
                                              onMouseLeave={() =>
                                                handleMouseEnter(
                                                  index1,
                                                  mainindex
                                                )
                                              }
                                              onClick={() => handleMouseLeave()}
                                              label="Auto Pacer"
                                              value={item?.vdot}
                                              name="vdot"
                                              InputLabelProps={{
                                                className:
                                                  "textfiels-input-label",
                                                shrink: true,
                                                style: { fontSize: "14px" },
                                              }}
                                              InputProps={{
                                                className:
                                                  "textfiels-input-value",
                                                style: {
                                                  fontSize: "13px",
                                                  paddingLeft: "0px",
                                                  paddingRight: "5px",
                                                },
                                                readOnly: true,
                                              }}
                                              sx={{
                                                borderRadius: "5px",
                                                fontSize: "13px",
                                                width: "100%",
                                                marginTop: "5px",
                                              }}
                                            />
                                          </div>
                                        )}
                                        <div
                                          className={
                                            isWithHistoryAndBigScreen
                                              ? "col-5 pe-0 py-1"
                                              : `col-12 col-md-5 ${
                                                  isSidebar
                                                    ? "col-lg-4"
                                                    : "col-lg-3"
                                                } pe-0 py-1`
                                          }
                                        >
                                          <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            label="Type"
                                            InputLabelProps={{
                                              className:
                                                "textfiels-input-label",
                                              shrink: true,
                                              style: { fontSize: "14px" },
                                            }}
                                            InputProps={{
                                              className:
                                                "textfiels-input-value",
                                              style: {
                                                fontSize: "13px",
                                                paddingLeft: "0px",
                                                paddingRight: "0px",

                                                justifyContent: "center",
                                              },
                                              endAdornment: (
                                                <InputAdornment
                                                  position="end"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Select
                                                    id="select-option"
                                                    variant="outlined"
                                                    size="small"
                                                    name="race_type"
                                                    value={item?.race_type}
                                                    label="Weight Type"
                                                    inputProps={{
                                                      style: {
                                                        padding: 0,
                                                      },
                                                    }}
                                                    onChange={(e) => {
                                                      handleChangeVdotDistance(
                                                        e,
                                                        mainindex,
                                                        index1
                                                      );
                                                    }}
                                                    sx={{
                                                      "& legend": {
                                                        display: "none",
                                                      },
                                                      "& fieldset": {
                                                        top: 0,
                                                      },
                                                      fontSize: "13px",
                                                      border: "none",
                                                      "& fieldset": {
                                                        border: "none",
                                                      },
                                                      "& .MuiOutlinedInput-input":
                                                        {
                                                          padding: 0,
                                                        },
                                                    }}
                                                  >
                                                    <MenuItem value="el">
                                                      Easy/Long
                                                    </MenuItem>
                                                    <MenuItem value="c">
                                                      Compromised
                                                    </MenuItem>
                                                    <MenuItem value="t">
                                                      Threshold
                                                    </MenuItem>
                                                    <MenuItem value="i">
                                                      Interval
                                                    </MenuItem>
                                                    <MenuItem value="r">
                                                      Repitition
                                                    </MenuItem>
                                                  </Select>

                                                  <Select
                                                    id="select-option"
                                                    variant="outlined"
                                                    size="small"
                                                    name="pace_type"
                                                    value={item?.pace_type}
                                                    label="Weight Type"
                                                    inputProps={{
                                                      style: {
                                                        padding: 0,
                                                      },
                                                    }}
                                                    onChange={(e) => {
                                                      handleChangeVdotPaceType(
                                                        e,
                                                        mainindex,
                                                        index1
                                                      );
                                                    }}
                                                    sx={{
                                                      "& legend": {
                                                        display: "none",
                                                      },
                                                      "& fieldset": {
                                                        top: 0,
                                                      },
                                                      fontSize: "13px",
                                                      border: "none",
                                                      "& fieldset": {
                                                        border: "none",
                                                      },
                                                      "& .MuiOutlinedInput-input":
                                                        {
                                                          padding: 0,
                                                        },
                                                    }}
                                                  >
                                                    {item?.optionsType?.map(
                                                      (option) => {
                                                        return (
                                                          <MenuItem
                                                            key={option?.key}
                                                            value={option?.key}
                                                          >
                                                            {option?.title}
                                                          </MenuItem>
                                                        );
                                                      }
                                                    )}
                                                  </Select>
                                                </InputAdornment>
                                              ),
                                            }}
                                            sx={{
                                              borderRadius: "5px",
                                              fontSize: "13px",

                                              marginTop: "5px",
                                              "& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input":
                                                {
                                                  width: "0px !important",
                                                  padding: "9px 0px !important",
                                                },
                                              "& .css-1nuss9t": {
                                                width: "0px !important",
                                                padding: "9px 0px !important",
                                              },

                                              width: "100%",
                                            }}
                                          />
                                        </div>

                                        {memberId ? (
                                          item?.race_type == "el" ? (
                                            <div
                                              className={
                                                isWithHistoryAndBigScreen
                                                  ? "col-3 pe-0 py-1"
                                                  : `col-12 col-md-3 col-lg-2 pe-0 py-1`
                                              }
                                            >
                                              <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                label={getPaceText(
                                                  item?.pace_type
                                                )}
                                                InputLabelProps={{
                                                  className:
                                                    "textfiels-input-label",
                                                  shrink: true,
                                                  style: { fontSize: "14px" },
                                                }}
                                                InputProps={{
                                                  className:
                                                    "textfiels-input-value",
                                                  style: {
                                                    fontSize: "13px",
                                                    paddingLeft: "0px",
                                                    paddingRight: "0px",

                                                    justifyContent: "center",
                                                  },
                                                  readOnly: true,
                                                  endAdornment: (
                                                    <InputAdornment
                                                      position="end"
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                      }}
                                                    >
                                                      <TextField
                                                        id="outlined-basic-minutes"
                                                        type="number"
                                                        size="small"
                                                        placeholder="Min"
                                                        name="pace"
                                                        value={formatWithLeadingZeros(
                                                          Math.floor(
                                                            item?.pace / 60
                                                          )
                                                        )}
                                                        onChange={(e) =>
                                                          handleMinutesChange(
                                                            e,
                                                            mainindex,
                                                            index1
                                                          )
                                                        }
                                                        InputProps={{
                                                          className:
                                                            "textfiels-input-value",
                                                          style: {
                                                            fontSize: "14px",
                                                            // width: "40px",
                                                          },
                                                          readOnly: true,
                                                        }}
                                                        sx={{
                                                          "& fieldset": {
                                                            border: "none",
                                                          },
                                                          // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                          //   {
                                                          //     padding: "0px !important",
                                                          //   },
                                                          "& input": {
                                                            textAlign: "center",
                                                            padding: "0px",
                                                          },
                                                          width: "100%",
                                                        }}
                                                      />
                                                      <div className="fs-6 fw-semibold ms-1 ">
                                                        :
                                                      </div>

                                                      <TextField
                                                        id="outlined-basic-seconds"
                                                        type="number"
                                                        size="small"
                                                        placeholder="Sec"
                                                        name="pace"
                                                        value={formatWithLeadingZeros(
                                                          item?.pace % 60
                                                        )}
                                                        onChange={(e) =>
                                                          handleSecondsChange(
                                                            e,
                                                            mainindex,
                                                            index1
                                                          )
                                                        }
                                                        InputProps={{
                                                          className:
                                                            "textfiels-input-value",
                                                          style: {
                                                            fontSize: "14px",
                                                            // width: "40px",
                                                          },
                                                          readOnly: true,
                                                        }}
                                                        sx={{
                                                          "& fieldset": {
                                                            border: "none",
                                                          },
                                                          // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                          //   {
                                                          //     padding: "0px !important",
                                                          //   },
                                                          "& input": {
                                                            textAlign: "center",
                                                            padding: "0px",
                                                          },
                                                          width: "100%",
                                                        }}
                                                      />

                                                      <div className="fs-6 fw-semibold ms-1 me-1 ">
                                                        -
                                                      </div>

                                                      <TextField
                                                        id="outlined-basic-minutes"
                                                        type="number"
                                                        size="small"
                                                        placeholder="Min"
                                                        name="paceUp"
                                                        value={formatWithLeadingZeros(
                                                          Math.floor(
                                                            item?.paceUp / 60
                                                          )
                                                        )}
                                                        onChange={(e) =>
                                                          handleMinutesChange(
                                                            e,
                                                            mainindex,
                                                            index1
                                                          )
                                                        }
                                                        InputProps={{
                                                          className:
                                                            "textfiels-input-value",
                                                          style: {
                                                            fontSize: "14px",
                                                            // width: "40px",
                                                          },
                                                          readOnly: true,
                                                        }}
                                                        sx={{
                                                          "& fieldset": {
                                                            border: "none",
                                                          },
                                                          // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                          //   {
                                                          //     padding: "0px !important",
                                                          //   },
                                                          "& input": {
                                                            textAlign: "center",
                                                            padding: "0px",
                                                          },
                                                          width: "100%",
                                                        }}
                                                      />
                                                      <div className="fs-6 fw-semibold ms-1">
                                                        :
                                                      </div>
                                                      <TextField
                                                        id="outlined-basic-seconds"
                                                        type="number"
                                                        size="small"
                                                        placeholder="Sec"
                                                        name="paceUp"
                                                        value={formatWithLeadingZeros(
                                                          item?.paceUp % 60
                                                        )}
                                                        onChange={(e) =>
                                                          handleSecondsChange(
                                                            e,
                                                            mainindex,
                                                            index1
                                                          )
                                                        }
                                                        InputProps={{
                                                          className:
                                                            "textfiels-input-value",
                                                          style: {
                                                            fontSize: "14px",
                                                            // width: "40px",
                                                          },
                                                          readOnly: true,
                                                        }}
                                                        sx={{
                                                          "& fieldset": {
                                                            border: "none",
                                                          },
                                                          // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                          //   {
                                                          //     padding: "0px !important",
                                                          //   },
                                                          "& input": {
                                                            textAlign: "center",
                                                            padding: "0px",
                                                          },
                                                          width: "100%",
                                                          marginRight: 1,
                                                        }}
                                                      />
                                                    </InputAdornment>
                                                  ),
                                                }}
                                                sx={{
                                                  borderRadius: "5px",
                                                  fontSize: "13px",

                                                  marginTop: "5px",
                                                  "& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input":
                                                    {
                                                      width: "0px !important",
                                                      padding:
                                                        "9px 0px !important",
                                                    },
                                                  "& .css-1nuss9t": {
                                                    width: "0px !important",
                                                    padding:
                                                      "9px 0px !important",
                                                  },

                                                  width: "100%",
                                                }}
                                              />
                                            </div>
                                          ) : (
                                            <div
                                              className={
                                                isWithHistoryAndBigScreen
                                                  ? "col-2 pe-0 py-1"
                                                  : `col-12 col-md-2 col-lg-1 pe-0 py-1`
                                              }
                                            >
                                              <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                label={getPaceText(
                                                  item?.pace_type
                                                )}
                                                InputLabelProps={{
                                                  className:
                                                    "textfiels-input-label",
                                                  shrink: true,
                                                  style: { fontSize: "14px" },
                                                }}
                                                InputProps={{
                                                  className:
                                                    "textfiels-input-value",
                                                  style: {
                                                    fontSize: "13px",
                                                    paddingLeft: "0px",
                                                    paddingRight: "0px",

                                                    justifyContent: "center",
                                                  },
                                                  readOnly: true,
                                                  endAdornment: (
                                                    <InputAdornment
                                                      position="end"
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                      }}
                                                    >
                                                      <TextField
                                                        id="outlined-basic-minutes"
                                                        type="number"
                                                        size="small"
                                                        placeholder="Min"
                                                        name="pace"
                                                        value={formatWithLeadingZeros(
                                                          Math.floor(
                                                            item?.pace / 60
                                                          )
                                                        )}
                                                        onChange={(e) =>
                                                          handleMinutesChange(
                                                            e,
                                                            mainindex,
                                                            index1
                                                          )
                                                        }
                                                        InputProps={{
                                                          className:
                                                            "textfiels-input-value",
                                                          style: {
                                                            fontSize: "14px",
                                                            // width: "40px",
                                                          },
                                                          readOnly: true,
                                                        }}
                                                        sx={{
                                                          "& fieldset": {
                                                            border: "none",
                                                          },
                                                          // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                          //   {
                                                          //     padding: "0px !important",
                                                          //   },
                                                          "& input": {
                                                            textAlign: "center",
                                                            padding: "0px",
                                                          },
                                                          width: "100%",
                                                        }}
                                                      />
                                                      <div className="fs-6 fw-semibold ms-1 ">
                                                        :
                                                      </div>

                                                      <TextField
                                                        id="outlined-basic-seconds"
                                                        type="number"
                                                        size="small"
                                                        placeholder="Sec"
                                                        name="pace"
                                                        value={formatWithLeadingZeros(
                                                          item?.pace % 60
                                                        )}
                                                        onChange={(e) =>
                                                          handleSecondsChange(
                                                            e,
                                                            mainindex,
                                                            index1
                                                          )
                                                        }
                                                        InputProps={{
                                                          className:
                                                            "textfiels-input-value",
                                                          style: {
                                                            fontSize: "14px",
                                                            // width: "40px",
                                                          },
                                                          readOnly: true,
                                                        }}
                                                        sx={{
                                                          "& fieldset": {
                                                            border: "none",
                                                          },
                                                          // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                          //   {
                                                          //     padding: "0px !important",
                                                          //   },
                                                          "& input": {
                                                            textAlign: "center",
                                                            padding: "0px",
                                                          },
                                                          width: "100%",
                                                        }}
                                                      />
                                                    </InputAdornment>
                                                  ),
                                                }}
                                                sx={{
                                                  borderRadius: "5px",
                                                  fontSize: "13px",
                                                  "& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input":
                                                    {
                                                      width: "0px !important",
                                                      padding:
                                                        "9px 0px !important",
                                                    },
                                                  "& .css-1nuss9t": {
                                                    width: "0px !important",
                                                    padding:
                                                      "9px 0px !important",
                                                  },
                                                  // "& input": {
                                                  //   textAlign: "center",
                                                  //   padding: "0px",
                                                  // },
                                                  width: "100%",
                                                  // flexGrow: 1,
                                                  marginTop: "5px",
                                                }}
                                              />
                                            </div>
                                          )
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )}
                                    {data.parameters.includes("Pace") && (
                                      <>
                                        <div
                                          className={
                                            isWithHistoryAndBigScreen
                                              ? `${
                                                  item?.race_type == "t"
                                                    ? "col-4 "
                                                    : "col-3"
                                                } pe-0 py-1`
                                              : `col-12 col-md-3 ${
                                                  item?.race_type == "t"
                                                    ? "col-lg-3"
                                                    : "col-lg-2"
                                                }  pe-0 py-1`
                                          }
                                        >
                                          <FormControl
                                            fullWidth
                                            className="py-1"
                                          >
                                            <InputLabel
                                              className="set-Type-lable"
                                              sx={{ fontSize: "14px" }}
                                              id="demo-simple-select-label"
                                            >
                                              Type
                                            </InputLabel>
                                            <Select
                                              className="set-Type-value"
                                              id="select-option"
                                              variant="outlined"
                                              size="small"
                                              name="race_type"
                                              value={item?.race_type}
                                              label="Type"
                                              inputProps={{
                                                style: {
                                                  padding: 0,
                                                },
                                              }}
                                              onChange={(e) => {
                                                handleChangeVdotDistance(
                                                  e,
                                                  mainindex,
                                                  index1
                                                );
                                              }}
                                              sx={{
                                                width: "100%",
                                                // "& legend": {
                                                //   display: "none",
                                                // },
                                                // "& fieldset": {
                                                //   top: 0,
                                                // },
                                                fontSize: "13px",

                                                "& .MuiOutlinedInput-input": {
                                                  paddingLeft: 1,
                                                },
                                              }}
                                            >
                                              <MenuItem value="el">
                                                Easy/Long
                                              </MenuItem>
                                              <MenuItem value="c">
                                                Compromised
                                              </MenuItem>
                                              <MenuItem value="t">
                                                Threshold/Tempo
                                              </MenuItem>
                                              <MenuItem value="i">
                                                Interval
                                              </MenuItem>
                                              <MenuItem value="r">
                                                Repitition
                                              </MenuItem>
                                            </Select>
                                          </FormControl>
                                          {/* <TextField
                                              id="outlined-basic"
                                              variant="outlined"
                                              label="Type "
                                              InputLabelProps={{className:"textfiels-input-label",
                                                shrink: true,
                                                style: { fontSize: "14px" },
                                              }}
                                              InputProps={{className:"textfiels-input-value",
                                                style: {
                                                  fontSize: "13px",
                                                  paddingLeft: "0px",
                                                  paddingRight: "0px",

                                                  justifyContent: "end",
                                                },
                                                endAdornment: (
                                                  <InputAdornment
                                                    position="end"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <Select
                                                      id="select-option"
                                                      variant="outlined"
                                                      size="small"
                                                      name="race_type"
                                                      value={item?.race_type}
                                                      label="Weight Type"
                                                      inputProps={{
                                                        style: {
                                                          padding: 0,
                                                        },
                                                      }}
                                                      onChange={(e) => {
                                                        handleChangeVdotDistance(
                                                          e,
                                                          mainindex,
                                                          index1
                                                        );
                                                      }}
                                                      sx={{
                                                        "& legend": {
                                                          display: "none",
                                                        },
                                                        "& fieldset": {
                                                          top: 0,
                                                        },
                                                        fontSize: "13px",
                                                        border: "none",
                                                        "& fieldset": {
                                                          border: "none",
                                                        },
                                                        "& .MuiOutlinedInput-input":
                                                          {
                                                            padding: 0,
                                                          },
                                                      }}
                                                    >
                                                      <MenuItem value="el">
                                                        Easy/Long
                                                      </MenuItem>
                                                      <MenuItem value="c">
                                                        Compromised
                                                      </MenuItem>

                                                      <MenuItem value="i">
                                                        Interval
                                                      </MenuItem>
                                                      <MenuItem value="r">
                                                        Repitition
                                                      </MenuItem>
                                                    </Select>
                                                  </InputAdornment>
                                                ),
                                              }}
                                              sx={{
                                                borderRadius: "5px",
                                                fontSize: "13px",
                                                "& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input":
                                                  {
                                                    width: "0px !important",
                                                    padding:
                                                      "9px 0px !important",
                                                  },
                                                "& .css-1nuss9t": {
                                                  width: "0px !important",
                                                  padding: "9px 0px !important",
                                                },
                                                // "& input": {
                                                //   textAlign: "center",
                                                //   padding: "0px",
                                                // },
                                                width: "100%",
                                                // flexGrow: 1,
                                                marginTop: "5px",
                                              }}
                                            /> */}
                                        </div>
                                        <div
                                          className={
                                            isWithHistoryAndBigScreen
                                              ? "col-3 pe-0 py-1"
                                              : `col-12 col-md-3 col-lg-2 pe-0 py-1`
                                          }
                                        >
                                          <TextField
                                            sx={{
                                              borderRadius: "5px",
                                              // "& legend": { display: "none" },
                                              // "& fieldset": { top: 0 },
                                              paddingRight: 0,
                                              // width: "45%",
                                              width: "100%",
                                              // flexGrow: 1,
                                              marginTop: "5px",
                                            }}
                                            // className={classes.customInput}
                                            size="small"
                                            id="custom-input"
                                            variant="outlined"
                                            onMouseLeave={() =>
                                              handleMouseEnter(
                                                index1,
                                                mainindex
                                              )
                                            }
                                            onClick={() => handleMouseLeave()}
                                            placeholder={"Distance"}
                                            name="distance"
                                            label="Distance"
                                            value={item?.distance}
                                            onChange={(e) => {
                                              handleChange(
                                                e,
                                                mainindex,
                                                index1
                                              );
                                            }}
                                            type="number"
                                            InputLabelProps={{
                                              className:
                                                "textfiels-input-label",
                                              shrink: true,
                                              style: { fontSize: "14px" },
                                            }}
                                            InputProps={{
                                              className:
                                                "textfiels-input-value",
                                              style: {
                                                fontSize: "13px",
                                                paddingRight: 0,
                                              },
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <Select
                                                    id="select-option"
                                                    variant="outlined"
                                                    size="small"
                                                    name="distanceType"
                                                    value={item?.distanceType}
                                                    label="Weight Type"
                                                    onChange={(e) => {
                                                      handleChange(
                                                        e,
                                                        mainindex,
                                                        index1
                                                      );
                                                    }}
                                                    onMouseLeave={() =>
                                                      handleMouseEnter(
                                                        index1,
                                                        mainindex
                                                      )
                                                    }
                                                    onClick={() =>
                                                      handleMouseLeave()
                                                    }
                                                    sx={{
                                                      "& legend": {
                                                        display: "none",
                                                      },
                                                      "& fieldset": {
                                                        top: 0,
                                                      },
                                                      fontSize: "13px",
                                                      border: "none",
                                                      "& fieldset": {
                                                        border: "none",
                                                      },
                                                    }}
                                                  >
                                                    <MenuItem value="m">
                                                      Meter
                                                    </MenuItem>
                                                    <MenuItem value="km">
                                                      KM
                                                    </MenuItem>
                                                    <MenuItem value="mi">
                                                      Mile
                                                    </MenuItem>
                                                  </Select>
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                        </div>
                                        <div
                                          className={
                                            isWithHistoryAndBigScreen
                                              ? "col-3 pe-0 py-1"
                                              : `col-12 col-md-2 col-lg-2 pe-0 py-1`
                                          }
                                        >
                                          <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            label={getPaceTextinPaces(
                                              item?.distanceType
                                            )}
                                            InputLabelProps={{
                                              className:
                                                "textfiels-input-label",
                                              shrink: true,
                                              style: { fontSize: "14px" },
                                            }}
                                            InputProps={{
                                              className:
                                                "textfiels-input-value",
                                              style: {
                                                fontSize: "13px",
                                                paddingLeft: "0px",
                                                paddingRight: "0px",

                                                justifyContent: "center",
                                              },
                                              readOnly: true,
                                              endAdornment: (
                                                <InputAdornment
                                                  position="end"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <TextField
                                                    id="outlined-basic-minutes"
                                                    type="number"
                                                    size="small"
                                                    placeholder="Min"
                                                    name="pace"
                                                    value={formatWithLeadingZeros(
                                                      Math.floor(
                                                        item?.pace / 60
                                                      )
                                                    )}
                                                    onChange={(e) =>
                                                      handleMinutesChange(
                                                        e,
                                                        mainindex,
                                                        index1
                                                      )
                                                    }
                                                    InputProps={{
                                                      className:
                                                        "textfiels-input-value",
                                                      style: {
                                                        fontSize: "14px",
                                                        // width: "40px",
                                                      },
                                                      // readOnly: true,
                                                    }}
                                                    sx={{
                                                      "& fieldset": {
                                                        border: "none",
                                                      },
                                                      // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                      //   {
                                                      //     padding: "0px !important",
                                                      //   },
                                                      "& input": {
                                                        textAlign: "center",
                                                        padding: "0px",
                                                      },
                                                      width: "100%",
                                                    }}
                                                  />
                                                  <div className="fs-6 fw-semibold ms-1 ">
                                                    :
                                                  </div>

                                                  <TextField
                                                    id="outlined-basic-seconds"
                                                    type="number"
                                                    size="small"
                                                    placeholder="Sec"
                                                    name="pace"
                                                    value={formatWithLeadingZeros(
                                                      item?.pace % 60
                                                    )}
                                                    onChange={(e) =>
                                                      handleSecondsChange(
                                                        e,
                                                        mainindex,
                                                        index1
                                                      )
                                                    }
                                                    InputProps={{
                                                      className:
                                                        "textfiels-input-value",
                                                      style: {
                                                        fontSize: "14px",
                                                        // width: "40px",
                                                      },
                                                      // readOnly: true,
                                                    }}
                                                    sx={{
                                                      "& fieldset": {
                                                        border: "none",
                                                      },
                                                      // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                      //   {
                                                      //     padding: "0px !important",
                                                      //   },
                                                      "& input": {
                                                        textAlign: "center",
                                                        padding: "0px",
                                                      },
                                                      width: "100%",
                                                    }}
                                                  />
                                                </InputAdornment>
                                              ),
                                            }}
                                            sx={{
                                              borderRadius: "5px",
                                              fontSize: "13px",
                                              "& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input":
                                                {
                                                  width: "0px !important",
                                                  padding: "9px 0px !important",
                                                },
                                              "& .css-1nuss9t": {
                                                width: "0px !important",
                                                padding: "9px 0px !important",
                                              },
                                              // "& input": {
                                              //   textAlign: "center",
                                              //   padding: "0px",
                                              // },
                                              width: "100%",
                                              // flexGrow: 1,
                                              marginTop: "5px",
                                            }}
                                          />
                                        </div>

                                        <div
                                          style={{
                                            position: "absolute",
                                            bottom: "4px",
                                            left: "52px",
                                          }}
                                        >
                                          <Typography
                                            variant="subtitle2"
                                            sx={{
                                              opacity: 0.6,
                                              fontWeight: "600",
                                              fontSize: "12px",
                                            }}
                                          >{` @ Speed ${getKmhrForPace(
                                            item?.distance,
                                            item?.pace,
                                            item?.distanceType
                                          )} km/hr or ${getMihrForPace(
                                            item?.distance,
                                            item?.pace,
                                            item?.distanceType
                                          )} mi/hr`}</Typography>
                                        </div>
                                      </>
                                    )}
                                    {data.parameters.includes("Distance") && (
                                      <div
                                        className={
                                          isWithHistoryAndBigScreen
                                            ? "col-3 pe-0 py-1"
                                            : `col-12 col-md-3 col-lg-2 pe-0 py-1`
                                        }
                                      >
                                        <TextField
                                          sx={{
                                            borderRadius: "5px",
                                            // "& legend": { display: "none" },
                                            // "& fieldset": { top: 0 },
                                            paddingRight: 0,
                                            // width: "45%",
                                            width: "100%",
                                            // flexGrow: 1,
                                            marginTop: "5px",
                                          }}
                                          // className={classes.customInput}
                                          size="small"
                                          id="custom-input"
                                          variant="outlined"
                                          onMouseLeave={() =>
                                            handleMouseEnter(index1, mainindex)
                                          }
                                          onClick={() => handleMouseLeave()}
                                          placeholder={"Distance"}
                                          name="distance"
                                          label="Distance"
                                          value={item?.distance}
                                          onChange={(e) => {
                                            handleChange(e, mainindex, index1);
                                          }}
                                          type="number"
                                          InputLabelProps={{
                                            className: "textfiels-input-label",
                                            shrink: true,
                                            style: { fontSize: "14px" },
                                          }}
                                          InputProps={{
                                            className: "textfiels-input-value",
                                            style: {
                                              fontSize: "13px",
                                              paddingRight: 0,
                                            },
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <Select
                                                  id="select-option"
                                                  variant="outlined"
                                                  size="small"
                                                  name="distanceType"
                                                  value={item?.distanceType}
                                                  label="Weight Type"
                                                  onChange={(e) => {
                                                    handleChange(
                                                      e,
                                                      mainindex,
                                                      index1
                                                    );
                                                  }}
                                                  onMouseLeave={() =>
                                                    handleMouseEnter(
                                                      index1,
                                                      mainindex
                                                    )
                                                  }
                                                  onClick={() =>
                                                    handleMouseLeave()
                                                  }
                                                  sx={{
                                                    "& legend": {
                                                      display: "none",
                                                    },
                                                    "& fieldset": {
                                                      top: 0,
                                                    },
                                                    fontSize: "13px",
                                                    border: "none",
                                                    "& fieldset": {
                                                      border: "none",
                                                    },
                                                  }}
                                                >
                                                  <MenuItem value="m">
                                                    Meter
                                                  </MenuItem>
                                                  <MenuItem value="km">
                                                    KM
                                                  </MenuItem>
                                                  <MenuItem value="mi">
                                                    Mile
                                                  </MenuItem>
                                                </Select>
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      </div>
                                    )}
                                    {(data.parameters.includes(
                                      "Duration/Pace"
                                    ) ||
                                      data.parameters.includes("Time")) && (
                                      <div
                                        className={
                                          isWithHistoryAndBigScreen
                                            ? "col-3 pe-0 py-1"
                                            : `col-12 col-md-3 col-lg-2 pe-0 py-1`
                                        }
                                      >
                                        <TextField
                                          id="outlined-basic"
                                          size="small"
                                          variant="outlined"
                                          onMouseLeave={() =>
                                            handleMouseEnter(index1, mainindex)
                                          }
                                          onClick={() => handleMouseLeave()}
                                          placeholder="0"
                                          type="number"
                                          value={item?.time}
                                          name="time"
                                          onChange={(e) => {
                                            handleChange(e, mainindex, index1);
                                          }}
                                          InputLabelProps={{
                                            className: "textfiels-input-label",
                                            shrink: true,
                                            style: { fontSize: "14px" },
                                          }}
                                          label={
                                            data.parameters.includes(
                                              "Duration/Pace"
                                            )
                                              ? "Duration/Pace"
                                              : "Time"
                                          }
                                          InputProps={{
                                            className: "textfiels-input-value",
                                            style: {
                                              fontSize: "13px",
                                              paddingRight: 0,
                                            },
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <Select
                                                  id="select-option"
                                                  variant="outlined"
                                                  size="small"
                                                  name="timeType"
                                                  value={item?.timeType}
                                                  label="Weight Type"
                                                  inputProps={{
                                                    style: {
                                                      padding: 0,
                                                    },
                                                  }}
                                                  onChange={(e) => {
                                                    handleChange(
                                                      e,
                                                      mainindex,
                                                      index1
                                                    );
                                                  }}
                                                  sx={{
                                                    "& legend": {
                                                      display: "none",
                                                    },
                                                    "& fieldset": {
                                                      top: 0,
                                                    },
                                                    fontSize: "13px",
                                                    border: "none",
                                                    "& fieldset": {
                                                      border: "none",
                                                    },
                                                    "& .MuiOutlinedInput-input":
                                                      {
                                                        padding: 0,
                                                      },
                                                  }}
                                                >
                                                  <MenuItem value="sec">
                                                    sec
                                                  </MenuItem>
                                                  <MenuItem value="min">
                                                    min
                                                  </MenuItem>
                                                </Select>
                                              </InputAdornment>
                                            ),
                                          }}
                                          sx={{
                                            borderRadius: "5px",
                                            fontSize: "13px",
                                            width: "100%",
                                            marginTop: "5px",
                                          }}
                                        />
                                      </div>
                                    )}
                                    {data.parameters.includes("Reps") && (
                                      <div
                                        className={
                                          isWithHistoryAndBigScreen
                                            ? "col-2 pe-0 py-1"
                                            : "col-12 col-md-2 col-lg-1 pe-0 py-1"
                                        }
                                      >
                                        <TextField
                                          id="outlined-basic"
                                          size="small"
                                          variant="outlined"
                                          placeholder="Reps"
                                          type="number"
                                          onMouseLeave={() =>
                                            handleMouseEnter(index1, mainindex)
                                          }
                                          onClick={() => handleMouseLeave()}
                                          label="Reps"
                                          value={item?.reps}
                                          name="reps"
                                          onChange={(e) => {
                                            handleChange(e, mainindex, index1);
                                          }}
                                          InputLabelProps={{
                                            className: "textfiels-input-label",
                                            shrink: true,
                                            style: { fontSize: "14px" },
                                          }}
                                          InputProps={{
                                            className: "textfiels-input-value",
                                            style: { fontSize: "13px" },
                                          }}
                                          sx={{
                                            borderRadius: "5px",
                                            fontSize: "13px",
                                            width: "100%",
                                            marginTop: "5px",
                                          }}
                                        />
                                      </div>
                                    )}
                                    {data.parameters.includes("Max_Reps") && (
                                      <div
                                        className={
                                          isWithHistoryAndBigScreen
                                            ? "col-2 pe-0 py-1"
                                            : "col-12 col-md-2 col-lg-1 pe-0 py-1"
                                        }
                                      >
                                        <TextField
                                          id="outlined-basic"
                                          size="small"
                                          variant="outlined"
                                          placeholder="Max Reps"
                                          type="number"
                                          onMouseLeave={() =>
                                            handleMouseEnter(index1, mainindex)
                                          }
                                          onClick={() => handleMouseLeave()}
                                          label="Max Reps"
                                          value={item?.maxReps}
                                          name="maxReps"
                                          onChange={(e) => {
                                            handleChange(e, mainindex, index1);
                                          }}
                                          InputLabelProps={{
                                            className: "textfiels-input-label",
                                            shrink: true,
                                            style: { fontSize: "14px" },
                                          }}
                                          InputProps={{
                                            className: "textfiels-input-value",
                                            style: { fontSize: "13px" },
                                          }}
                                          sx={{
                                            borderRadius: "5px",
                                            fontSize: "13px",
                                            width: "100%",
                                            marginTop: "5px",
                                          }}
                                        />
                                      </div>
                                    )}
                                    {data.parameters.includes("Height") && (
                                      <div
                                        className={
                                          isWithHistoryAndBigScreen
                                            ? "col-3 pe-0 py-1"
                                            : "col-12 col-md-3 col-lg-2 pe-0 py-2"
                                        }
                                      >
                                        <TextField
                                          id="outlined-basic"
                                          size="small"
                                          variant="outlined"
                                          placeholder="0"
                                          type="number"
                                          value={item?.height}
                                          name="height"
                                          onChange={(e) => {
                                            handleChange(e, mainindex, index1);
                                          }}
                                          InputLabelProps={{
                                            className: "textfiels-input-label",
                                            shrink: true,
                                            style: {
                                              fontSize: "14px",
                                            },
                                          }}
                                          label="Height"
                                          InputProps={{
                                            className: "textfiels-input-value",
                                            style: {
                                              fontSize: "13px",
                                              paddingRight: 0,
                                            },
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <Select
                                                  id="select-option"
                                                  variant="outlined"
                                                  size="small"
                                                  name="heightType"
                                                  value={item?.heightType}
                                                  label="Height Type"
                                                  inputProps={{
                                                    style: {
                                                      padding: 0,
                                                    },
                                                  }}
                                                  onChange={(e) => {
                                                    handleChange(
                                                      e,
                                                      mainindex,
                                                      index1
                                                    );
                                                  }}
                                                  sx={{
                                                    "& legend": {
                                                      display: "none",
                                                    },
                                                    "& fieldset": {
                                                      top: 0,
                                                    },
                                                    fontSize: "13px",
                                                    border: "none",
                                                    "& fieldset": {
                                                      border: "none",
                                                    },
                                                    "& .MuiOutlinedInput-input":
                                                      {
                                                        padding: 0,
                                                      },
                                                  }}
                                                >
                                                  <MenuItem value="cm">
                                                    Cm
                                                  </MenuItem>
                                                  <MenuItem value="ft">
                                                    Feet
                                                  </MenuItem>
                                                  <MenuItem value="inch">
                                                    Inch
                                                  </MenuItem>
                                                </Select>
                                              </InputAdornment>
                                            ),
                                          }}
                                          sx={{
                                            borderRadius: "5px",
                                            fontSize: "13px",
                                            width: "100%",
                                          }}
                                        />
                                      </div>
                                    )}
                                    {data.parameters.includes("Weight") && (
                                      <div
                                        className={
                                          isWithHistoryAndBigScreen
                                            ? "col-3 pe-0 py-1"
                                            : "col-12 col-md-4 col-lg-2 pe-0 py-1"
                                        }
                                      >
                                        <TextField
                                          sx={{
                                            borderRadius: "5px",
                                            paddingRight: 0,
                                            width: "100%",
                                            marginTop: "5px",
                                          }}
                                          size="small"
                                          id="custom-input"
                                          variant="outlined"
                                          onMouseLeave={() =>
                                            handleMouseEnter(index1, mainindex)
                                          }
                                          onClick={() => handleMouseLeave()}
                                          placeholder="Weight"
                                          name="weight"
                                          label="Weight"
                                          value={item.weight}
                                          onChange={(e) => {
                                            handleChange(e, mainindex, index1);
                                          }}
                                          type="number"
                                          InputLabelProps={{
                                            className: "textfiels-input-label",
                                            shrink: true,
                                            style: { fontSize: "14px" },
                                          }}
                                          InputProps={{
                                            className: "textfiels-input-value",
                                            style: {
                                              fontSize: "13px",
                                              paddingRight: 0,
                                            },
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <Select
                                                  id="select-option"
                                                  variant="outlined"
                                                  size="small"
                                                  name="weightType"
                                                  value={item?.weightType}
                                                  label="Weight Type"
                                                  onChange={(e) => {
                                                    handleChange(
                                                      e,
                                                      mainindex,
                                                      index1
                                                    );
                                                  }}
                                                  onMouseLeave={() =>
                                                    handleMouseEnter(
                                                      index1,
                                                      mainindex
                                                    )
                                                  }
                                                  onClick={() =>
                                                    handleMouseLeave()
                                                  }
                                                  sx={{
                                                    "& legend": {
                                                      display: "none",
                                                    },
                                                    "& fieldset": { top: 0 },
                                                    fontSize: "13px",
                                                    border: "none",
                                                    "& fieldset": {
                                                      border: "none",
                                                    },
                                                    "& .MuiOutlinedInput-input":
                                                      {
                                                        padding: 0,
                                                      },
                                                  }}
                                                >
                                                  <MenuItem value="1rm">
                                                    % 1RM
                                                  </MenuItem>
                                                  <MenuItem value="body_weight">
                                                    % BW
                                                  </MenuItem>
                                                  <MenuItem value="kg">
                                                    KG
                                                  </MenuItem>
                                                  <MenuItem value="lb">
                                                    Pound
                                                  </MenuItem>
                                                  <MenuItem value="poods">
                                                    Poods
                                                  </MenuItem>
                                                </Select>
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      </div>
                                    )}
                                    {data.parameters.includes(
                                      "Distance/Calories"
                                    ) && (
                                      <div
                                        className={
                                          isWithHistoryAndBigScreen
                                            ? "col-3 pe-0 py-1"
                                            : "col-12 col-md-3 col-lg-2 pe-0 py-1"
                                        }
                                      >
                                        <TextField
                                          sx={{
                                            borderRadius: "5px",
                                            paddingRight: 0,
                                            width: "100%",
                                            marginTop: "5px",
                                          }}
                                          size="small"
                                          id="custom-input"
                                          variant="outlined"
                                          onMouseLeave={() =>
                                            handleMouseEnter(index1, mainindex)
                                          }
                                          onClick={() => handleMouseLeave()}
                                          placeholder={
                                            item?.distanceType == "calories"
                                              ? "Calories"
                                              : "Distance"
                                          }
                                          name="distance"
                                          label="Distance/Calories"
                                          value={item?.distance}
                                          onChange={(e) => {
                                            handleChange(e, mainindex, index1);
                                          }}
                                          type="number"
                                          InputLabelProps={{
                                            className: "textfiels-input-label",
                                            shrink: true,
                                            style: { fontSize: "14px" },
                                          }}
                                          InputProps={{
                                            className: "textfiels-input-value",
                                            style: {
                                              fontSize: "13px",
                                              paddingRight: 0,
                                            },
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <Select
                                                  id="select-option"
                                                  variant="outlined"
                                                  size="small"
                                                  name="distanceType"
                                                  value={item?.distanceType}
                                                  label="Weight Type"
                                                  onChange={(e) => {
                                                    handleChange(
                                                      e,
                                                      mainindex,
                                                      index1
                                                    );
                                                  }}
                                                  onMouseLeave={() =>
                                                    handleMouseEnter(
                                                      index1,
                                                      mainindex
                                                    )
                                                  }
                                                  onClick={() =>
                                                    handleMouseLeave()
                                                  }
                                                  sx={{
                                                    "& legend": {
                                                      display: "none",
                                                    },
                                                    "& fieldset": { top: 0 },
                                                    fontSize: "13px",
                                                    border: "none",
                                                    "& fieldset": {
                                                      border: "none",
                                                    },
                                                    "& .MuiOutlinedInput-input":
                                                      {
                                                        padding: 0,
                                                      },
                                                  }}
                                                >
                                                  <MenuItem value="m">
                                                    Meter
                                                  </MenuItem>
                                                  <MenuItem value="km">
                                                    KM
                                                  </MenuItem>
                                                  <MenuItem value="mi">
                                                    Mile
                                                  </MenuItem>
                                                  <MenuItem value="calories">
                                                    Calories
                                                  </MenuItem>
                                                </Select>
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      </div>
                                    )}
                                    {data.parameters.includes("%MHR") && (
                                      <div
                                        className={
                                          isWithHistoryAndBigScreen
                                            ? "col-2 pe-0 py-1"
                                            : "col-12 col-md-2 col-lg-1 pe-0 py-1"
                                        }
                                      >
                                        <TextField
                                          id="outlined-basic"
                                          size="small"
                                          variant="outlined"
                                          placeholder="%MHR"
                                          type="number"
                                          label="%MHR"
                                          value={item?.mhr}
                                          name="mhr"
                                          onChange={(e) => {
                                            handleChangeTempo(
                                              e,
                                              mainindex,
                                              index1
                                            );
                                          }}
                                          InputLabelProps={{
                                            className: "textfiels-input-label",
                                            shrink: true,
                                            style: { fontSize: "14px" },
                                          }}
                                          InputProps={{
                                            className: "textfiels-input-value",
                                            style: {
                                              fontSize: "13px",
                                            },
                                          }}
                                          sx={{
                                            borderRadius: "5px",

                                            fontSize: "13px",

                                            width: "100%",

                                            marginTop: "5px",

                                            "& .MuiOutlinedInput-input": {
                                              padding: "8px 7px",
                                            },
                                          }}
                                        />
                                      </div>
                                    )}
                                    {data.parameters.includes("Tempo") && (
                                      <div
                                        className={
                                          isWithHistoryAndBigScreen
                                            ? "col-2 pe-0 py-1"
                                            : "col-12 col-md-2 col-lg-1 pe-0 py-1"
                                        }
                                      >
                                        <TextField
                                          id="outlined-basic"
                                          size="small"
                                          variant="outlined"
                                          placeholder="Tempo"
                                          type="number"
                                          label="Tempo"
                                          value={item?.tempo}
                                          name="tempo"
                                          onChange={(e) => {
                                            handleChangeTempo(
                                              e,
                                              mainindex,
                                              index1
                                            );
                                          }}
                                          InputLabelProps={{
                                            className: "textfiels-input-label",
                                            shrink: true,
                                            style: { fontSize: "14px" },
                                          }}
                                          InputProps={{
                                            className: "textfiels-input-value",
                                            style: {
                                              fontSize: "13px",
                                            },
                                          }}
                                          sx={{
                                            borderRadius: "5px",

                                            fontSize: "13px",

                                            width: "100%",

                                            marginTop: "5px",

                                            "& .MuiOutlinedInput-input": {
                                              padding: "8px 7px",
                                            },
                                          }}
                                        />
                                      </div>
                                    )}
                                    {data.parameters.includes("Rest") && (
                                      <div
                                        className={
                                          isWithHistoryAndBigScreen
                                            ? "col-3 pe-0 py-1"
                                            : "col-12 col-md-3 col-lg-2 pe-0 py-1"
                                        }
                                      >
                                        <TextField
                                          id="outlined-basic"
                                          size="small"
                                          variant="outlined"
                                          placeholder="0"
                                          type="number"
                                          value={item?.rest}
                                          name="rest"
                                          onChange={(e) => {
                                            handleChange(e, mainindex, index1);
                                          }}
                                          onMouseLeave={() =>
                                            handleMouseEnter(index1, mainindex)
                                          }
                                          onClick={() => handleMouseLeave()}
                                          InputLabelProps={{
                                            className: "textfiels-input-label",
                                            shrink: true,
                                            style: { fontSize: "14px" },
                                          }}
                                          label="Rest"
                                          InputProps={{
                                            className: "textfiels-input-value",
                                            style: {
                                              fontSize: "13px",
                                              paddingRight: 0,
                                            },
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <Select
                                                  id="select-option"
                                                  variant="outlined"
                                                  size="small"
                                                  name="restType"
                                                  value={item?.restType}
                                                  label="Weight Type"
                                                  inputProps={{
                                                    style: {
                                                      padding: 0,
                                                    },
                                                  }}
                                                  onChange={(e) => {
                                                    handleChange(
                                                      e,
                                                      mainindex,
                                                      index1
                                                    );
                                                  }}
                                                  sx={{
                                                    "& legend": {
                                                      display: "none",
                                                    },
                                                    "& fieldset": {
                                                      top: 0,
                                                    },
                                                    fontSize: "13px",
                                                    border: "none",
                                                    "& fieldset": {
                                                      border: "none",
                                                    },
                                                    "& .MuiOutlinedInput-input":
                                                      {
                                                        padding: 0,
                                                      },
                                                  }}
                                                >
                                                  <MenuItem value="sec">
                                                    sec
                                                  </MenuItem>
                                                  <MenuItem value="min">
                                                    min
                                                  </MenuItem>
                                                </Select>
                                              </InputAdornment>
                                            ),
                                          }}
                                          sx={{
                                            borderRadius: "5px",

                                            marginTop: "5px",
                                            fontSize: "13px",

                                            width: "100%",
                                          }}
                                        />
                                      </div>
                                    )}
                                    {memberId &&
                                      data.parameters.includes("Vdot") && (
                                        <div
                                        // style={{
                                        //   position: "absolute",
                                        //   bottom: "4px",
                                        //   left: "5px",
                                        // }}
                                        >
                                          {item?.race_type == "el" ? (
                                            <Typography
                                              variant="subtitle2"
                                              sx={{
                                                opacity: 0.6,
                                                fontWeight: "600",
                                                fontSize: "12px",
                                              }}
                                            >{` @ Speed ${getKmhr(
                                              item?.pace,
                                              item?.pace_type
                                            )} km/hr or ${getMihr(
                                              item?.pace,
                                              item?.pace_type
                                            )} mi/hr -  ${getKmhr(
                                              item?.paceUp,
                                              item?.pace_type
                                            )} km/hr or ${getMihr(
                                              item?.paceUp,
                                              item?.pace_type
                                            )} mi/hr`}</Typography>
                                          ) : (
                                            <Typography
                                              variant="subtitle2"
                                              sx={{
                                                opacity: 0.6,
                                                fontWeight: "600",
                                                fontSize: "12px",
                                              }}
                                            >{` @ Speed ${getKmhr(
                                              item?.pace,
                                              item?.pace_type
                                            )} km/hr or ${getMihr(
                                              item?.pace,
                                              item?.pace_type
                                            )} mi/hr`}</Typography>
                                          )}
                                        </div>
                                      )}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        {data?.open && (
                          <TextField
                            id="outlined-basic"
                            size="small"
                            variant="outlined"
                            placeholder="Instructions"
                            label="Instructions"
                            type="number"
                            value={data.description}
                            name="description"
                            multiline
                            rows={2}
                            onChange={(e) => handleChangeDes(e, mainindex)}
                            InputLabelProps={{
                              className: "textfiels-input-label",
                              shrink: true,
                              style: { fontSize: "14px" },
                            }}
                            InputProps={{
                              className: "textfiels-input-value",
                              style: { fontSize: "14px" },
                            }}
                            sx={{
                              borderRadius: "5px",
                              // "& legend": { display: "none" },
                              // "& fieldset": { top: 0 },

                              mt: 2,
                              width: "100%",
                            }}
                          />
                        )}
                        {data?.open && (
                          <div className="row mt-3 d-flex justify-content-center">
                            <div className="col-md-2 col-lg-1 col-3">
                              <button
                                onClick={(e) => {
                                  e.preventDefault();

                                  handleArrow(false, mainindex);
                                }}
                                className="w-100 small-contained-set-button"
                              >
                                <KeyboardArrowUpIcon
                                  sx={{
                                    cursor: "pointer",
                                    opacity: 0.5,
                                    fontSize: "20px",
                                  }}
                                  onClick={() => {
                                    handleArrow(false, mainindex);
                                  }}
                                />
                              </button>
                            </div>
                          </div>
                        )}
                      </Box>
                    </div>
                    <span
                      className="cross-icon"
                      style={{
                        position: "absolute",
                        bottom: "-12px",
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        zIndex: 1,
                      }}
                    >
                      {addArray.length > 1 &&
                      mainindex < addArray.length - 1 &&
                      // !addArray[mainindex + 1].parameters.includes("Vdot") &&
                      // !addArray[mainindex].parameters.includes("Vdot") &&
                      addArray[mainindex + 1]?.type == "general" ? (
                        <div className="">
                          <SyncIcon
                            onClick={() => handleAgreeExercise(mainindex)}
                            className="sync-icon-add"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                )}
              </>
            );
          })}
        </form>
      </div>
    </div>
  );
};
export default WorkoutExercisesUI;
