import React from "react";
import DisplaySet from "src/pages/Banner/component/DisplaySet";
import ReactVideoPlayer from "src/components/ReactVideoPlayer/ReactVideoPlayer";
import { Card, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import { useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const WorkoutDetail = ({ selectedPracticeTypeOption, selectedDetail }) => {
  const [showDetails, setShowDetails] = useState([]);

  const toggleDetails = (index) => {
    setShowDetails((prev) => {
      if (prev.includes(index)) {
        return prev.filter((section) => section !== index);
      } else {
        return [...prev, index];
      }
    });
  };
  return (
    <>
      {selectedPracticeTypeOption == "workout" ? (
        <>
          {selectedDetail?.exercises?.length > 0 && (
            <>
              {selectedDetail?.exercises.map((exercise, index) => {
                return (
                  <div
                    className="col-lg-4 exercise_detail col-sm-12 mt-2"
                    key={index}
                  >
                    <div className="text-start">
                      {exercise.exercise_type === "something_else" && (
                        <>
                          <div
                            className="text-center"
                            style={{
                              color: "#04abbb",
                            }}
                          >
                            <Icon icon="material-symbols:exercise-outline-sharp" />{" "}
                            Something Else
                          </div>
                          <Card className="exercise-card mb-1 d-flex align-items-center">
                            <div>
                              <Icon
                                icon="ph:tag-simple-fill"
                                className="ms-1"
                              />
                            </div>
                            <div>
                              <div className="px-2">
                                <Typography variant="subtitle2" component="div">
                                  {exercise.title}
                                </Typography>
                              </div>
                            </div>
                          </Card>
                        </>
                      )}
                      {exercise.exercise_type === "amrap" &&
                        exercise.emom_amrap &&
                        exercise.emom_amrap.length > 0 && (
                          <div>
                            <div
                              className="text-center"
                              style={{ color: "#04abbb" }}
                            >
                              <Icon icon="material-symbols:exercise-outline-sharp" />{" "}
                              AMRAP
                            </div>
                            {exercise.emom_amrap.map(
                              (interval, intervalIndex) => (
                                <div key={intervalIndex}>
                                  {interval.map((exerData) => (
                                    <div key={exerData.id}>
                                      <Card className="exercise-card my-1 d-flex align-items-center ">
                                        <div>
                                          <Icon
                                            icon="ph:tag-simple-fill"
                                            className="ms-1"
                                          />
                                        </div>
                                        <div>
                                          <div className="d-flex justify-content-center"></div>
                                          <div className="px-2">
                                            <Typography
                                              variant="subtitle2"
                                              component="div"
                                            >
                                              {exerData.exercise_info.title}
                                            </Typography>
                                            <Typography
                                              variant="subtitle2"
                                              sx={{ fontSize: 10 }}
                                            >
                                              <DisplaySet
                                                sets_array={exerData?.sets}
                                                parameters={
                                                  exerData?.parameters
                                                }
                                              />
                                            </Typography>
                                          </div>
                                        </div>
                                      </Card>
                                    </div>
                                  ))}
                                </div>
                              )
                            )}
                          </div>
                        )}
                      {exercise.exercise_type === "reps_for_time" &&
                        exercise.emom_amrap &&
                        exercise.emom_amrap.length > 0 && (
                          <div>
                            <div
                              className="text-center"
                              style={{ color: "#04abbb" }}
                            >
                              <Icon icon="material-symbols:exercise-outline-sharp" />{" "}
                              Reps For Time
                            </div>
                            {exercise.emom_amrap.map(
                              (interval, intervalIndex) => (
                                <div key={intervalIndex}>
                                  <div
                                    style={{
                                      fontSize: "11px",
                                      color: "#04abbb",
                                    }}
                                  >
                                    Movements
                                  </div>
                                  {interval.map((exerData) => (
                                    <div key={exerData.id}>
                                      <Card className="exercise-card my-1 d-flex align-items-center ">
                                        <div>
                                          <Icon
                                            icon="ph:tag-simple-fill"
                                            className="ms-1"
                                          />
                                        </div>
                                        <div>
                                          <div className="d-flex justify-content-center"></div>
                                          <div className="px-2">
                                            <Typography
                                              variant="subtitle2"
                                              component="div"
                                            >
                                              {exerData.exercise_info.title}
                                            </Typography>
                                            <Typography
                                              variant="subtitle2"
                                              sx={{ fontSize: 10 }}
                                            >
                                              <DisplaySet
                                                type={"reps_for_time"}
                                                sets_array={exerData?.sets}
                                                parameters={
                                                  exerData?.parameters
                                                }
                                              />
                                            </Typography>
                                          </div>
                                        </div>
                                      </Card>
                                    </div>
                                  ))}
                                </div>
                              )
                            )}
                          </div>
                        )}
                      {exercise.exercise_type === "rounds_for_time" &&
                        exercise.emom_amrap &&
                        exercise.emom_amrap.length > 0 && (
                          <div>
                            <div
                              className="text-center"
                              style={{ color: "#04abbb" }}
                            >
                              <Icon icon="material-symbols:exercise-outline-sharp" />{" "}
                              Rounds For Time
                            </div>
                            {exercise.emom_amrap.map(
                              (interval, intervalIndex) => (
                                <div key={intervalIndex}>
                                  <div
                                    style={{
                                      fontSize: "11px",
                                      color: "#04abbb",
                                    }}
                                  >
                                    Movements
                                  </div>
                                  {interval.map((exerData) => (
                                    <div key={exerData.id}>
                                      <Card className="exercise-card my-1 d-flex align-items-center ">
                                        <div>
                                          <Icon
                                            icon="ph:tag-simple-fill"
                                            className="ms-1"
                                          />
                                        </div>
                                        <div>
                                          <div className="d-flex justify-content-center"></div>
                                          <div className="px-2">
                                            <Typography
                                              variant="subtitle2"
                                              component="div"
                                            >
                                              {exerData.exercise_info.title}
                                            </Typography>
                                            <Typography
                                              variant="subtitle2"
                                              sx={{ fontSize: 10 }}
                                            >
                                              <DisplaySet
                                                sets_array={exerData?.sets}
                                                parameters={
                                                  exerData?.parameters
                                                }
                                              />
                                            </Typography>
                                          </div>
                                        </div>
                                      </Card>
                                    </div>
                                  ))}
                                </div>
                              )
                            )}
                          </div>
                        )}
                      {exercise.exercise_type === "emom" &&
                        exercise.emom_amrap &&
                        exercise.emom_amrap.length > 0 && (
                          <div>
                            <h4
                              className="text-center"
                              style={{ color: "#04abbb" }}
                            >
                              <Icon icon="material-symbols:exercise-outline-sharp" />{" "}
                              EMOM
                            </h4>
                            {exercise?.emom_amrap?.map(
                              (interval, intervalIndex) => {
                                return (
                                  <div className="my-1">
                                    {interval?.map((exerData) => {
                                      return (
                                        <div key={exerData.id}>
                                          <Card className="exercise-card my-1 d-flex align-items-center">
                                            <div>
                                              <Icon
                                                icon="ph:tag-simple-fill"
                                                className="ms-1"
                                              />
                                            </div>
                                            <div>
                                              <div className="px-2">
                                                <Typography
                                                  variant="subtitle2"
                                                  component="div"
                                                >
                                                  {exerData.exercise_info.title}
                                                </Typography>
                                                <Typography
                                                  variant="subtitle2"
                                                  sx={{ fontSize: 10 }}
                                                >
                                                  <DisplaySet
                                                    sets_array={exerData?.sets}
                                                    parameters={
                                                      exerData?.parameters
                                                    }
                                                  />
                                                </Typography>
                                              </div>
                                            </div>
                                          </Card>
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        )}
                      {exercise.sub_exercises &&
                        exercise.sub_exercises.length > 0 && (
                          <div>
                            <div
                              className="text-center"
                              style={{
                                color: "#04abbb",
                              }}
                            >
                              <Icon icon="material-symbols:exercise-outline-sharp" />{" "}
                              Superset
                            </div>
                            {exercise.sub_exercises.map((exerData) => (
                              <div key={exerData.id}>
                                <Card className="exercise-card mb-1 d-flex align-items-center">
                                  <div>
                                    <Icon
                                      icon="ph:tag-simple-fill"
                                      className="ms-1"
                                    />
                                  </div>

                                  <div>
                                    <div className="px-2">
                                      <Typography
                                        variant="subtitle2"
                                        component="div"
                                        className="d-flex"
                                      >
                                        {exerData.exercise_info.title}
                                        {exerData?.sets?.length > 1 && (
                                          <div
                                            className="ms-2 detail_open_icon"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              toggleDetails(index);
                                            }}
                                          >
                                            {showDetails.includes(index) ? (
                                              <>
                                                <ExpandLessIcon /> {"Sets"}{" "}
                                                {exerData?.sets?.length}
                                              </>
                                            ) : (
                                              <>
                                                <ExpandMoreIcon /> {"Sets"}{" "}
                                                {exerData?.sets?.length}
                                              </>
                                            )}
                                          </div>
                                        )}
                                      </Typography>
                                      <Typography
                                        variant="subtitle2"
                                        sx={{ fontSize: 10 }}
                                      >
                                        <DisplaySet
                                          sets_array={
                                            showDetails.includes(index)
                                              ? exerData?.sets
                                              : [exerData?.sets[0]]
                                          }
                                          parameters={exerData?.parameters}
                                        />
                                      </Typography>
                                    </div>
                                  </div>
                                </Card>
                              </div>
                            ))}
                          </div>
                        )}
                      {exercise.exercise_type == "general" &&
                        exercise.sub_exercises.length == 0 && (
                          <>
                            <div
                              className="text-center"
                              style={{
                                color: "#04abbb",
                              }}
                            >
                              <Icon icon="material-symbols:exercise-outline-sharp" />{" "}
                              General Exercise
                            </div>
                            <Card className="exercise-card mb-1 d-flex align-items-center">
                              <div>
                                <Icon
                                  icon="ph:tag-simple-fill"
                                  className="ms-1"
                                />
                              </div>
                              <div>
                                <div className="px-2">
                                  <Typography
                                    variant="subtitle2"
                                    component="div"
                                    className="d-flex"
                                  >
                                    {exercise.exercise_info.title}
                                    {exercise?.sets?.length > 1 && (
                                      <div
                                        className="ms-2 detail_open_icon"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          toggleDetails(index);
                                        }}
                                      >
                                        {showDetails.includes(index) ? (
                                          <>
                                            <ExpandLessIcon /> {"Sets"}{" "}
                                            {exercise?.sets?.length}
                                          </>
                                        ) : (
                                          <>
                                            <ExpandMoreIcon /> {"Sets"}{" "}
                                            {exercise?.sets?.length}
                                          </>
                                        )}
                                      </div>
                                    )}
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ fontSize: 10 }}
                                  >
                                    <DisplaySet
                                      sets_array={
                                        showDetails.includes(index)
                                          ? exercise?.sets
                                          : [exercise?.sets[0]]
                                      }
                                      parameters={exercise?.parameters}
                                    />
                                  </Typography>
                                </div>
                              </div>
                            </Card>
                          </>
                        )}
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </>
      ) : selectedPracticeTypeOption == "food" ||
        selectedPracticeTypeOption == "mindset" ? (
        <>
          {selectedDetail?.exercises?.length > 0 && (
            <>
              {selectedDetail?.exercises.map((exercise, index) => {
                return (
                  <div
                    className="col-lg-12 exercise_detail col-sm-12 mt-2"
                    key={index}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: exercise?.description,
                      }}
                    />
                  </div>
                );
              })}
            </>
          )}
        </>
      ) : (
        <div className="col-lg-12 exercise_detail col-sm-12 mt-2">
          <ReactVideoPlayer url={selectedDetail.video_url} />
        </div>
      )}
    </>
  );
};

export default WorkoutDetail;
