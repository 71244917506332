import { CircularProgress, TextField, Tooltip, Box, Chip } from "@mui/material";

import React, { useEffect, useRef, useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { useSnackbar } from "notistack";

import { makeStyles } from "@mui/styles";

import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import { paceFromVdot } from "src/utils/constant";
import TinyEditor from "src/components/ckeditor/Ckeditor";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  loading2: {
    position: "absolute",
    top: "50%",
    right: "50%",
    zIndex: 1000,
  },
  customInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed

      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },

  select: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed
      backgroundColor: "#ebebeb",
      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },
}));
const findPaceObjectByVdot = (vdotValue) => {
  return paceFromVdot.find((paceObject) => paceObject.vdot == vdotValue);
};
const SomeThingElseExercisesUI = ({
  addArray,
  setaddArray,
  setModalChangeExe,
  isHistory,
  memberId,
  mainindex,
  member_vdot,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [isReaload, setIsReaload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isHovered, setIsHovered] = useState("");

  const classes = useStyles();

  // Function to update intervalTime state
  const timeoutRef = useRef(null);
  const vdotwholeObject = findPaceObjectByVdot(member_vdot);
  useEffect(() => {
    // Cleanup function to clear the timeout on component unmount
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);
  const handleMouseLeave = () => {
    // Introduce a delay of 100 milliseconds before updating state on hover out
    const delay = 0;
    timeoutRef.current = setTimeout(() => {
      setIsHovered("");
    }, delay);
  };

  const handleAdd = (i) => {
    let list = addArray;

    list.splice(i + 1, 0, {
      exercise: "",
      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          heightType: "cm",
          height: "",
          distance: "",
          distanceType: "m",
          timeType: "sec",
          restType: "sec",
          reps: "",
          rest: "",
          tempo: "",
          vdot: member_vdot ? member_vdot : 30,
          race_type: "c",
          pace_type: "c_km",
          pace: member_vdot ? vdotwholeObject?.m_km : 423,
          paceUp: 423,
          optionsType: [
            { title: "KM Pace", key: "c_km" },
            { title: "MILE Pace", key: "c_mi" },
          ],
        },
      ],
      parameters: ["Weight", "Reps", "Rest"],
      time_interval: 60,
      rounds: 10,
      type: "general",
      emomSets: [[]],
      description: "",
      superSet: [],
      superset_sets: "",
    });

    setaddArray([...list]);
    setModalChangeExe(true);
  };

  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);

    setaddArray(delValue);
    setModalChangeExe(true);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;

    const list = [...addArray];
    list[index][name] = value;

    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleChangeDes = (value) => {
    const list = [...addArray];
    list[mainindex]["description"] = value;

    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleChangeUp = (value) => {
    if (value == 0) {
      enqueueSnackbar("You are already on Top !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value - 1, 0, reorderedItem);

    setaddArray(items);
    setModalChangeExe(true);
  };

  const handleChangeDown = (value) => {
    if (value >= addArray.length - 1) {
      enqueueSnackbar("You are already on Bottom !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value + 1, 0, reorderedItem);

    setaddArray(items);
    setModalChangeExe(true);
  };
  const memueOptions = (value) => {
    const MENU_OPTIONS = [];
    MENU_OPTIONS.unshift(
      {
        label: "Move Up",
        icon: "mdi:arrow-up",
        handleClick: handleChangeUp,
        disabled: value == 0 ? true : false,
      },
      {
        label: "Move Down",
        icon: "ic:outline-arrow-downward",
        handleClick: handleChangeDown,
        disabled: value >= addArray.length - 1 ? true : false,
      }
      // {
      //   label: "Parameters",
      //   icon: "pajamas:labels",
      //   handleClick: handleParameters,
      // }
    );

    return MENU_OPTIONS;
  };

  if (isReaload === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="my-2">
      {isLoading && (
        <CircularProgress className={classes.loading2} color="primary" />
      )}

      <div className="mt-2 ">
        <div className="characters">
          {/* <form onSubmit={handleSubmit}> */}

          <form onSubmit={() => {}}>
            <div
              style={{
                width: "100%",
                padding: "1px",
                position: "relative",
              }}
            >
              <div
                className=" my-1 workout-set-card w-100"
                onMouseEnter={() => handleMouseLeave()}
                style={{
                  width: "100%",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <div className=" cross-icon d-flex justify-content-end">
                  <>
                    <Tooltip title="Remove Exercise">
                      <RemoveCircleOutlineIcon
                        onClick={() => handleDelete(mainindex)}
                        className="diary-icon-remove"
                      />
                    </Tooltip>
                  </>

                  <Tooltip title="Add Exercise">
                    <AddCircleOutlineIcon
                      onClick={() => handleAdd(mainindex)}
                      className="diary-icon-add"
                    />
                  </Tooltip>

                  <CustomPopoverSection
                    menu={memueOptions(mainindex)}
                    data={mainindex}
                  />
                </div>
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                  }}
                  onMouseEnter={() => handleMouseLeave()}
                >
                  <div
                    className="row  "
                    onMouseEnter={() => handleMouseLeave()}
                  >
                    <div
                      className={`col-sm-12 ${
                        isHistory ? "col-md-6" : "col-md-7"
                      } col-lg-7 d-flex align-items-center  mt-2 mt-md-0 mt-lg-0`}
                    >
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="Name"
                        value={
                          addArray[mainindex].title
                            ? addArray[mainindex].title
                            : ""
                        }
                        name="title"
                        onChange={(e) => handleChange(e, mainindex)}
                        InputLabelProps={{
                          className: "textfiels-input-label",
                          shrink: true,
                          style: { fontSize: "14px" },
                        }}
                        label="Name"
                        InputProps={{
                          className: "textfiels-input-value",
                          style: {
                            fontSize: "13px",
                          },

                          inputProps: {
                            // readOnly: true,
                          },
                        }}
                        sx={{
                          borderRadius: "5px",

                          fontSize: "13px",
                          width: "100%",
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-12 mt-3">
                    <h4>Description</h4>
                    <TinyEditor
                      setDetailDescription={handleChangeDes}
                      detailDescriptionCk={addArray[mainindex].description}
                    />
                  </div>
                  {/* <TextField
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    placeholder="Description"
                    label="Description"
                    type="number"
                    value={addArray[mainindex].description}
                    name="description"
                    multiline
                    rows={3}
                    onChange={(e) => handleChangeDes(e, mainindex)}
                    InputLabelProps={{
                      className: "textfiels-input-label",
                      shrink: true,
                      style: { fontSize: "14px" },
                    }}
                    InputProps={{
                      className: "textfiels-input-value",
                      style: { fontSize: "14px" },
                    }}
                    sx={{
                      borderRadius: "5px",
                      // "& legend": { display: "none" },
                      // "& fieldset": { top: 0 },

                      mt: 2,
                      width: "100%",
                    }}
                  /> */}
                </Box>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default SomeThingElseExercisesUI;
