import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { IconButton, CircularProgress, Button } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  addFeedback,
  editFeedback,
  getFormMembersDetails_V1,
  getTemplateDetail_V1,
} from "src/DAL/SendFormTemplate/sendFormTemplate";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@mui/styles";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import QuestionsListing from "./QuestionsListing";
import FeedBack from "./FeedBack";
import GeneralPopUpModel from "src/components/GeneralComponents/GeneralPopUpModel";
import GeneralCkeditor from "src/components/GeneralCkeditor";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function DetailsFormInformation({ page_type }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const classes = useStyles();
  const { state } = useLocation();
  const param = useParams();
  const { user_id } = param;
  const _id = param.slug;
  const [templateTitle, setTemplateTitle] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [isFeedbackLoading, setIsFeedbackLoading] = useState(false);
  const [feedbackList, setFeedbackList] = useState([]);
  const [openFeedback, setOpenFeedback] = useState(false);

  const [feedback, setFeedback] = useState({
    feedback: "",
    feedbackId: "",
  });
  const [openAddFeedback, setOpenAddFeedback] = useState(false);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const TABS_OPTIONS = [
    {
      title: "Questions",
      component: (
        <QuestionsListing questions={questions} setQuestions={setQuestions} />
      ),
    },
    {
      title: "Feedback",
      component: (
        <FeedBack
          feedbackList={feedbackList}
          setFeedbackList={setFeedbackList}
          setOpenAddFeedback={setOpenAddFeedback}
          setFeedback={setFeedback}
          page_type={page_type}
        />
      ),
    },
  ];

  const handleFeedback = async () => {
    setOpenFeedback(true);
  };

  const handleAddFeedback = async () => {
    setIsFeedbackLoading(true);
    setOpenFeedback(false);
    const postData = {
      form_id: _id,
      member_id: page_type === "forms" ? "" : user_id,
      feedback_content: feedback?.feedback,
      feedback_type: page_type === "forms" ? "general" : "individual",
    };

    const EditPost = {
      feedback_content: feedback?.feedback,
      feedback_id: feedback?.feedbackId,
    };
    const result = feedback?.feedbackId
      ? await editFeedback(EditPost)
      : await addFeedback(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setOpenAddFeedback(false);
      setFeedback({
        feedback: "",
      });
      if (!feedback?.feedbackId) {
        setFeedbackList([result?.feedback, ...feedbackList]);
      } else {
        const newFeedbackList = feedbackList.map((item) =>
          item._id === feedback?.feedbackId ? result?.feedback : item
        );
        setFeedbackList(newFeedbackList);
      }
      setIsFeedbackLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsFeedbackLoading(false);
    }
  };

  const getFormDetails = async () => {
    setIsLoading(true);
    const type = page_type === "forms" ? "general" : "individual";
    const result = await getTemplateDetail_V1(_id, user_id, type);
    if (result.code === 200) {
      setTemplateTitle(state);
      setFeedbackList(result?.data);
      if (page_type === "forms") {
        setQuestions(result?.form?.questions);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const getFormMembersDetails = async () => {
    setIsLoading(true);
    const result = await getFormMembersDetails_V1(_id, user_id);
    if (result.code === 200) {
      setQuestions(result?.data?.questions);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (page_type === "members_forms") {
      getFormMembersDetails();
      getFormDetails();
    } else {
      getFormDetails();
    }
  }, [state, page_type]);

  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <CustomConfirmation
        openDelete={openFeedback}
        setOpenDelete={setOpenFeedback}
        title={
          "Your feedback will be sent to multiple users. Are you sure you want to proceed?"
        }
        handleAgree={handleAddFeedback}
      />
      <div className="container">
        <div className="row mobile-margin display-flex">
          <div className="col-12 col-md-8 d-flex align-items-baseline">
            <IconButton
              className="back-screen-button mb-0"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
            <div className="ms-2">
              {page_type === "members_forms" ? (
                <h2>{state?.fullName || state?.title}</h2>
              ) : (
                <>
                  <h2>{templateTitle?.title}</h2>
                  <p>{templateTitle?.description}</p>
                </>
              )}
            </div>
          </div>
          {tabValue == 1 && (
            <div className="col-12 col-md-4 text-end mt-2">
              <Button
                variant="contained"
                onClick={() => {
                  setFeedback({
                    feedback: "",
                    feedbackId: "",
                  });
                  setOpenAddFeedback(true);
                }}
              >
                Add Feedback
              </Button>
            </div>
          )}
        </div>
        <MUICustomTabs
          data={TABS_OPTIONS}
          value={tabValue}
          handleChange={handleChangeTab}
        />
        <GeneralPopUpModel
          open={openAddFeedback}
          setOpen={setOpenAddFeedback}
          title={`${feedback?.feedbackId ? "Edit" : "Add"} Feedback`}
          componentToPassDown={
            <>
              <GeneralCkeditor
                setInputs={setFeedback}
                inputs={feedback}
                name="feedback"
                editorHeight={350}
              />
              <div className="text-end mt-2">
                <Button
                  variant="contained"
                  onClick={
                    page_type === "forms" ? handleFeedback : handleAddFeedback
                  }
                >
                  {isFeedbackLoading ? "Submitting..." : "Submit"}
                </Button>
              </div>
            </>
          }
        />
      </div>
    </>
  );
}
