import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import React from "react";

function ConfirmationWithDescription({
  open,
  setOpen,
  handleAgree,
  title,
  descriotion,
  isLoading,
}) {
  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle>
          {title ? title : "Are you sure you want to take this action?"}
        </DialogTitle>
        {descriotion && (
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {descriotion}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button className="model-button-hover" onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button
            className="model-button-hover"
            onClick={handleAgree}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Agree"}
          </Button>
        </DialogActions>
      </Dialog>
      {/* New to solve delete issue  */}
    </>
  );
}

export default ConfirmationWithDescription;
