import { Dialog } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export default function DetailPopUpModelNew({
  open,
  title,
  setOpen,
  componentToPassDown,
  eventDetailData,
  handleAgreeDelete,
  handleEdit,
}) {
  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
          },
          className: "p-3 general-popup-model",
        }}
      >
        <div class="icon-event-detail d-flex" onClick={() => setOpen(false)}>
          <div className="other-detail-icon-new" onClick={handleEdit}>
            <EditIcon style={{ cursor: "pointer", fontSize: "16px" }} />
          </div>
          <div className="other-detail-icon-new" onClick={handleAgreeDelete}>
            <DeleteOutlineIcon
              style={{ cursor: "pointer", fontSize: "16px" }}
            />
          </div>
          <div className="close-detail-icon-new" onClick={() => setOpen(false)}>
            <CloseIcon style={{ cursor: "pointer", fontSize: "18px" }} />
          </div>
        </div>
        <div className="responce-messages">{componentToPassDown}</div>
      </Dialog>
    </>
  );
}
