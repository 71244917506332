import {
  Avatar,
  CircularProgress,
  Box,
  Typography,
  Chip,
  Button,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useRef, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EditIcon from "@mui/icons-material/Edit";
import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "src/config/config";

import moment from "moment";
import { getTitleByKey, getValueText } from "src/utils/constant";
import SimpleCustomTextfields from "./components/SimpleCustomTextfields";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  customInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed

      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },

  select: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed
      backgroundColor: "#ebebeb",
      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },
}));

const WorkoutExercisesHistoryUI = ({ addArray, setaddArray, notForModel }) => {
  const [isReaload, setIsReaload] = useState(false);

  const classes = useStyles();

  const handleArrow = (value, index) => {
    //  const { name, value } = e.target;

    const list = [...addArray];
    list[index]["open"] = value;

    setaddArray(list);
  };

  const getPaceTextinPaces = (type) => {
    let result = "";
    if (type == "m") {
      result = "Time Cap";
    } else if (type == "mi") {
      result = "Pace Min/Mile";
    } else if (type == "km") {
      result = "Pace Min/Km";
    }
    return result;
  };
  const getKmhrForPace = (value, time, type) => {
    if (!value || !time) {
      return 0;
    }
    let result = 0;
    if (type == "m") {
      result = (value / time) * 3.6;
    } else if (type == "mi") {
      result = ((1 * 1000) / time) * 3.6 * 1.609;
    } else if (type == "km") {
      result = ((1 * 1000) / time) * 3.6;
    }
    return result.toFixed(2);
  };
  const getMihrForPace = (value, time, type) => {
    if (!value || !time) {
      return 0;
    }
    let result = 0;
    if (type == "m") {
      result = result = ((value / time) * 3.6) / 1.609;
    } else if (type == "mi") {
      result = ((1 * 1000) / time) * 3.6;
    } else if (type == "km") {
      result = (((1 * 1000) / time) * 3.6) / 1.609;
    }
    return result.toFixed(2);
  };
  const getPaceText = (type) => {
    let result = "";
    if (type?.includes("_200_")) {
      result = "Time Cap";
    } else if (type?.includes("_300_")) {
      result = "Time Cap";
    } else if (type?.includes("_400_")) {
      result = "Time Cap";
    } else if (type?.includes("_600_")) {
      result = "Time Cap";
    } else if (type?.includes("_800_")) {
      result = "Time Cap";
    } else if (type?.includes("_1200_")) {
      result = "Time Cap";
    } else if (type?.includes("mi")) {
      result = "Pace Min/Mile";
    } else if (type?.includes("km")) {
      result = "Pace Min/Km";
    }
    return result;
  };
  const getKmhr = (value, type) => {
    let result = 0;
    if (type?.includes("_200_")) {
      result = (200 * 3600) / (parseInt(value) * 1000);
    } else if (type?.includes("_300_")) {
      result = (300 * 3600) / (parseInt(value) * 1000);
    } else if (type?.includes("_400_")) {
      result = (400 * 3600) / (parseInt(value) * 1000);
    } else if (type?.includes("_600_")) {
      result = (600 * 3600) / (parseInt(value) * 1000);
    } else if (type?.includes("_800_")) {
      result = (800 * 3600) / (parseInt(value) * 1000);
    } else if (type?.includes("_1200_")) {
      result = (1200 * 3600) / (parseInt(value) * 1000);
    } else if (type?.includes("mi")) {
      result = (3600 / parseInt(value)) * 1.609;
    } else if (type?.includes("km")) {
      result = 3600 / parseInt(value);
    }
    return result.toFixed(2);
  };
  const getMihr = (value, type) => {
    let result = 0;
    if (type?.includes("_200_")) {
      result = (200 * 3600) / (parseInt(value) * 1000) / 1.609;
    } else if (type?.includes("_300_")) {
      result = (300 * 3600) / (parseInt(value) * 1000) / 1.609;
    } else if (type?.includes("_400_")) {
      result = (400 * 3600) / (parseInt(value) * 1000) / 1.609;
    } else if (type?.includes("_600_")) {
      result = (600 * 3600) / (parseInt(value) * 1000) / 1.609;
    } else if (type?.includes("_800_")) {
      result = (800 * 3600) / (parseInt(value) * 1000) / 1.609;
    } else if (type?.includes("_1200_")) {
      result = (1200 * 3600) / (parseInt(value) * 1000) / 1.609;
    } else if (type?.includes("mi")) {
      result = 3600 / parseInt(value);
    } else if (type?.includes("km")) {
      result = 3600 / parseInt(value) / 1.609;
    }
    return result.toFixed(2);
  };

  // Function to update intervalTime state

  const formatWithLeadingZeros = (value) => {
    return value < 10 ? `0${value}` : `${value}`;
  };

  if (isReaload === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="characters">
      {addArray?.map((data, mainindex) => {
        return (
          <>
            {
              <div
                style={{
                  width: "100%",
                  padding: "1px",
                  position: "relative",
                }}
              >
                <div
                  className="d-flex my-1 workout-set-card w-100"
                  style={{
                    width: "100%",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                    }}
                  >
                    <div className="d-flex justify-content-end">
                      <p className="mb-0">
                        {moment(data?.date).format("DD-MMM-YYYY")}
                      </p>
                      {data?.is_extra && (
                        <p className="mb-0 ps-2">Extra Work</p>
                      )}
                    </div>
                    <div className="row  ">
                      <div
                        className={
                          notForModel
                            ? "col-sm-12 d-flex align-items-center p-0"
                            : `col-sm-12 ${"col-md-7"} col-lg-6 d-flex align-items-center p-0 mt-2 mt-md-0 mt-lg-0`
                        }
                      >
                        <div className="exercise-label-box">
                          <span className="outer-label">Exercise</span>
                          <div className="d-flex align-items-center justify-content-between">
                            <div
                              className="exercise-label w-100"
                              style={{ cursor: "default" }}
                            >
                              <div className="d-flex align-items-center">
                                {data?.exercise?.image?.thumbnail_3 && (
                                  <Avatar
                                    sx={{ borderRadius: "5px" }}
                                    alt="User Image"
                                    src={
                                      s3baseUrl +
                                      data?.exercise?.image?.thumbnail_3
                                    }
                                  />
                                )}
                                <div className="label-text ms-1">
                                  {data?.exercise?.title
                                    ? data?.exercise?.title
                                    : ""}
                                </div>
                              </div>
                            </div>
                            <div className="exercise-action">
                              <div style={{ fontSize: "16px" }}>
                                {data?.exercise ? (
                                  <div></div>
                                ) : (
                                  <AddIcon
                                    sx={{
                                      opacity: 0.5,
                                      cursor: "pointer",
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <Button
                          sx={{ textTransform: "inherit" }}
                          size="small"
                          color="primary"
                          variant="contained"
                          className="d-flex align-items-center ms-2 small-contained-dialog-button"
                          onClick={() => {
                            handleArrow(!data?.open ? true : false, mainindex);
                          }}
                        >
                          {!data?.open ? (
                            <KeyboardArrowDownIcon
                              sx={{
                                cursor: "pointer",
                                // opacity: 0.5,
                                fontSize: "20px",
                              }}
                            />
                          ) : (
                            <KeyboardArrowUpIcon
                              sx={{
                                cursor: "pointer",
                                // opacity: 0.5,
                                fontSize: "20px",
                              }}
                            />
                          )}

                          <Typography
                            variant="body2"
                            sx={{
                              // opacity: 0.6,
                              whiteSpace: "nowrap",
                            }}
                          >
                            {` Sets ${data?.set?.length}`}
                          </Typography>
                        </Button>
                      </div>
                    </div>

                    {data?.open &&
                      data?.set?.map((item, index1) => (
                        <div className="d-flex align-items-center my-3 workout-card-inset ">
                          <div className="row w-100">
                            {data.parameters.includes("Vdot") && (
                              <>
                                {
                                  <div
                                    className={
                                      notForModel
                                        ? "col-12 py-2"
                                        : `col-12 col-md-2 col-lg-2 pe-0 py-2`
                                    }
                                  >
                                    <SimpleCustomTextfields
                                      label={"Auto Pacer"}
                                      value1={item?.vdot}
                                    />
                                  </div>
                                }
                                <div
                                  className={
                                    notForModel
                                      ? "col-12 py-2"
                                      : `col-12 col-md-5 ${
                                          item?.race_type == "el"
                                            ? "col-lg-3"
                                            : "col-lg-4"
                                        } pe-0 py-2`
                                  }
                                >
                                  <SimpleCustomTextfields
                                    label={"Type"}
                                    value1={getValueText(item?.race_type)}
                                    value2={getTitleByKey(item?.pace_type)}
                                  />
                                </div>

                                {item?.race_type == "el" ? (
                                  <div
                                    className={
                                      notForModel
                                        ? "col-12 py-2"
                                        : `col-12 col-md-3 col-lg-3 pe-0 py-2`
                                    }
                                  >
                                    <SimpleCustomTextfields
                                      label={getPaceText(item?.pace_type)}
                                      value1={`${formatWithLeadingZeros(
                                        Math.floor(item?.pace / 60)
                                      )}  :  ${formatWithLeadingZeros(
                                        item?.pace % 60
                                      )}`}
                                      value2={`${formatWithLeadingZeros(
                                        Math.floor(item?.paceUp / 60)
                                      )}  :  ${formatWithLeadingZeros(
                                        item?.paceUp % 60
                                      )}`}
                                      time={true}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className={
                                      notForModel
                                        ? "col-12 py-2"
                                        : `col-12 col-md-2 col-lg-2 pe-0 py-2`
                                    }
                                  >
                                    <SimpleCustomTextfields
                                      label={getPaceText(item?.pace_type)}
                                      value1={`  ${formatWithLeadingZeros(
                                        Math.floor(item?.pace / 60)
                                      )}  :  ${formatWithLeadingZeros(
                                        item?.pace % 60
                                      )}`}
                                    />
                                  </div>
                                )}
                              </>
                            )}
                            {data.parameters.includes("Pace") && (
                              <>
                                <div
                                  className={
                                    notForModel
                                      ? "col-12 py-2"
                                      : `col-12 col-md-3 ${
                                          item?.race_type == "t"
                                            ? "col-lg-3"
                                            : "col-lg-2"
                                        }  pe-0 py-2`
                                  }
                                >
                                  <SimpleCustomTextfields
                                    label={"Type"}
                                    value1={getValueText(item?.race_type)}
                                  />
                                </div>
                                <div
                                  className={
                                    notForModel
                                      ? "col-12 py-2"
                                      : `col-12 col-md-3 col-lg-2 pe-0 py-2`
                                  }
                                >
                                  <SimpleCustomTextfields
                                    label={"Distance"}
                                    value1={item?.distance ? item?.distance : 0}
                                    value2={getValueText(item?.distanceType)}
                                  />
                                </div>
                                <div
                                  className={
                                    notForModel
                                      ? "col-12 py-2"
                                      : `col-12 col-md-2 col-lg-2 pe-0 py-2`
                                  }
                                >
                                  <SimpleCustomTextfields
                                    label={getPaceTextinPaces(
                                      item?.distanceType
                                    )}
                                    value1={`  ${formatWithLeadingZeros(
                                      Math.floor(item?.pace / 60)
                                    )}  :  ${formatWithLeadingZeros(
                                      item?.pace % 60
                                    )}`}
                                  />
                                </div>

                                <div
                                  style={{
                                    position: "absolute",
                                    bottom: "4px",
                                    left: "1px",
                                  }}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      opacity: 0.6,
                                      fontWeight: "600",
                                      fontSize: "12px",
                                    }}
                                  >{` @ Speed ${getKmhrForPace(
                                    item?.distance,
                                    item?.pace,
                                    item?.distanceType
                                  )} km/hr or ${getMihrForPace(
                                    item?.distance,
                                    item?.pace,
                                    item?.distanceType
                                  )} mi/hr`}</Typography>
                                </div>
                              </>
                            )}
                            {data.parameters.includes("Distance") && (
                              <div
                                className={
                                  notForModel
                                    ? "col-12 py-2"
                                    : `col-12 col-md-2 col-lg-2 pe-0 py-2`
                                }
                              >
                                <SimpleCustomTextfields
                                  label={"Distance"}
                                  value1={item?.distance ? item?.distance : 0}
                                  value2={getValueText(item?.distanceType)}
                                />
                              </div>
                            )}
                            {(data.parameters.includes("Duration/Pace") ||
                              data.parameters.includes("Time")) && (
                              <div
                                className={
                                  notForModel
                                    ? "col-12 py-2"
                                    : `col-12 col-md-2 col-lg-2 pe-0 py-2`
                                }
                              >
                                <SimpleCustomTextfields
                                  label={
                                    data.parameters.includes("Duration/Pace")
                                      ? "Duration/Pace"
                                      : "Time"
                                  }
                                  value1={item?.time ? item?.time : 0}
                                  value2={item?.timeType}
                                />
                              </div>
                            )}
                            {data.parameters.includes("Reps") && (
                              <div
                                className={
                                  notForModel
                                    ? "col-12 py-2"
                                    : "col-12 col-md-2 col-lg-2 pe-0 py-2"
                                }
                              >
                                <SimpleCustomTextfields
                                  label={"Reps"}
                                  value1={item?.reps ? item?.reps : 0}
                                />
                              </div>
                            )}
                            {data.parameters.includes("Max_Reps") && (
                              <div
                                className={
                                  notForModel
                                    ? "col-12 py-2"
                                    : "col-12 col-md-2 col-lg-2 pe-0 py-2"
                                }
                              >
                                <SimpleCustomTextfields
                                  label={"Max Reps"}
                                  value1={item?.maxReps ? item?.maxReps : 0}
                                />
                              </div>
                            )}
                            {data.parameters.includes("Height") && (
                              <div
                                className={
                                  notForModel
                                    ? "col-12 py-2"
                                    : "col-12 col-md-3 col-lg-2 pe-0 py-2"
                                }
                              >
                                <SimpleCustomTextfields
                                  label={"Height"}
                                  value1={item?.height ? item?.height : 0}
                                  value2={getValueText(item?.heightType)}
                                />
                              </div>
                            )}
                            {data.parameters.includes("Weight") && (
                              <div
                                className={
                                  notForModel
                                    ? "col-12 py-2"
                                    : "col-12 col-md-2 col-lg-2 pe-0 py-2"
                                }
                              >
                                <SimpleCustomTextfields
                                  label={"Weight"}
                                  value1={item?.weight ? item?.weight : 0}
                                  value2={getValueText(item?.weightType)}
                                />
                              </div>
                            )}
                            {data.parameters.includes("Distance/Calories") && (
                              <div
                                className={
                                  notForModel
                                    ? "col-12 py-2"
                                    : "col-12 col-md-3 col-lg-2 pe-0 py-2"
                                }
                              >
                                <SimpleCustomTextfields
                                  label={"Distance/Calories"}
                                  value1={item?.distance ? item?.distance : 0}
                                  value2={getValueText(item?.distanceType)}
                                />
                              </div>
                            )}
                            {data.parameters.includes("%MHR") && (
                              <div
                                className={
                                  notForModel
                                    ? "col-12 py-2"
                                    : "col-12 col-md-2 col-lg-2 pe-0 py-2"
                                }
                              >
                                <SimpleCustomTextfields
                                  label={"%MHR"}
                                  value1={item?.mhr ? item?.mhr : 0}
                                />
                              </div>
                            )}
                            {data.parameters.includes("Tempo") && (
                              <div
                                className={
                                  notForModel
                                    ? "col-12 py-2"
                                    : "col-12 col-md-2 col-lg-2 pe-0 py-2"
                                }
                              >
                                <SimpleCustomTextfields
                                  label={"Tempo"}
                                  value1={item?.tempo ? item?.tempo : 0}
                                />
                              </div>
                            )}
                            {data.parameters.includes("Rest") && (
                              <div
                                className={
                                  notForModel
                                    ? "col-12 py-2"
                                    : "col-12 col-md-2 col-lg-2 pe-0 py-2"
                                }
                              >
                                <SimpleCustomTextfields
                                  label={"Rest"}
                                  value1={item?.rest ? item?.rest : 0}
                                  value2={item?.restType}
                                />
                              </div>
                            )}
                            {data.parameters.includes("Vdot") && (
                              <div
                              // style={{
                              //   position: "absolute",
                              //   bottom: "4px",
                              //   left: "5px",
                              // }}
                              >
                                {item?.race_type == "el" ? (
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      opacity: 0.6,
                                      fontWeight: "600",
                                      fontSize: "12px",
                                    }}
                                  >{` @ Speed ${getKmhr(
                                    item?.pace,
                                    item?.pace_type
                                  )} km/hr or ${getMihr(
                                    item?.pace,
                                    item?.pace_type
                                  )} mi/hr -  ${getKmhr(
                                    item?.paceUp,
                                    item?.pace_type
                                  )} km/hr or ${getMihr(
                                    item?.paceUp,
                                    item?.pace_type
                                  )} mi/hr`}</Typography>
                                ) : (
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      opacity: 0.6,
                                      fontWeight: "600",
                                      fontSize: "12px",
                                    }}
                                  >{` @ Speed ${getKmhr(
                                    item?.pace,
                                    item?.pace_type
                                  )} km/hr or ${getMihr(
                                    item?.pace,
                                    item?.pace_type
                                  )} mi/hr`}</Typography>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    {data?.description && data?.is_extra && (
                      <div className="col-12 custom_description ">
                        <div className="custom_description_title">Notes</div>
                        <div className="custom_description_text">
                          {data?.description}
                        </div>
                      </div>
                    )}
                    {data?.open && (
                      <div className="row mt-3 d-flex justify-content-center">
                        <div
                          className={
                            notForModel ? "col-3" : "col-md-2 col-lg-1 col-3"
                          }
                        >
                          <button
                            onClick={(e) => {
                              e.preventDefault();

                              handleArrow(false, mainindex);
                            }}
                            className="w-100 small-contained-set-button"
                          >
                            <KeyboardArrowUpIcon
                              sx={{
                                cursor: "pointer",
                                opacity: 0.5,
                                fontSize: "20px",
                              }}
                              onClick={() => {
                                handleArrow(false, mainindex);
                              }}
                            />
                          </button>
                        </div>
                      </div>
                    )}
                  </Box>
                </div>
              </div>
            }
          </>
        );
      })}
    </div>
  );
};
export default WorkoutExercisesHistoryUI;
